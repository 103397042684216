import { HistoryItem } from 'types';
import React, { useEffect, useState } from 'react';
import { Timeline, TimelineItem } from '../timeline';
import { useMsal } from '@azure/msal-react';
import { OFCOM_API_SCOPES, getAccessToken } from 'utils';

interface HistoryTabProps {
	historyFunction(token: string, page?: number, pageSize?: number): Promise<HistoryItem[]>;
}

/**
 * Tab containing a history of changes for the current provider
 * @return {JSX.Element}
 */
const HistoryTab = ({ historyFunction }: HistoryTabProps): JSX.Element => {
	const [historyItems, setHistoryItems] = useState<TimelineItem[]>([]);
	const msal = useMsal();

	useEffect(() => {
		const getHistory = async () => {
			const token = await getAccessToken(OFCOM_API_SCOPES, msal);
			const apiHistory = await historyFunction(token);

			const history: Array<TimelineItem> = [];

			apiHistory.forEach((item) => {
				history.push({
					date: item.date,
					person: item.person,
					event: item.item,
				});
			});

			setHistoryItems(history);
		};

		getHistory();
	}, []);

	return <Timeline data-testid='history-timeline' items={historyItems} dateFormat='dd/MM/yyyy' />;
};

export default HistoryTab;
