import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { PortAgreementItem, PortAgreementAction, StepItem, StepItemStatus } from 'types';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { PortAgreementService } from 'services';
import { GammaFeedbackToast, PanelStep } from 'components';
import PortAgreementFormInput from './port-agreement-form-input';
import PortAgreementFormSummary from './port-agreement-form-summary';
import { useMsal } from '@azure/msal-react';
import { apiConfig } from 'auth-config';
import { getAccessToken } from 'utils';
import toast from 'react-hot-toast';

interface PortAgreementFormProps {
	agreement: PortAgreementItem | null;
	setEditMode: Dispatch<SetStateAction<boolean>>;
	onClose: () => void;
	action?: PortAgreementAction;
}

const portPrefixRegExp = new RegExp('^5[0-9]{5}$');
const numberBlockRegExp = new RegExp('^[1-9][0-9]{3,7}$', 'i');
const maxPdiSize = 5000000;
const supporedPdiFormats = [
	'application/pdf',
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const validateNumberBlocks = (blockInput: string): boolean => {
	const invalidNumbers: string[] = [];

	blockInput.split(/;|,|\n/).forEach((number) => {
		if (number && !numberBlockRegExp.test(number.trim())) {
			invalidNumbers.push(number);
		}
	});

	return invalidNumbers.length == 0;
};

const PortAgreementFormContainer = ({ agreement, setEditMode, onClose, action = 'EDIT' }: PortAgreementFormProps) => {
	const initialSteps: StepItem[] = [
		{ name: action == 'EDIT' ? 'Edit Agreement' : action == 'ADD' ? 'Add Agreement' : 'Merge Agreement', status: 'CURRENT' },
		{ name: 'Summary', status: 'UPCOMING' },
	];

	const msal = useMsal();
	const ofcomApiScopes = apiConfig.ofcomApi.scopes;
	const [showSummary, setShowSummary] = useState(false);
	const [steps, setSteps] = useState(initialSteps);

	const validationSchema: Yup.SchemaOf<PortAgreementService.PortAgreementEditInput> = Yup.object().shape(
		{
			numberBlock:
				action == 'EDIT'
					? Yup.string().notRequired()
					: Yup.string()
							.required('One or more number blocks are required')
							.test(' number-check', 'One or more numbers are in an invalid format', (value) => !value || validateNumberBlocks(value)),
			agreementHolder: action == 'EDIT' ? Yup.string().notRequired() : Yup.string().required('A provider must be selected'),
			portPrefix: Yup.string()
				.required('A port prefix is required')
				.matches(portPrefixRegExp, 'A port prefix must start with a 5,  be numeric and be 6 digits long'),
			pdi: Yup.mixed()
				.notRequired()
				.test('fileSize', 'PDI file must be no larger than 5MB', (value?: File) => !value || (value && value.size <= maxPdiSize))
				.test(
					'fileType',
					'PDI file type not supported. Supported types are PDF, XLS or XLSX',
					(value?: File) => !value || (value && supporedPdiFormats.includes(value.type)),
				),
			changeReason: Yup.string()
				.notRequired()
				.when('changeReason', {
					is: (value: string) => value?.length,
					then: (rule: Yup.StringSchema) => rule.max(255, 'A change reason should be no more than 255 characters'),
				}),
		},
		[['changeReason', 'changeReason']],
	);

	const submit = async (values: PortAgreementService.PortAgreementEditInput) => {
		const token = await getAccessToken(ofcomApiScopes, msal);
		const response =
			action == 'EDIT'
				? await PortAgreementService.updatePortAgreementItem(token, agreement!.id, values)
				: await PortAgreementService.addPortAgreements(token, values);

		if (response.ok || (response.status > 200 && response.status < 299)) {
			setEditMode(false);
			onClose();
			toast.custom((t) => (
				<GammaFeedbackToast
					hotToast={t}
					message={getSuccessMessage(values)}
					type='success'
					includeIcon={true}
					data-testid='success-feedback'
					title={`Port Agreement ${action == 'EDIT' ? 'Update' : 'Add'} Successful`}
				/>
			));
		} else {
			const errorMessage = await response.text();
			toast.custom((t) => (
				<GammaFeedbackToast
					hotToast={t}
					message={getFailMessage(errorMessage)}
					type='error'
					includeIcon={true}
					data-testid='error-feedback'
					title='Port Agreement Update Failed'
				/>
			));
		}
	};

	const getSuccessMessage = (values: PortAgreementService.PortAgreementEditInput) => {
		const blockInput = values.numberBlock as string;
		const agreementHolderCupidInput = values.agreementHolder as string;

		return action == 'EDIT'
			? `Successfully updated the agreement details of +44${blockInput} for (${agreement?.agreementHolder.cupid}) ${agreement?.agreementHolder.name}`
			: `Sucessfully added port agreements for the following ranges ${blockInput
					.split(/;|,|\n/)
					.map((i) => `+44${i}`)
					.toString()} to cupid ${agreementHolderCupidInput}`;
	};

	const getFailMessage = (resError: string) => {
		if (resError && resError !== '') {
			console.log(resError);
			return resError;
		}
		return action == 'EDIT'
			? 'Unable to update the port agreement item. If the problem persists feel free to get in contact your system administrator'
			: action == 'ADD'
			? 'Unable to add the port agreement items. If the problem persists feel free to get in contact your system administrator'
			: 'Unable to merge the port agreement items. If the problem persists feel free to get in contact your system administrator';
	};

	const updateStep = (stepIdx: number, stepStatus: StepItemStatus): void => {
		const currSteps = steps;
		currSteps[stepIdx].status = stepStatus;
		setSteps(currSteps);
	};

	// force step component refresh
	useEffect(() => {}, [steps]);

	return (
		<Formik
			initialValues={{
				numberBlock: agreement ? agreement.numberBlock : '',
				agreementHolder: agreement ? agreement.agreementHolder.cupid : '',
				portPrefix: agreement ? agreement.portPrefix : '',
				pdi: undefined,
				changeReason: '',
			}}
			onSubmit={(values) => submit(values)}
			validationSchema={validationSchema}
		>
			{(props: FormikProps<any>) => (
				<>
					<div className='pl-5 pr-12' data-testid='agreeeent-form-steps'>
						<PanelStep steps={steps} />
					</div>
					<Form className='flex h-full flex-col overflow-y-scroll shadow-xl'>
						{showSummary ? (
							<PortAgreementFormSummary
								action={action}
								agreement={agreement}
								formProps={props}
								updateStep={updateStep}
								setShowSummary={setShowSummary}
							/>
						) : (
							<PortAgreementFormInput
								action={action}
								agreement={agreement}
								onClose={onClose}
								setEditMode={setEditMode}
								formPops={props}
								setShowSummary={setShowSummary}
								updateStep={updateStep}
							/>
						)}
					</Form>
				</>
			)}
		</Formik>
	);
};

export default PortAgreementFormContainer;
