import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Spinner } from 'components';

export interface SlideoutProps {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	title: string;
	child?: React.ReactNode;
}

const Slideout: React.FC<SlideoutProps> = ({ open, setOpen, title, child = null }: SlideoutProps) => {
	const [childComponent, setChildComponent] = useState<React.ReactNode>(null);
	const [showSpinner, setShowSpinner] = useState(true);
	const [slideoutTitle, setSlideoutTitle] = useState('');
	useEffect(() => {
		setShowSpinner(child === null);
		setSlideoutTitle(title);
		setChildComponent(child);
	}, [child]);

	const onClose = (): void => {
		setOpen(false);
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as='div' className='relative z-40' onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter='ease-in-out duration-75'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in-out duration-75'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity' />
				</Transition.Child>
				<div className='fixed inset-0 overflow-hidden' data-testid='slideout'>
					<div className='absolute inset-0 overflow-hidden'>
						<div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
							<Transition.Child
								as={Fragment}
								enter='transform transition ease-in-out duration-500 sm:duration-700'
								enterFrom='translate-x-full'
								enterTo='translate-x-0'
								leave='transform transition ease-in-out duration-500 sm:duration-700'
								leaveFrom='translate-x-0'
								leaveTo='translate-x-full'
								afterLeave={() => {
									setChildComponent(null);
									setShowSpinner(true);
									setSlideoutTitle('');
								}}
							>
								<Dialog.Panel className='pointer-events-auto w-screen max-w-md md:max-w-3xl'>
									<div
										className='flex h-full flex-col overflow-y-scroll bg-gray-50 dark:bg-gray-800 shadow-xl'
										data-testid='slideout-panel'
									>
										<div className='px-4 py-6 sm:px-6'>
											<div className='flex items-start justify-between'>
												<Dialog.Title className='text-lg font-medium text-black dark:text-white' data-testid='slideout-title'>
													{slideoutTitle}
												</Dialog.Title>
												<div className='ml-3 flex h-7 items-center'>
													<button
														type='button'
														className='rounded-md bg-gray-50 dark:bg-gray-800 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gamma-digital focus:ring-offset-2'
														data-testid='slideout-close-button'
														onClick={onClose}
													>
														<span className='sr-only'>Close Panel</span>
														<XMarkIcon className='h-6 w-6' aria-hidden='true' />
													</button>
												</div>
											</div>
										</div>
										<div className='self-center' hidden={!showSpinner}>
											<Spinner size={'xl'} data-testid='slideout-spinner' />
										</div>
										{childComponent}
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default Slideout;
