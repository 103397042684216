import React from 'react';
import type { FC } from 'react';
import { OFCOM_DATA_ADMIN_ROLE } from 'utils';
import { ActionsDropdown } from 'components/actions-dropdown';

interface RangeActionsProps {
	onAddRange: () => Promise<void>;
}

const RangeActionsDropDown: FC<RangeActionsProps> = ({ onAddRange }): JSX.Element => {
	return (
		<ActionsDropdown
			pageTitle='Ofcom Range'
			actions={[
				{
					label: 'Add Range',
					userRole: OFCOM_DATA_ADMIN_ROLE,
					onSelection: onAddRange,
				},
			]}
		/>
	);
};

export default RangeActionsDropDown;
