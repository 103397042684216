export type OfcomRangeResponse = {
	id: number;
	numberBlock: string;
	status: string;
	numberLength: NumberLength;
	blockSize: number;
	allocationDate?: string;
	rangeHolder?: NumberRangeHolderResponse;
	hostingProvider?: NumberRangeHostingProviderResponse;
	notes?: string;
	lastUpdated: string;
};

export type NumberLength = {
	format: string;
	telnumLength: number;
	areaCodeLength: number;
};

export type NumberRangeHolderResponse = {
	name: string;
	type: string;
	rangeHolderId?: string;
	cupid?: string;
};

export type NumberRangeHostingProviderResponse = {
	name: string;
	cupid: string;
};

export type RangeHistory = {
	date: string;
	person: string;
	item: string;
	rangeId?: number;
};

export type RangeAdvancedSearchInput = {
	startDigits?: string;
	status?: RangeStatus;
	cupid?: string;
	ofcomRangeHolderId?: number;
};

export type RangeStatus =
	| 'ALLOCATED'
	| 'ALLOCATED_CLOSE_RANGE'
	| 'QUARANTINED'
	| 'FREE'
	| 'DESIGNATED'
	| 'PROTECTED'
	| 'RESERVED'
	| 'FREE_NATIONAL_DIALING';

export type RangeNumberLength =
	| 'FOUR_PLUS_SIX'
	| 'TWO_PLUS_EIGHT'
	| 'ZERO_PLUS_TEN'
	| 'THREE_PLUS_SEVEN'
	| 'FOUR_PLUS_FIVE'
	| 'FIVE_PLUS_FIVE'
	| 'FIVE_PLUS_FOUR'
	| 'MIXED_FOUR_PLUS_FIVE_SIX'
	| 'TEN_DIGIT_NUMBERS'
	| 'SEVEN_DIGIT_NUMBERS'
	| 'NINE_DIGIT_NUMBERS';

export const rangeStatusMap = new Map<string, RangeStatus>()
	.set('Allocated', 'ALLOCATED')
	.set('Allocated (Closed Range)', 'ALLOCATED_CLOSE_RANGE')
	.set('Quarantined', 'QUARANTINED')
	.set('Free', 'FREE')
	.set('Designated', 'DESIGNATED')
	.set('Protected', 'PROTECTED')
	.set('Reserved', 'RESERVED')
	.set('Free (National Dialling)', 'FREE_NATIONAL_DIALING');

export const numberLengthMap = new Map<string, RangeNumberLength>()
	.set('4+6', 'FOUR_PLUS_SIX')
	.set('2+8', 'TWO_PLUS_EIGHT')
	.set('(0)+10', 'ZERO_PLUS_TEN')
	.set('3+7', 'THREE_PLUS_SEVEN')
	.set('4+5', 'FOUR_PLUS_FIVE')
	.set('5+4', 'FIVE_PLUS_FOUR')
	.set('5+5', 'FIVE_PLUS_FIVE')
	.set('Mixed 4+5 and 4+6', 'MIXED_FOUR_PLUS_FIVE_SIX')
	.set('10 digit numbers', 'TEN_DIGIT_NUMBERS')
	.set('7 digit numbers', 'SEVEN_DIGIT_NUMBERS')
	.set('9 digit numbers', 'NINE_DIGIT_NUMBERS');

export type NumberHostingProviderRequest = {
	cupid: string;
};

export type NumberRangeHolderRequest = {
	type: string;
	rangeHolderId?: string;
	cupid?: string;
};

export type OfcomRangeRequest = {
	numberBlock?: String;
	status: RangeStatus;
	hostingProvider?: NumberHostingProviderRequest;
	rangeHolder?: NumberRangeHolderRequest;
	numberLength: RangeNumberLength;
	allocationDate?: string;
	comment?: string;
	notes?: string;
};
