/* eslint-disable no-unused-vars */
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useEventListener } from '../use-event-listener';

declare global {
	interface WindowEventMap {
		'local-storage': CustomEvent;
	}
}

type SetValue<T> = Dispatch<SetStateAction<T>>;

/**
 * Persist the state with local storage so that it remains after a page refresh. This can be useful for a dark theme or to record session information. This hook is used in the same way as useState except that you must pass the storage key in the 1st parameter. If the window object is not present (as in SSR), useLocalStorage() will return the default value.
 * @param {string} key: Key use to map to the value in local storage
 * @param {T} initialValue: the initial value to put in local storage
 * @return {Array}: Array containing the object and setFunction to update it
 * @Template T
 */
function useLocalStorage<T>(key: string, initialValue: T): [T, SetValue<T>] {
	// Get from local storage then parse stored json or return initialValue
	const readValue = useCallback((): T => {
		// Prevent build error "window is undefined" but keep keep working
		if (typeof window === 'undefined') {
			return initialValue;
		}

		try {
			const item = window.localStorage.getItem(key);
			return item ? (parseJSON(item) as T) : initialValue;
		} catch (error) {
			console.warn(`Error reading localStorage key "${key}":`, error);
			return initialValue;
		}
	}, [initialValue, key]);

	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState<T>(readValue);
	const setValueRef = useRef<SetValue<T>>();

	setValueRef.current = (value) => {
		// Prevent build error "window is undefined" but keeps working
		if (typeof window == 'undefined') {
			console.warn(`Tried setting localStorage key “${key}” even though environment is not a client`);
		}

		try {
			// Allow value to fe a function so we have the same API as useState
			const newValue = value instanceof Function ? value(storedValue) : value;

			// Save to local storage
			window.localStorage.setItem(key, JSON.stringify(newValue));

			// Save state
			setStoredValue(newValue);

			// Dispatch a custom event so every useLocalStorage hook is notified
			window.dispatchEvent(new Event('local-storage'));
		} catch (error) {
			console.warn(`Error setting localStorage key “${key}”:`, error);

			// fallback gracefully if user blocks localstorage
			const newValue = value instanceof Function ? value(storedValue) : value;
			setStoredValue(newValue);
		}
	};

	/*
	 * Return a wrapped version of useState's setter function that
	 * persists the new value to localStorage.
	 */
	const setValue: SetValue<T> = useCallback((value) => setValueRef.current?.(value), []);

	useEffect(() => {
		setStoredValue(readValue());
	}, []);

	const handleStorageChange = useCallback(() => {
		setStoredValue(readValue());
	}, [readValue]);

	// this only works for other documents, not the current one
	useEventListener('storage', handleStorageChange);

	// this is a custom event, triggered in writeValueToLocalStorage
	useEventListener('local-storage', handleStorageChange);

	return [storedValue, setValue];
}

export default useLocalStorage;

/**
 * A wrapper for "JSON.parse()" to support "undefined" value
 * @param {string} value JSON to parse
 * @return {T} The JSON object or undefined
 * @template T
 */
function parseJSON<T>(value: string | null): T | undefined {
	try {
		return value === 'undefined' ? undefined : JSON.parse(value ?? '');
	} catch {
		console.log('parsing error on', { value });
		return undefined;
	}
}
