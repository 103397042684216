import React from 'react';
import classnames from 'classnames';

export type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface AvatarProps {
	fullname: string;
	size?: AvatarSize;
}

const sizeClasses: Record<AvatarSize, string> = {
	xs: 'h-6 w-6',
	sm: 'h-8 w-8',
	md: 'h-10 w-10',
	lg: 'h-12 w-12',
	xl: 'h-14 w-14',
};

const textSizeClasses: Record<AvatarSize, string> = {
	xs: 'text-xs',
	sm: 'text-sm',
	md: '',
	lg: 'text-lg',
	xl: 'text-xl',
};

const colors: string[] = ['bg-blue-500', 'bg-red-500', 'bg-green-500', 'bg-gray-500', 'bg-pink-500', 'bg-orange-500', 'bg-yellow-500'];

/**
 * Function to render a Avatar component with placeholder initials
 * @param {AvatarProps} props: Required properties to render the component
 * @return {JSX.Element}: An Avatar component with placeholder initials
 */
const Avatar = ({ fullname, size = 'sm' }: AvatarProps) => {
	const names = fullname.split(' ');
	let initials = names[0].substring(0, 1).toUpperCase();

	if (names.length > 1) {
		initials += names[names.length - 1].substring(0, 1).toUpperCase();
	}

	const colorIndex = random(0, colors.length - 1);
	const color = colors[colorIndex];

	return (
		<span
			data-testid='avatar-container'
			className={classnames('inline-flex items-center justify-center rounded-full', sizeClasses[size], color)}
		>
			<span
				data-testid='avatar-display'
				className={classnames('font-medium leading-none text-black dark:text-white', textSizeClasses[size])}
			>
				{initials}
			</span>
		</span>
	);
};

export default Avatar;

/**
 * Function to get a random number from a range of values
 * @param {number} min: the start of the range
 * @param {number} max: the end of the range
 * @return {number}: a number between the min and max values inclusive
 */
function random(min: number = 0, max: number = 5): number {
	return Math.floor(Math.random() * max) + min;
}
