import React from 'react';
import classNames from 'classnames';
import { useIsAuthenticated } from '@azure/msal-react';
import { Tab } from '@headlessui/react';

import { Avatar, ScrollTop, UnauthorisedCard } from 'components';
import { useAppContext } from 'contexts';
import UserCalendar from './user-calendar';
import UserDetails from './user-details';

const Profile: React.FC = () => {
	const isAuthenticated = useIsAuthenticated();
	if (!isAuthenticated) {
		return (
			<main>
				<UnauthorisedCard />
			</main>
		);
	}

	const app = useAppContext();
	const user = app.user || {
		avatar: undefined,
		displayName: '',
		email: '',
		jobTitle: '',
		location: '',
		phone: '',
		manager: '',
		timeZone: '',
		roles: [],
	};

	return (
		<main>
			<div className='bg-gray-200 dark:bg-gray-700 px-4 py-5 border-b border-gray-200 sm:px-6'>
				<div className='-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap'>
					<div className='ml-4 mt-4'>
						<div className='flex items-center'>
							<div className='flex-shrink-0'>
								{user.avatar ? (
									<>
										<img className='h-14 w-14 rounded-full' src={user.avatar} alt={`${user.displayName} avatar`} />
									</>
								) : (
									<>
										<Avatar fullname={user.displayName ? user.displayName : ''} size='xl' />
									</>
								)}
							</div>
							<div className='ml-4'>
								<h3 className='text-lg leading-6 font-medium' data-testid='profile-username'>
									{user.displayName}
								</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<Tab.Group>
					<Tab.List className='flex ml-4 space-x-2 lg:ml-32 lg:space-x-8'>
						<Tab
							data-testid='profile-user-tab'
							className={({ selected }) =>
								classNames(
									selected
										? 'border-gamma-digital text-gamma-digital'
										: 'border-transparent text-gray-500 dark:text-gray-200 hover:text-gamma-hover hover:border-gamma-hover',
									'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
								)
							}
						>
							Details
						</Tab>
						<Tab
							data-testid='profile-calendar-tab'
							className={({ selected }) =>
								classNames(
									selected
										? 'border-gamma-digital text-gamma-digital'
										: 'border-transparent text-gray-500 dark:text-gray-200 hover:text-gamma-hover hover:border-gamma-hover',
									'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
								)
							}
						>
							Calendar
						</Tab>
					</Tab.List>
					<Tab.Panels className='mx-4 space-x-2 my-4 lg:mx-32 lg:space-x-8 lg:my-8'>
						<Tab.Panel>
							<UserDetails user={user} authProvider={app.authProvider} />
						</Tab.Panel>
						<Tab.Panel>
							<UserCalendar authProvider={app.authProvider} />
						</Tab.Panel>
					</Tab.Panels>
				</Tab.Group>
			</div>
			<ScrollTop />
		</main>
	);
};

export default Profile;
