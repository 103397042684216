import React from 'react';
import type { FC } from 'react';
import { OFCOM_DATA_ADMIN_ROLE } from 'utils';
import { ActionsDropdown } from 'components/actions-dropdown';

interface ProviderActionsProps {
	onAddProvider: () => Promise<void>;
}

const ProviderActionsDropDown: FC<ProviderActionsProps> = ({ onAddProvider }): JSX.Element => {
	return (
		<ActionsDropdown
			pageTitle='Communication Provider'
			actions={[
				{
					label: 'Add Provider',
					userRole: OFCOM_DATA_ADMIN_ROLE,
					onSelection: onAddProvider,
				},
			]}
		/>
	);
};

export default ProviderActionsDropDown;
