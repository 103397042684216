import React from 'react';
import { Link } from 'react-router-dom';

import { GammaPurpleIcon, GammaPurpleLogo, GammaWhiteIcon, GammaWhiteLogo } from 'assets';
import { useDarkMode } from 'hooks';

const Footer: React.FC = () => {
	const { isDarkMode } = useDarkMode();

	return (
		<footer className='p-4 rounded-lg shadow md:px-6 md:py-8' data-testid='layout-footer'>
			<div className='sm:flex sm:items-center sm:justify-between'>
				<a href='https://www.gamma.co.uk/' className='flex items-center mb-4 sm:mb-0'>
					<img
						data-testid='test-footer-small-logo'
						src={isDarkMode ? GammaWhiteIcon : GammaPurpleIcon}
						className='block lg:hidden h-8 w-auto'
						alt='Gamma Corporate Icon'
					/>
					<img
						data-testid='test-footer-large-logo'
						src={isDarkMode ? GammaWhiteLogo : GammaPurpleLogo}
						className='hidden lg:block h-8 w-auto'
						alt='Gamma Corporate Logo'
					/>
				</a>
				<ul className='flex flex-wrap items-center mb-6 text-sm text-gray-500 sm:mb-0 dark:text-gray-400'>
					<li>
						<Link to='/about' className='mr-4 hover:underline md:mr-6'>
							About
						</Link>
					</li>
					<li>
						<Link to='/support' className='mr-4 hover:underline md:mr-6'>
							Help &amp; Support
						</Link>
					</li>
					<li>
						<Link to='/team' className='mr-4 hover:underline md:mr-6'>
							Meet the Team
						</Link>
					</li>
					<li>
						<a href='mailto:cops-dev@gamma.co.uk' className='hover:underline'>
							Contact
						</a>
					</li>
				</ul>
			</div>
			<hr className='border-gray-200 sm:mx-auto dark:border-gray-700 md:my-4 lg:my-8' />
			<span className='block text-sm text-gray-500 sm:text-center dark:text-gray-400'>
				&copy; {new Date().getFullYear()}{' '}
				<a href='https://www.gamma.co.uk/' className='hover:underline'>
					Gamma
				</a>
				. All Rights Reserved.
			</span>
		</footer>
	);
};

export default Footer;
