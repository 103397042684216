import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ChevronDoubleUpIcon } from '@heroicons/react/24/outline';

export const ScrollTop = () => {
	const [visible, setVisible] = useState(false);

	const toggleVisibility = () => {
		window.scrollY > 300 ? setVisible(true) : setVisible(false);
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleVisibility);

		return () => {
			window.removeEventListener('scroll', toggleVisibility);
		};
	}, []);

	return (
		<div className='fixed bottom-5 right-5'>
			<button
				data-testid='scroll-top'
				type='button'
				onClick={scrollToTop}
				className={classNames(
					'bg-gamma-digital hover:bg-gamma-hover focus:ring-gamma-digital inline-flex items-center rounded-full p-3 text-white shadow-sm transition-opacity focus:outline-none focus:ring-2 focus:ring-offset-2',
					visible ? 'opacity-100' : 'opacity-0',
				)}
			>
				<span className='sr-only'>Scroll To Top</span>
				<ChevronDoubleUpIcon className='h-6 w-6' aria-hidden='true' />
			</button>
		</div>
	);
};

export default ScrollTop;
