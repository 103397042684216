import { FieldProps } from 'formik';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useMsal } from '@azure/msal-react';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { apiConfig } from 'auth-config';
import { Option } from 'types';
import { getAccessToken } from 'utils';

interface ProviderSelectProps {
	formikProps: FieldProps<any>;
	noSelectionLabel: string;
	optionFunction(token: string, selectOptions: Option[]): Promise<Option[]>;
	onChangeFunction(newValue: Option, formikFieldProps: FieldProps<any>): void;
	initialValue?: string;
	isDisabled?: boolean;
	id: string;
	disabled?: boolean;
	placeHolder?: string;
	errorKey: string;
}

const ProviderSelect = ({
	formikProps,
	noSelectionLabel,
	optionFunction,
	onChangeFunction,
	initialValue,
	id,
	disabled = false,
	placeHolder = 'Select a communication provider if appropriate',
	errorKey,
}: ProviderSelectProps): JSX.Element => {
	const [selectOptions, setSelectOptions] = useState<Option[]>([] as Option[]);
	const msal = useMsal();
	const ofcomApiScopes = apiConfig.ofcomApi.scopes;

	useEffect(() => {
		const initialise = async () => {
			const token = await getAccessToken(ofcomApiScopes, msal);
			const selectOptions = [] as Option[];
			selectOptions.push({
				label: noSelectionLabel,
				value: '',
			});
			setSelectOptions(await optionFunction(token, selectOptions));
		};
		initialise();
	}, []);

	const defaultValue = (options: Option[], value: string) => {
		return options ? options.find((option) => option.value === value) : '';
	};

	return (
		<div className='sm:col-span-2 relative rounded-md shadow-sm'>
			<Select
				aria-labelledby={id}
				aria-invalid={formikProps.meta.touched && formikProps.meta.error ? 'true' : 'false'}
				aria-describedby={formikProps.meta.touched && formikProps.meta.error ? `${errorKey}` : ''}
				options={selectOptions}
				id={id}
				inputId={id}
				isMulti={false}
				isDisabled={disabled}
				placeholder={placeHolder}
				name={formikProps.field.name}
				value={defaultValue(selectOptions, initialValue ? initialValue : formikProps.field.value)}
				onChange={(newValue) => {
					onChangeFunction(newValue as Option, formikProps);
				}}
				onBlur={() => {
					formikProps.form.handleBlur({ target: { name: formikProps.field.name } });
				}}
				className={formikProps.meta.touched && formikProps.meta.error ? 'searchable-select-error-container' : 'searchable-select-container'}
				classNamePrefix={formikProps.meta.touched && formikProps.meta.error ? 'searchable-select-error' : 'searchable-select'}
			/>
			{formikProps.meta.touched && formikProps.meta.error && (
				<div className='absolute inset-y-0 -top-7 right-0 pr-3 flex items-center pointer-events-none'>
					<ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' data-testid={`${id}-error-icon`} />
				</div>
			)}
			{formikProps.meta.touched && formikProps.meta.error && (
				<p className='mt-2 text-sm text-red-600' id={errorKey} data-testid={`${id}-error`}>
					{formikProps.meta.error}
				</p>
			)}
		</div>
	);
};

export default ProviderSelect;
