import React, { Dispatch, SetStateAction, useState } from 'react';
import ProviderEdit from './provider-edit';
import ProviderView from './provider-view';
import { CommunicationProvider } from 'types';

interface ProviderViewContainerProps {
	provider: CommunicationProvider;
	setSearchInput: Dispatch<SetStateAction<string>>;
	onClose: () => void;
}

const ProviderViewContainer: React.FC<ProviderViewContainerProps> = ({ provider, setSearchInput, onClose }) => {
	const [editMode, setEditMode] = useState(false);
	return (
		<>
			{editMode ? (
				<ProviderEdit provider={provider} setEditMode={setEditMode} setSearchInput={setSearchInput} onClose={onClose} />
			) : (
				<ProviderView provider={provider} setEditMode={setEditMode} />
			)}
		</>
	);
};

export default ProviderViewContainer;
