import { useIsAuthenticated } from '@azure/msal-react';
import { useAppContext } from 'contexts';

/**
 * Simple React hook to check that a user is logged in and has a specified role
 * @param {string} role: role to check that user has
 * @return {boolean}: true if the user is logged in and has the required role otherwise false
 */
function useHasRole(role: string): boolean {
	const loggedIn = useIsAuthenticated();

	if (!loggedIn) {
		return false;
	}

	const { user } = useAppContext();

	if (!user) {
		return false;
	}

	return user.roles.indexOf(role) !== -1;
}

export default useHasRole;
