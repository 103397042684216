import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import {
	add,
	eachDayOfInterval,
	endOfMonth,
	format,
	getDay,
	isEqual,
	isSameDay,
	isSameMonth,
	isToday,
	parse,
	parseISO,
	startOfToday,
} from 'date-fns';
import { Event } from 'microsoft-graph';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';

import { Avatar } from 'components';
import { GraphService } from 'services';
import { isBST } from 'utils';

interface CalendarProps {
	authProvider: AuthCodeMSALBrowserAuthenticationProvider | undefined;
}

/**
 * The User Calendar component
 * @return {JSX.Element}: The users calendar
 */
export default function UserCalendar({ authProvider }: CalendarProps) {
	const today = startOfToday();
	const [selectedDay, setSelectedDay] = useState(today);
	const [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'));
	const firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date());
	const [meetings, setMeetings] = useState([] as Event[]);

	const days = eachDayOfInterval({
		start: firstDayCurrentMonth,
		end: endOfMonth(firstDayCurrentMonth),
	});

	/**
	 * Toggle the calendar to go to the previous month
	 */
	function togglePreviousMonth() {
		const firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
		setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
	}

	/**
	 * Toggle the calendar to go to the next month
	 */
	function toggleNextMonth() {
		const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
		setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
	}

	/**
	 * Load meetings for the selectedDay
	 */
	async function getSelectedDayMeetings() {
		const selectedMeetings = await GraphService.getUserDayEvents(authProvider, selectedDay);
		setMeetings(selectedMeetings);
	}

	useEffect(() => {
		getSelectedDayMeetings();
	}, [selectedDay]);

	return (
		<div className='pt-16'>
			<div className='max-w-md px-4 mx-auto sm:px-7 md:max-w-4xl md:px-6'>
				<div className='md:grid md:grid-cols-2 md:divide-x md:divide-gray-200'>
					<div className='md:pr-14'>
						<div className='flex items-center'>
							<h2 className='flex-auto font-semibold'>{format(firstDayCurrentMonth, 'MMMM yyyy')}</h2>
							<button
								type='button'
								onClick={togglePreviousMonth}
								className='-my-1.5 flex flex-none items-center justify-center p-1.5 text-gamma-digital hover:text-gamma-hover'
								data-testid='user-calendar-previous-month'
							>
								<span className='sr-only'>Previous Month</span>
								<ChevronLeftIcon className='w-5 h-5' aria-hidden='true' />
							</button>
							<button
								type='button'
								onClick={toggleNextMonth}
								className='-my-1.5 flex flex-none items-center justify-center p-1.5 text-gamma-digital hover:text-gamma-hover'
								data-testid='user-calendar-next-month'
							>
								<span className='sr-only'>Next Month</span>
								<ChevronRightIcon className='w-5 h-5' aria-hidden='true' />
							</button>
						</div>
						<div className='grid grid-cols-7 mt-10 text-xs leading-6 text-center'>
							<div>S</div>
							<div>M</div>
							<div>T</div>
							<div>W</div>
							<div>T</div>
							<div>F</div>
							<div>S</div>
						</div>
						<div className='grid grid-cols-7 mt-2 text-sm'>
							{days.map((day, dayIdx) => (
								<div key={day.toString()} className={classNames(dayIdx === 0 && colStartClasses[getDay(day)], 'py-1.5')}>
									<button
										type='button'
										onClick={() => setSelectedDay(day)}
										className={classNames(
											isEqual(day, selectedDay) && 'text-white',
											!isEqual(day, selectedDay) && isToday(day) && 'text-gamma-digital',
											!isEqual(day, selectedDay) &&
												!isToday(day) &&
												isSameMonth(day, firstDayCurrentMonth) &&
												'text-gray-900 dark:text-gray-50',
											!isEqual(day, selectedDay) && !isToday(day) && !isSameMonth(day, firstDayCurrentMonth) && 'text-gray-400',
											isEqual(day, selectedDay) && isToday(day) && 'text-gamma-digital',
											isEqual(day, selectedDay) && !isToday(day) && 'bg-purple-dark',
											!isEqual(day, selectedDay) && 'hover:bg-gamma-hover hover:text-white',
											(isEqual(day, selectedDay) || isToday(day)) && 'font-semibold',
											'mx-auto flex h-8 w-8 items-center justify-center rounded-full',
										)}
									>
										<time dateTime={format(day, 'yyyy-MM-dd')}>{format(day, 'd')}</time>
									</button>

									<div className='w-1 h-1 mx-auto mt-1'>
										{Array.from(meetings).some((meeting) => isSameDay(parseISO(meeting.start?.dateTime as string), day)) && (
											<div className='w-1 h-1 rounded-full bg-gamma-digital'></div>
										)}
									</div>
								</div>
							))}
						</div>
					</div>
					<section className='mt-12 md:mt-0 md:pl-14'>
						<h2 className='font-semibold'>
							Schedule for <time dateTime={format(selectedDay, 'yyyy-MM-dd')}>{format(selectedDay, 'MMM dd, yyy')}</time>
						</h2>
						<ol className='mt-4 space-y-1 text-sm leading-6'>
							{meetings ? (
								Array.from(meetings).map((meeting) => <Meeting meeting={meeting} key={meeting.id} />)
							) : (
								<p>No meetings for today.</p>
							)}
						</ol>
					</section>
				</div>
			</div>
		</div>
	);
}

interface MeetingProps {
	meeting: Event;
}

/**
 * A React {@link JSX.Element} for rending the information regarding a meeting
 * @param {Event} meeting:
 * @return {JSX.Element}
 */
function Meeting({ meeting }: MeetingProps) {
	let startDateTime = parseISO(meeting.start?.dateTime as string);
	let endDateTime = parseISO(meeting.end?.dateTime as string);

	if (isBST(startDateTime)) {
		startDateTime = add(startDateTime, { hours: 1 });
	}

	if (isBST(endDateTime)) {
		endDateTime = add(endDateTime, { hours: 1 });
	}

	return (
		<li className='flex items-center px-4 py-2 space-x-4 group rounded-xl'>
			<div className='flex-none'>
				<Avatar fullname={meeting.organizer?.emailAddress?.name ? meeting.organizer.emailAddress.name : ''} size='md' />
			</div>
			<div className='flex-auto'>
				<p>{meeting.subject ? meeting.subject : ''}</p>
				<p className='mt-0.5'>
					<time dateTime={meeting.start?.dateTime}>{format(startDateTime, 'h:mm a')}</time> -{' '}
					<time dateTime={meeting.end?.dateTime}>{format(endDateTime, 'h:mm a')}</time>
				</p>
			</div>
		</li>
	);
}

const colStartClasses = ['', 'col-start-2', 'col-start-3', 'col-start-4', 'col-start-5', 'col-start-6', 'col-start-7'];
