import type { ProviderTypeCountStat, AgreementProviderCountStat } from 'types';
import { apiConfig } from 'auth-config';
import { createRequestHeader } from 'utils';

const baseUrl = apiConfig.ofcomApi.baseUrl;

/**
 * Get the provider counts per category. Returned categories are Parent Providers, Hosting Partners and Porting Partners
 *
 * @param {string} token:  Access token for API requests
 * @return {Promise<ProviderTypeCountStat[]>}
 */
export async function getProviderTypeCounts(token: string): Promise<ProviderTypeCountStat[]> {
	const getUrl: string = `${baseUrl}/stat/provider`;
	return await (await fetch(getUrl, createRequestHeader(token, 'GET'))).json();
}

/**
 * Get the import port agreement counts per provider.
 *
 * @param {string} token:  Access token for API requests
 * @return {Promise<AgreementProviderCountStat[]>}
 */
export async function getAgreementProviderTypeCounts(token: string): Promise<AgreementProviderCountStat[]> {
	const getUrl: string = `${baseUrl}/stat/agreement`;
	return await (await fetch(getUrl, createRequestHeader(token, 'GET'))).json();
}
