/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import classNames from 'classnames';
import { CalendarIcon, CloudArrowUpIcon, PhoneArrowDownLeftIcon } from '@heroicons/react/24/outline';
import type { FC } from 'react';
import { format } from 'date-fns';

import type { CommunicationProvider } from 'types';
import { GammaIcon } from 'components';

interface ProviderCardProps {
	provider: CommunicationProvider;
	onSelection: (cupid: string) => Promise<void>;
}

const ProviderCard: FC<ProviderCardProps> = ({ provider, onSelection }): JSX.Element => {
	return (
		<div
			data-testid={`provider-card-${provider.cupid}`}
			aria-hidden='true'
			onClick={(e) => {
				e.preventDefault();
				onSelection(provider.cupid);
			}}
			className='block hover:bg-gray-50 dark:hover:bg-gray-600'
		>
			<div className='px-4 py-4 sm:px-6'>
				<div className='flex items-center justify-between'>
					<p className='text-lg font-medium text-gamma-digital truncate dark:hover:text' data-testid='cupid-name'>
						{`(${provider.cupid})`} {provider.name}{' '}
						<span className='dark:text-gray-200 text-gray-600'>{provider.franchiseArea ? `${provider.franchiseArea}` : ''}</span>
					</p>
					<div className='ml-2 flex-shrink-0 flex'>
						<GammaIcon
							aria-hidden='true'
							data-testid='gamma-icon'
							className={classNames('flex-shrink-0 mr-1.5 h-5 w-5', provider.flags.gamma ? 'text-gamma-digital' : 'text-gray-400')}
						/>
						<CloudArrowUpIcon
							aria-hidden='true'
							data-testid='cloud-icon'
							className={classNames('flex-shrink-0 mr-1.5 h-5 w-5', provider.flags.hostingPartner ? 'text-gamma-digital' : 'text-gray-400')}
						/>
						<PhoneArrowDownLeftIcon
							aria-hidden='true'
							data-testid='phone-icon'
							className={classNames('flex-shrink-0 mr-1.5 h-5 w-5', provider.flags.portingPartner ? 'text-gamma-digital' : 'text-gray-400')}
						/>
					</div>
				</div>
				<div className='mt-2 sm:flex sm:justify-between'>
					<div className='sm:flex'>
						<p className='flex items-center text-sm' data-testid='parent-name'>
							{provider.parent ? (
								<a
									href='#'
									className='text-blue-500 dark:text-gray-400 hover:text-blue-800 visited:text-purple-600'
									onClick={(e) => {
										e.stopPropagation();
										e.preventDefault();
										onSelection(provider.parent!.cupid);
									}}
								>{`(${provider.parent!.cupid}) ${provider.parent!.name}`}</a>
							) : null}
						</p>
					</div>
					<div className='mt-2 flex items-center text-sm text-gray-500 sm:mt-0'>
						<CalendarIcon aria-hidden='true' className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400' />
						<p data-testid='last-updated'>
							Last Updated{' '}
							<time dateTime={format(new Date(provider.lastUpdated), 'dd/MM/yyyy')}>
								{format(new Date(provider.lastUpdated), 'dd/MM/yyyy')}
							</time>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProviderCard;
