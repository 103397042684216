import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { FieldProps } from 'formik';
import React from 'react';

interface DependentToogleSwitchProps {
	formikProps: FieldProps<any>;
	disabledCondition: boolean;
	screenReaderText: string;
	id: string;
	onChange: (checked: boolean) => void;
}

/**
 *
 * @param {DependentToogleSwitchProps} param0
 * @return {JSX.Element}
 */
const DependentToogleSwitch = ({
	formikProps,
	disabledCondition,
	screenReaderText,
	id,
	onChange,
}: DependentToogleSwitchProps): JSX.Element => {
	return (
		<div className='sm:col-span-2'>
			<Switch
				id={id}
				data-testid={id}
				checked={formikProps.field.value}
				onChange={onChange}
				disabled={disabledCondition}
				className={classNames(
					formikProps.field.value ? 'bg-gamma-digital' : 'bg-gray-400',
					disabledCondition ? 'pointer-events-none cursor-not-allowed opacity-50' : '',
					'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-150 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gamma-hover',
				)}
				name={formikProps.field.name}
				value={formikProps.field.value}
				onBlur={formikProps.field.onBlur}
			>
				<span className='sr-only' data-testid={`${id}-screen-reader`}>
					{screenReaderText}
				</span>
				<span
					aria-hidden='true'
					className={classNames(
						formikProps.field.value ? 'translate-x-5' : 'translate-x-0',
						'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-100',
					)}
				/>
			</Switch>
		</div>
	);
};

export default DependentToogleSwitch;
