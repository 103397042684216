/**
 * Config object to be passed to Msal and Graph API related functions
 * @todo Finalize tenant and client id and move auth to env variables
 */
const authConfig = {
	clientId: process.env.REACT_APP_AZURE_AD_CLIENTID as string,
	authority: process.env.REACT_APP_AZURE_AD_AUTHORITY as string,
	redirectUri: process.env.REACT_APP_AZURE_AD_REDIRECT_URL as string,
	postLogoutRedirectUri: '/',
	navigateToLoginRequestUrl: false,
	cacheLocation: 'sessionStorage',
	storeAuthStateInCookie: true,
	scopes: ['User.Read', 'profile', 'openid', 'User.ReadBasic.All', 'Calendars.ReadWrite', 'MailboxSettings.Read'],
};

export const apiConfig = {
	ofcomApi: {
		scopes: [(('api://' + process.env.REACT_APP_OFCOM_SCOPE_ID) as string) + '/access_as_user'],
		baseUrl: process.env.REACT_APP_API_BASE_URL as string,
	},
};

export default authConfig;
