import { useMsal } from '@azure/msal-react';
import { apiConfig } from 'auth-config';
import { EditLabel, Spinner } from 'components';
import { FormikProps } from 'formik';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CommunicationProviderService } from 'services';
import { PortAgreementAction, PortAgreementItem, StepItemStatus } from 'types';
import { getAccessToken } from 'utils';

interface PortAgreementFormSummary {
	agreement: PortAgreementItem | null;
	action: PortAgreementAction;
	formProps: FormikProps<any>;
	setShowSummary: Dispatch<SetStateAction<boolean>>;
	updateStep: (stepIdx: number, stepStatus: StepItemStatus) => void;
}

const PortAgreementFormSummary = ({ agreement, action, formProps, setShowSummary, updateStep }: PortAgreementFormSummary) => {
	const [agreementHolder, setAgreementHolder] = useState('');
	const [numberRanges, setNumberRanges] = useState([] as string[]);
	const [pdiChange, setPdiChange] = useState('Unchanged');

	const msal = useMsal();
	const ofcomApiScopes = apiConfig.ofcomApi.scopes;

	useEffect(() => {
		const loadProviderInfo = async () => {
			if (agreement) {
				setAgreementHolder(`(${agreement.agreementHolder.cupid}) ${agreement.agreementHolder.name}`);
				return;
			}

			const token = await getAccessToken(ofcomApiScopes, msal);
			const provider = await CommunicationProviderService.getProvider(token, formProps.values.agreementHolder);

			setAgreementHolder(`(${provider.cupid}) ${provider.name}`);
		};

		const parseNumberBlocks = async () => {
			if (agreement) {
				setNumberRanges([`+44${agreement.numberBlock}`]);
				return;
			}

			const blockInput = formProps.values.numberBlock as string;
			setNumberRanges(blockInput.split(/;|,|\n/));
		};

		const pdiChangeCalculation = async () => {
			const pdiInput = formProps.values.pdi as File | null | undefined;
			if (!agreement) {
				setPdiChange(pdiInput ? pdiInput.name : 'No PDI Supplied');
				return;
			}

			const currPdi = agreement.pdi;
			setPdiChange(!pdiInput ? 'Unchanged' : !currPdi ? pdiInput.name : pdiInput.name != currPdi.key ? pdiInput.name : 'Unchanged');
		};

		loadProviderInfo();
		parseNumberBlocks();
		pdiChangeCalculation();
	}, []);

	return (
		<>
			{/* Divider container */}
			<div className='space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0' data-testid='agreement-form-summary'>
				{/* Number Block Display */}
				{action == 'EDIT' ? (
					<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
						<EditLabel label='Number Range' id='number-range' />
						<div className='block text-sm font-medium text-gray-800 dark:text-gray-200 sm:mt-px sm:pt-2'>+44{agreement?.numberBlock}</div>
					</div>
				) : null}
				{/* Communication Provider Input/Display */}
				<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
					<EditLabel label='Communication Provider' id='agreement-holder' />
					<div className='block text-sm font-medium text-gray-800 dark:text-gray-200 sm:mt-px sm:pt-2'>{agreementHolder}</div>
				</div>
				{/* Port Prefix */}
				<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
					<EditLabel label='Prefix' id='port-prefix' />
					<div className='block text-sm font-medium text-gray-800 dark:text-gray-200 sm:mt-px sm:pt-2'>{formProps.values.portPrefix}</div>
				</div>
				{/* PDI */}
				<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
					<EditLabel label='PDI' id='pdi' />
					<div className='block text-sm font-medium text-gray-800 dark:text-gray-200 sm:mt-px sm:pt-2'>{pdiChange}</div>
				</div>
				{/* Number Ranges */}
				{action != 'EDIT' ? (
					<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
						<EditLabel label='Number Ranges' id='number-ranges' />
						<div className='block text-sm font-medium text-gray-800 dark:text-gray-200 sm:mt-px sm:pt-2'>
							<ul className='max-w-md space-y-1 list-disc list-inside py-2'>
								{numberRanges.map((range, rangeIdx) => (
									<li key={rangeIdx}>+44{range}</li>
								))}
							</ul>
						</div>
					</div>
				) : null}
				{/* Reason for Change */}
				<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
					<EditLabel label='Reason for Change' id='change-reason' />
					<div className='block text-sm font-medium text-gray-800 dark:text-gray-200 sm:mt-px sm:pt-2'>{formProps.values.changeReason}</div>
				</div>
			</div>
			{/* Action buttons */}
			<div className='flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6'>
				<div className='flex justify-end space-x-3'>
					<button
						data-testid='return-to-form-button'
						type='button'
						onClick={() => {
							updateStep(1, 'UPCOMING');
							updateStep(0, 'CURRENT');
							setShowSummary(false);
						}}
						className='rounded-md border border-gray-300 bg-gray-50 dark:bg-gray-800 py-2 px-4 text-sm font-medium text-gray-800 dark:text-white shadow-sm hover:bg-gamma-digital hover:text-white dark:hover:bg-gamma-digital focus:outline-none focus:ring-2 focus:ring-gamma-digital focus:ring-offset-2 dark:focus:text-white'
					>
						Cancel
					</button>
					<button
						data-testid='submit-button'
						type='submit'
						disabled={formProps.isSubmitting}
						onClick={(e) => {
							e.preventDefault();
							formProps.submitForm();
						}}
						className='ml-4 inline-flex justify-center rounded-md border border-transparent bg-gamma-digital py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gamma-hover focus:outline-none focus:ring-2 focus:ring-gamma-hover focus:ring-offset-2'
					>
						{formProps.isSubmitting ? <Spinner color='button' /> : null}
						&nbsp;Submit
					</button>
				</div>
			</div>
		</>
	);
};

export default PortAgreementFormSummary;
