import { useRef } from 'react';

/**
 * Simple React hook that returns a boolean to indicate true at mount time then always false
 * @return {boolean}: true at mount time, otherwise false
 */
function useIsFirstRender(): boolean {
	const isFirst = useRef(true);

	if (isFirst.current) {
		isFirst.current = false;
		return true;
	}

	return isFirst.current;
}

export default useIsFirstRender;
