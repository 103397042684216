import React from 'react';

import { ScrollTop, TeamMember } from 'components';
import TeamMemberJson from './team-members.json';

const Team: React.FC = () => (
	<main className='min-h-full'>
		<div className='mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24'>
			<div className='space-y-12'>
				<div className='space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none'>
					<h1 className='text-3xl font-extrabold text-gamma-digital tracking-tight sm:text-4xl'>Meet our team</h1>
					<p className='text-xl'>
						Meet the team responsible for the development of the Numbering and Porting Agent Console and the continuous improvement to the
						Numbering and Porting technical space within Gamma
					</p>
				</div>
				<ul id='team' className='space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-3 lg:gap-8'>
					{TeamMemberJson.map((member) => (
						<li key={member.name} className='py-10 px-6 bg-gray-100 dark:bg-gray-400 text-center rounded-lg xl:px-10 xl:text-left'>
							<TeamMember
								name={member.name}
								role={member.role}
								email={member.emailUrl}
								location={member.location}
								image={member.imageUrl}
							/>
						</li>
					))}
				</ul>
			</div>
		</div>
		<ScrollTop />
	</main>
);

export default Team;
