import { apiConfig } from 'auth-config';
import type { CommunicationProvider, ParentProvider, ProviderHistory } from 'types';
import { createRequestHeader } from '../../utils';

export interface ProviderEditInput {
	cupid?: string;
	name: string;
	franchiseArea?: string;
	gamma: boolean;
	hostingPartner: boolean;
	portingPartner: boolean;
	singleLineEmail?: string;
	multiLineEmail?: string;
	shortName: string;
	parentCupid?: string;
	changeReason?: string;
}

const baseUrl = apiConfig.ofcomApi.baseUrl;

/**
 * Full text search of communication providers based on user input returning the inputted page
 * of matching results with parent providers being sorted first.
 *
 * @param {string} token: Access token for API requests
 * @param {string} searchTerm: users search term to filter communication providers against
 * @param {number} page: the page of results to return
 * @param {number} pageSize: the number of results in the page
 * @return {Promise<CommunicationProvider[]>}: the selected page of results
 */
export async function searchProviders(token: string, searchTerm: string, page: number, pageSize: number): Promise<CommunicationProvider[]> {
	const searchUrl: string = searchTerm ? `${baseUrl}/cupid?q=${searchTerm}&` : `${baseUrl}/cupid?`;
	const endpoint: string = searchUrl + `page=${page}&perPage=${pageSize}`;
	return await (await fetch(endpoint, createRequestHeader(token, 'GET'))).json();
}

/**
 * Get a communication provider based on its cupid. Note this is using a placeholder
 * API so will need to be repointed to the actual API depending on the deployment environment
 * with any auth or query param changes applied
 * @param {string} token: Access token for API requests
 * @param {string} cupid: Id of communication provider
 * @return {Promise<CommunicationProvider>}: the identified communication provider
 */
export async function getProvider(token: string, cupid: string): Promise<CommunicationProvider> {
	const getUrl: string = `${baseUrl}/cupid/${parseInt(cupid)}`;
	return await (await fetch(getUrl, createRequestHeader(token, 'GET'))).json();
}

/**
 * Get the history of the supplied communication provider with the most recent event being the first item in the list.
 * Note this is using a placeholder API so will need to be repointed to the actual API depending on the deployment
 * environment with any auth or query param changes applied
 * @param {string} token: Access token for API requests
 * @param {string} id: Id of communication provider
 * @param {number} page: the page of results to return
 * @param {number} pageSize: the number of results in the page
 * @return {Promise<ProviderHistory[]>}: The history items for the communication provider
 */
export async function getProviderHistory(token: string, id: string, page?: number, pageSize?: number): Promise<ProviderHistory[]> {
	const historyUrl: string = `${baseUrl}/cupid/${parseInt(id)}/history`;
	const endpoint: string = page && pageSize ? historyUrl + `?page=${page}&perPage=${pageSize}` : historyUrl;
	return await (await fetch(endpoint, createRequestHeader(token, 'GET'))).json();
}

/**
 * Get the children communication providers for the supplied communication provider ordered by cupid. Note this is using a placeholder API so will
 * need to be repointed to the actual API depending on the deployment environment with any auth or query param changes applied
 * @param {string} token: Access token for API requests
 * @param {string} cupid: Id of communication provider
 * @param {number} page: the page of results to return
 * @param {number} pageSize: the number of results in the page
 * @return {Promise<CommunicationProvider[]>}: list of child communication providers if any
 */
export async function getChildProviders(token: string, cupid: string, page?: number, pageSize?: number): Promise<CommunicationProvider[]> {
	const childrenUrl: string = `${baseUrl}/cupid/${cupid}/children`;
	const endpoint: string = page && pageSize ? childrenUrl + `?page=${page}&perPage=${pageSize}` : childrenUrl;
	return await (await fetch(endpoint, createRequestHeader(token, 'GET'))).json();
}

/**
 * Get the parent communication providers currently in the system. At the moment just gets the entire list from the placeholder API and then in the React
 * component(s) that call this endpoint we filter to only include those without an parent element. This will need to be repointed to the actual API
 * depending on the deployment environment with any auth or query param changes applied. Then remove the manual filtering in the React component(s)
 * which are calling this service method
 * @param {string} token: Access token for API requests
 * @return {Promise<CommunicationProvider[]>}: list of parent communication providers if any
 */
export async function getParentProviders(token: string): Promise<CommunicationProvider[]> {
	const parentUrl: string = `${baseUrl}/cupid/parents`;
	return await (await fetch(parentUrl, createRequestHeader(token, 'GET'))).json();
}

/**
 * Update the information associated with the supplied cupid based on the user input from the edit communication provider function. This will need to
 * be repointed to the actual API depending on the deployment environment with any auth, request method and request input changes being applied.
 * @param {string} token
 * @param {string} cupid
 * @param {ProviderEditInput} updates
 */
export async function updateProvider(token: string, cupid: string, updates: ProviderEditInput): Promise<Response> {
	const updateUrl: string = `${baseUrl}/cupid/${parseInt(cupid)}`;

	const singleLineEmail =
		updates.singleLineEmail && updates.singleLineEmail !== '' ? updates.singleLineEmail.split(';').map((e) => e.trim()) : undefined;

	const multiLineEmail =
		updates.multiLineEmail && updates.multiLineEmail !== '' ? updates.multiLineEmail.split(';').map((e) => e.trim()) : undefined;

	const parent =
		updates.parentCupid && updates.parentCupid !== ''
			? providerToParentRepresentation(await getProvider(token, updates.parentCupid))
			: undefined;

	const updateData = {
		name: updates.name,
		franchiseArea: updates.franchiseArea,
		shortName: updates.shortName,
		flags: {
			gamma: updates.gamma,
			hostingPartner: updates.hostingPartner,
			portingPartner: updates.portingPartner,
		},
		singleLineEmail: singleLineEmail,
		multiLineEmail: multiLineEmail,
		parent: parent,
		comment: updates.changeReason,
	};

	return await fetch(updateUrl, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(updateData),
	});
}

/**
 * Add a new commication provider with user input from the edit communication provider function.
 * @param {string} token
 * @param {ProviderEditInput} details
 */
export async function addProvider(token: string, details: ProviderEditInput): Promise<Response> {
	const addUrl: string = `${baseUrl}/cupid`;

	const singleLineEmail =
		details.singleLineEmail && details.singleLineEmail !== '' ? details.singleLineEmail.split(';').map((e) => e.trim()) : undefined;

	const multiLineEmail =
		details.multiLineEmail && details.multiLineEmail !== '' ? details.multiLineEmail.split(';').map((e) => e.trim()) : undefined;

	const parent =
		details.parentCupid && details.parentCupid !== ''
			? providerToParentRepresentation(await getProvider(token, details.parentCupid))
			: undefined;

	const requestBody = {
		cupid: details.cupid,
		name: details.name,
		franchiseArea: details.franchiseArea,
		shortName: details.shortName,
		flags: {
			gamma: details.gamma,
			hostingPartner: details.hostingPartner,
			portingPartner: details.portingPartner,
		},
		singleLineEmail: singleLineEmail,
		multiLineEmail: multiLineEmail,
		parent: parent,
		comment: details.changeReason,
	};

	return await fetch(addUrl, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(requestBody),
	});
}

/**
 * Simple utility to map from a full CommunicationProvider representation to a ParentProvider
 * representation
 * @param {CommunicationProvider} provider
 * @return {ParentProvider}
 */
function providerToParentRepresentation(provider: CommunicationProvider): ParentProvider {
	return {
		name: provider.name,
		cupid: provider.cupid,
		franchiseArea: provider.franchiseArea,
	};
}
