import type { PropsWithChildren } from 'react';

export interface ExcludeProps {
	key: string;
	source: Record<string, unknown>;
}

/**
 * Function to remove a key from the given props
 * @param {PropsWithChildren<object>} props : props to remove the key from
 * @return {object}
 */
export const excludeClassName = (props: PropsWithChildren<object>): object => {
	return exclude({
		key: 'className',
		source: props,
	});
};

/**
 * Function to return a source object with the provided key excluded
 *
 * @param {ExcludeProps} param: The object source and the object key to be removed from it
 * @return {object}: Return source with the provided key removed
 */
const exclude = ({ key, source }: ExcludeProps): object => {
	delete source[key];
	return source;
};

export default exclude;
