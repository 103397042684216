import React from 'react';
import { Header } from '../header';
import { Footer } from '../footer';

interface LayoutProps {
	children: React.ReactNode;
}

/**
 * Function to display the application layout
 * @param {LayoutProps} param0
 * @return {JSX.Element}: The Layout component
 */
export default function Layout({ children }: LayoutProps) {
	return (
		<div className='min-h-screen flex flex-col'>
			<Header />
			{children}
			<Footer />
		</div>
	);
}
