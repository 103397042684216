import React from 'react';
import type { FC } from 'react';
import { useHasRole } from 'hooks';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { Menu } from '@headlessui/react';
import type { SearchAction } from 'types';

interface ActionsProps {
	pageTitle: string;
	actions: SearchAction[];
}

const ActionsDropDown: FC<ActionsProps> = ({ pageTitle, actions }): JSX.Element => {
	// create a new array with a userRole resovled
	const actionsWithRolesResolved = actions.map((action) => ({ ...action, hasRole: useHasRole(action.userRole) }));

	// if user does not have any of the roles, return an empty fragment
	if (!actionsWithRolesResolved.some((action) => action.hasRole)) {
		return <></>;
	}

	return (
		<Menu as='div' className='h-8 w-8 rounded-md bg-gamma-digital'>
			<Menu.Button data-testid='actions-menu'>
				<span className='sr-only'>{`${pageTitle} Actions`}</span>
				<EllipsisHorizontalIcon className='h-8 w-8 text-gray-50' />
			</Menu.Button>
			<Menu.Items className='absolute w-40 origin-top-right bg-gray-100 dark:bg-gray-500 rounded-md shadow-lg focus:outline-none'>
				{actionsWithRolesResolved.map((action, actionIdx) =>
					action.hasRole ? (
						<Menu.Item key={actionIdx}>
							{({ active }) => (
								<div
									className={`${active && 'bg-gamma-digital text-gray-50'} group flex rounded-md items-center w-full px-2 py-2 text-sm`}
									onClick={action.onSelection}
									aria-hidden='true'
									data-testid={`action-${actionIdx}-${action.label}`}
								>
									{action.label}
								</div>
							)}
						</Menu.Item>
					) : (
						<></>
					),
				)}
			</Menu.Items>
		</Menu>
	);
};

export default ActionsDropDown;
