import React from 'react';
import { useAppContext } from '../../contexts';
import { CallCenter } from '../../assets';

const UnauthenticatedLanding: React.FC = () => {
	const app = useAppContext();

	return (
		<main className='lg:relative'>
			<div className='mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left'>
				<div className='px-4 lg:w-1/2 sm:px-8 xl:pr-16'>
					<h1 className='text-4xl tracking-tight font-extrabold sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl'>
						<span className='block xl:inline text-gamma-digital'>NPAC:</span>{' '}
						<span className='block xl:inline'>Numbering and Porting Agent Console</span>
					</h1>
					<p className='mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl'>
						The new way to manage number ports, porting agreements and more
					</p>
					<div className='mt-10 sm:flex sm:justify-center lg:justify-start'>
						<div className='rounded-md shadow'>
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							<a
								href='#'
								className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10'
								onClick={app.signIn}
								data-testid='get-started'
							>
								Get started
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className='relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full'>
				<img
					className='absolute inset-0 w-full h-full object-cover'
					src={CallCenter}
					alt='Call centre operator and administrator'
					data-testid='landing-image'
				/>
			</div>
		</main>
	);
};

export default UnauthenticatedLanding;
