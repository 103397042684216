import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Formik, Field, FieldProps, Form, FormikProps, FormikState } from 'formik';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';

import { EditLabel, EditTextField, GammaFeedbackToast, ProviderSelect, Spinner, TextArea } from 'components';
import { CommunicationProviderService } from 'services';
import type { CommunicationProvider, Option } from 'types';
import { useMsal } from '@azure/msal-react';
import { getAccessToken } from 'utils';
import { apiConfig } from 'auth-config';
import { DependentToogleSwitch } from './provider-components';

interface ProviderEditProps {
	provider: CommunicationProvider | null;
	setEditMode: Dispatch<SetStateAction<boolean>>;
	setSearchInput: Dispatch<SetStateAction<string>>;
	onClose: () => void;
	isProviderAddForm?: boolean;
}

const shortNameRegExp = new RegExp('^[s a-zA-Z]*$', 'i');
const nameRegExp = new RegExp('^[s a-zA-Z0-9-"\',.()&_;:?!/*]*$', 'i');
const cupidRegExp = new RegExp('^[0-9]{3}$');
const emailRegExp = new RegExp(
	"^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
);

const areEmailsValid = (emails?: string): boolean => {
	const emailArray = emails ? emails?.split(';').map((e) => e.trim()) : [];
	return emailArray.filter((e) => !emailRegExp.test(e)).length === 0;
};

// Validation scheme
const ProviderValidationSchema: Yup.SchemaOf<CommunicationProviderService.ProviderEditInput> = Yup.object().shape(
	{
		cupid: Yup.string()
			.notRequired()
			.when('cupid', {
				is: (value: string) => value?.length,
				then: (rule: Yup.StringSchema) =>
					rule.matches(cupidRegExp, 'A CUPID must be a 3-digit number. Prefixed with zero if needed. For example 031'),
			}),
		name: Yup.string()
			.required('A name is required')
			.matches(nameRegExp, 'A name should only contain letters, numbers and basic punctuation')
			.min(2, 'A name should be at least 2 characters')
			.max(100, 'A name should be no more than 100 characters'),
		franchiseArea: Yup.string()
			.notRequired()
			.when('franchiseArea', {
				is: (value: string) => value?.length,
				then: (rule: Yup.StringSchema) =>
					rule
						.matches(nameRegExp, 'A franchise area should only contain letters, numbers and basic punctuation')
						.max(100, 'A franchise area should be no more than 100 characters'),
			}),
		gamma: Yup.boolean().required().oneOf([true, false]),
		hostingPartner: Yup.boolean().required().oneOf([true, false]),
		portingPartner: Yup.boolean().required().oneOf([true, false]),
		singleLineEmail: Yup.string().when('portingPartner', {
			is: true,
			then: Yup.string()
				.required("Please set email address(es) separated with ';'")
				.test('singleLineEmail', 'Invalid email formatting', (val) => areEmailsValid(val)),
		}),
		multiLineEmail: Yup.string().when('portingPartner', {
			is: true,
			then: Yup.string()
				.required("Please set email address(es) separated with ';'")
				.test('multiLineEmail', 'Invalid email formatting', (val) => areEmailsValid(val)),
		}),
		shortName: Yup.string()
			.required('A short name is required')
			.matches(shortNameRegExp, 'A short name should only contain letters')
			.min(2, 'A short name should be at least 2 characters in length')
			.max(5, 'A short name should be no more than 5 characters in length'),
		parentCupid: Yup.string().notRequired(),
		changeReason: Yup.string()
			.notRequired()
			.when('changeReason', {
				is: (value: string) => value?.length,
				then: (rule: Yup.StringSchema) => rule.max(255, 'A change reason should be no more than 255 characters'),
			}),
	},
	[
		['cupid', 'cupid'],
		['franchiseArea', 'franchiseArea'],
		['changeReason', 'changeReason'],
	],
);

const ProviderEdit = ({ provider, setEditMode, setSearchInput, onClose, isProviderAddForm = false }: ProviderEditProps) => {
	const [editProvider] = useState(provider);
	const [hasChildren, setHasChildren] = useState(false);
	const msal = useMsal();
	const ofcomApiScopes = apiConfig.ofcomApi.scopes;

	const getFailMessage = (resError: string): string => {
		if (resError && resError !== '') {
			console.log(resError);
			return resError;
		}
		return `Unable to ${
			isProviderAddForm ? 'add' : 'update'
		} communication provider. If the problem persists feel free to get in contact your system administrator`;
	};

	const submit = async (values: CommunicationProviderService.ProviderEditInput) => {
		const token = await getAccessToken(ofcomApiScopes, msal);

		const response = editProvider
			? await CommunicationProviderService.updateProvider(token, editProvider.cupid, values)
			: await CommunicationProviderService.addProvider(token, values);

		if (response.ok || (response.status > 200 && response.status < 299)) {
			setEditMode(false);
			setSearchInput(editProvider ? editProvider.cupid : values.cupid!);
			onClose();

			toast.custom((t) => (
				<GammaFeedbackToast
					hotToast={t}
					message={
						isProviderAddForm
							? `Successfully added communication provider (${values.cupid})`
							: `Successfully updated the details for communication provider (${editProvider!.cupid})`
					}
					type='success'
					includeIcon={true}
					data-testid='success-feedback'
					title='Communication Provider Update Successful'
				/>
			));
		} else {
			const errorMessage = await response.text();
			toast.custom((t) => (
				<GammaFeedbackToast
					hotToast={t}
					message={getFailMessage(errorMessage)}
					type='error'
					includeIcon={true}
					data-testid='error-feedback'
					title='Communication Provider Update Failed'
				/>
			));
		}
	};

	useEffect(() => {
		const initialise = async () => {
			if (editProvider) {
				const token = await getAccessToken(ofcomApiScopes, msal);
				const children = await CommunicationProviderService.getChildProviders(token, editProvider.cupid);
				setHasChildren(children && children.length > 0);
			} else {
				setHasChildren(false);
			}
		};

		initialise();
	}, []);

	const providerFormReset = (
		resetForm: (nextState?: Partial<FormikState<CommunicationProviderService.ProviderEditInput>>) => void,
	): void => {
		resetForm();
		setEditMode(false);
	};

	const providerFlagToggle = (
		setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
		gammaValue: boolean,
		hostingValue: boolean,
		portingValue: boolean,
	): void => {
		setFieldValue('gamma', gammaValue);
		setFieldValue('hostingPartner', hostingValue);
		setFieldValue('portingPartner', portingValue);
	};

	const getParentProviderOptions = async (token: string, selectOptions: Option[]): Promise<Option[]> => {
		const providers = await CommunicationProviderService.getParentProviders(token);
		const sameCupidOmittedProviders = providers
			? providers.filter(function (e) {
					return editProvider ? e.cupid != editProvider.cupid : true;
			  })
			: [];
		sameCupidOmittedProviders.forEach((item) => {
			selectOptions.push({
				label: `(${item.cupid}) ${item.name}${item.franchiseArea ? ` ${item.franchiseArea}` : ''}`,
				value: item.cupid,
			});
		});
		return selectOptions;
	};

	const onSelectChange = (newValue: Option, formikFieldProps: FieldProps<any>) => {
		formikFieldProps.form.setFieldTouched(formikFieldProps.field.name, true);
		formikFieldProps.form.setFieldValue(formikFieldProps.field.name, newValue.value);
	};

	return (
		<Formik
			initialValues={{
				cupid: '',
				name: provider ? provider.name : '',
				franchiseArea: provider && provider.franchiseArea ? provider.franchiseArea : '',
				gamma: provider ? provider.flags.gamma : false,
				hostingPartner: provider ? provider.flags.hostingPartner : false,
				portingPartner: provider ? provider.flags.portingPartner : false,
				shortName: provider ? provider.shortName : '',
				singleLineEmail: provider && provider.singleLineEmail ? provider.singleLineEmail : '',
				multiLineEmail: provider && provider.multiLineEmail ? provider.multiLineEmail : '',
				parentCupid: provider && provider.parent ? provider.parent.cupid : '',
				changeReason: '',
			}}
			onSubmit={(values) => submit(values)}
			validationSchema={ProviderValidationSchema}
		>
			{(props: FormikProps<any>) => (
				<Form className='flex h-full flex-col overflow-y-scroll shadow-xl'>
					{/* Divider container */}
					<div className='space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0' data-testid='provider-edit'>
						{/* CUPID */}
						{isProviderAddForm ? (
							<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
								<EditLabel label='CUPID' id='cupid' mandatory={true} />
								<Field name='cupid'>
									{(props: FieldProps<any>) => (
										<EditTextField
											formikProps={props}
											id='cupid'
											placeholder='3-digit CUPID of communication provider'
											errorKey='cupid-error'
										/>
									)}
								</Field>
							</div>
						) : null}

						{/* Communication Provider Name */}
						<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
							<EditLabel label='Name' id='provider-name' mandatory={true} />
							<Field name='name'>
								{(props: FieldProps<any>) => (
									<EditTextField
										formikProps={props}
										id='provider-name'
										placeholder='Specify Communication Provider name'
										errorKey='name-error'
									/>
								)}
							</Field>
						</div>
						{/* Communication Provider Franchise Area */}
						<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
							<EditLabel label='Franchise Area' id='provider-franchise-area' />
							<Field name='franchiseArea'>
								{(props: FieldProps<any>) => (
									<EditTextField
										formikProps={props}
										id='provider-franchise-area'
										placeholder='Specify a franchise area to uniquely identify the communication provider'
										errorKey='franchise-area-error'
									/>
								)}
							</Field>
						</div>
						{/* Gamma Flag */}
						<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
							<EditLabel label='Gamma' id='provider-gamma' />
							<Field name='gamma'>
								{(props: FieldProps<any>) => (
									<DependentToogleSwitch
										formikProps={props}
										disabledCondition={props.form.values.hostingPartner || props.form.values.portingPartner}
										screenReaderText='Mark Communication Provider as a Gamma provider'
										id='provider-gamma'
										onChange={(checked: boolean) => providerFlagToggle(props.form.setFieldValue, checked, false, false)}
									/>
								)}
							</Field>
						</div>
						{/* Hosting Flag */}
						<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
							<EditLabel label='Hosting Partner' id='provider-hosting' />
							<Field name='hostingPartner'>
								{(props: FieldProps<any>) => (
									<DependentToogleSwitch
										formikProps={props}
										disabledCondition={props.form.values.gamma}
										screenReaderText='Mark Communication Provider as a hosting provider'
										id='provider-hosting'
										onChange={(checked: boolean) =>
											providerFlagToggle(props.form.setFieldValue, false, checked, props.form.values.portingPartner)
										}
									/>
								)}
							</Field>
						</div>
						{/* Porting Flag */}
						<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
							<EditLabel label='Porting Partner' id='provider-porting' />
							<Field name='portingPartner'>
								{(props: FieldProps<any>) => (
									<DependentToogleSwitch
										formikProps={props}
										disabledCondition={props.form.values.gamma}
										screenReaderText='Mark Communication Provider as a porting provider'
										id='provider-porting'
										onChange={(checked: boolean) => {
											if (!checked) {
												props.form.setFieldValue('singleLineEmail', '', false);
												props.form.setFieldValue('multiLineEmail', '', false);
											}
											providerFlagToggle(props.form.setFieldValue, false, props.form.values.hostingPartner, checked);
										}}
									/>
								)}
							</Field>
						</div>
						{props.values.portingPartner ? (
							<>
								<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
									<EditLabel label='Single Line Email(s)' id='single-line-email' mandatory={props.values.portingPartner} />
									<Field name='singleLineEmail'>
										{(props: FieldProps<any>) => (
											<EditTextField
												formikProps={props}
												id='single-line-email'
												data-testid='single-line-email'
												placeholder='Please specify email address(es) the porting partner'
												errorKey='single-line-email-error'
											/>
										)}
									</Field>
								</div>
								<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
									<EditLabel label='Multi Line Email(s)' id='multi-line-email' mandatory={props.values.portingPartner} />
									<Field name='multiLineEmail'>
										{(props: FieldProps<any>) => (
											<EditTextField
												formikProps={props}
												id='multi-line-email'
												data-testid='multi-line-email'
												placeholder='Please specify email address(es) the porting partner'
												errorKey='multi-line-email-error'
											/>
										)}
									</Field>
								</div>
							</>
						) : null}
						{/* Communication Provider Short Name */}
						<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
							<EditLabel label='Short Name' id='provider-short-name' mandatory={true} />
							<Field name='shortName'>
								{(props: FieldProps<any>) => (
									<EditTextField
										formikProps={props}
										id='provider-short-name'
										placeholder='Specify a short name for the communication provider'
										errorKey='short-name-error'
									/>
								)}
							</Field>
						</div>
						{/* Communication Provider Parent */}
						<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
							<EditLabel label='Parent Provider' id='provider-parent' />
							<Field name='parentCupid'>
								{(props: FieldProps<any>) => (
									<ProviderSelect
										formikProps={props}
										noSelectionLabel={'No Parent'}
										optionFunction={getParentProviderOptions}
										onChangeFunction={onSelectChange}
										id='provider-parent'
										disabled={hasChildren}
										errorKey='provider-parent-error'
									/>
								)}
							</Field>
						</div>
						{/* Reason for Change */}
						<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
							<EditLabel label='Reason For Change' id='provider-change-reason' />
							<Field name='changeReason'>
								{(props: FieldProps<any>) => (
									<TextArea
										formikProps={props}
										id='provider-change-reason'
										errorKey='reason-error'
										placeholder='Enter a reason for changing this communication provider'
									/>
								)}
							</Field>
						</div>
					</div>

					{/* Action buttons */}
					<div className='flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6'>
						<div className='flex justify-end space-x-3'>
							<button
								data-testid='cancel-button'
								type='button'
								onClick={() => providerFormReset(props.resetForm)}
								className='rounded-md border border-gray-300 bg-gray-50 dark:bg-gray-800 py-2 px-4 text-sm font-medium text-gray-800 dark:text-white shadow-sm hover:bg-gamma-digital hover:text-white dark:hover:bg-gamma-digital focus:outline-none focus:ring-2 focus:ring-gamma-digital focus:ring-offset-2 dark:focus:text-white'
							>
								Cancel
							</button>
							<button
								data-testid='submit-button'
								type='submit'
								disabled={props.isSubmitting}
								onClick={() => props.submitForm()}
								className='ml-4 inline-flex justify-center rounded-md border border-transparent bg-gamma-digital py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gamma-hover focus:outline-none focus:ring-2 focus:ring-gamma-hover focus:ring-offset-2'
							>
								{props.isSubmitting ? <Spinner color='button' /> : null}
								&nbsp;Save
							</button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default ProviderEdit;
