/**
 * Check if the provided date is within British Summer Time
 * @param {Date | undefined} date: the date to check
 * @return {boolean}: true if is British Summer Time (BST) otherwise false
 */
export function isBST(date: Date | undefined): boolean {
	const d = date || new Date();
	const starts = lastSunday(2, d.getFullYear());
	starts.setHours(1);
	const ends = lastSunday(9, d.getFullYear());
	ends.setHours(1);
	return d.getTime() >= starts.getTime() && d.getTime() < ends.getTime();
}

/**
 * Function to build a {@link Date} that is the last Sunday of the current month and year
 * @param {number} month
 * @param {number} year
 * @return {Date}: The last Sunday for a given month year
 */
function lastSunday(month: number, year: number): Date {
	const d = new Date();
	const lastDayOfMonth = new Date(Date.UTC(year || d.getFullYear(), month + 1, 0));
	const day = lastDayOfMonth.getDay();
	return new Date(Date.UTC(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate() - day));
}
