import React from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import AuthenticatedLanding from './authenticated-landing';
import UnauthenticatedLanding from './unauthenticated-landing';

const Home: React.FC = () => {
	const isAuthenticated = useIsAuthenticated();
	console.log(process.env.REACT_APP_STAGE);
	return <>{isAuthenticated ? <AuthenticatedLanding /> : <UnauthenticatedLanding />}</>;
};
export default Home;
