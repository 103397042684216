import { Client, ResponseType, PageCollection } from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { endOfDay, startOfDay } from 'date-fns';
import { User, Event } from 'microsoft-graph';

type authProviderInput = AuthCodeMSALBrowserAuthenticationProvider | undefined;

let graphClient: Client | undefined = undefined;

/**
 * Check that a graphClient has been initialised prior to call any of the Microsoft Graph APIs
 * @param {AuthCodeMSALBrowserAuthenticationProvider} authProvider:
 * @return {Client} A client that can interact the Microsoft Graph API
 */
function ensureClient(authProvider: authProviderInput) {
	if (!authProvider) {
		throw Error('Cannot call Graph API Methods without an authProvider');
	}

	if (!graphClient) {
		graphClient = Client.initWithMiddleware({
			authProvider: authProvider,
		});
	}

	return graphClient;
}

/**
 * Get the logged in user information
 * @param {authProviderInput} authProvider
 * @return {Promise<User>}: A promise containing the account information obtained from Microsoft Graph
 */
export async function getUser(authProvider: authProviderInput): Promise<User> {
	ensureClient(authProvider);

	// Return the /me API endpoint result as a User object
	const user: User = await graphClient!
		.api('/me')
		.select('id,displayName,mail,mailboxSettings,userPrincipalName,jobTitle,officeLocation,businessPhones')
		.get();

	return user;
}

/**
 * Get the profile picture of the logged in user as a URL
 * @param {AuthCodeMSALBrowserAuthenticationProvider} authProvider
 * @return {Promise<string | undefined>}: the URL to the profile picture or undefined
 */
export async function getProfilePicture(authProvider: authProviderInput): Promise<string | undefined> {
	ensureClient(authProvider);

	const url = window.URL || window.webkitURL;

	try {
		const response = await graphClient!.api('/me/photo/$value').responseType(ResponseType.RAW).get();

		if (!response.ok) {
			console.warn(response.statusText);
			return undefined;
		}

		const blob = await response.blob();
		const imageUrl = url.createObjectURL(blob);
		return imageUrl;
	} catch (error) {
		console.error(error);
		return undefined;
	}
}

/**
 * Get the direct reports of the logged in user. Used in the profile page to generate the organisational chart
 * @param {authProviderInput} authProvider
 * @return {Promise<Array<User>>}: An array of {@link User}s that report to the logged in user
 */
export async function getDirectReports(authProvider: authProviderInput): Promise<Array<User>> {
	ensureClient(authProvider);

	const response: PageCollection = await graphClient!.api('/me/directReports').select('id,displayName,jobTitle').get();

	return response ? response.value : ([] as User[]);
}

/**
 * Get the manager chain for the logged in user. Used in the profile page to generate the organisational chart
 * @param {authProviderInput} authProvider
 * @return {Promise<string | undefined>}
 */
export async function getManagerChain(authProvider: authProviderInput): Promise<User | undefined> {
	ensureClient(authProvider);

	try {
		const user = graphClient!
			.api('/me')
			.header('consistencyLevel', 'eventual')
			.expand('manager($levels=max;$select=id,displayName,jobTitle)')
			.count(true)
			.get();
		return user;
	} catch (error) {
		console.warn(error);
		return undefined;
	}
}

/**
 * Get the calendar events for a given user on the specified date
 * @param {authProviderInput} authProvider
 * @param {Date} inputDay: The date to get events for
 * @return {Promise<Array<Event>>}: An array of {@link Event}s
 */
export async function getUserDayEvents(authProvider: authProviderInput, inputDay: Date): Promise<Array<Event>> {
	ensureClient(authProvider);

	const startDateTime = startOfDay(inputDay).toISOString();
	const endDateTime = endOfDay(inputDay).toISOString();

	try {
		const response: PageCollection = await graphClient!
			.api('/me/calendarview')
			.query({ startDateTime: startDateTime, endDateTime: endDateTime })
			.select('subject,organizer,start,end')
			.orderby('start/dateTime')
			.get();
		return response.value;
	} catch (error) {
		console.warn(error);
		return [] as Event[];
	}
}
