import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

export interface ImageModalInputs {
	imageSrc: string;
	onClose: () => void;
	caption?: string;
}

const ImageModal = ({ imageSrc, onClose, caption }: ImageModalInputs) => {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		setOpen(imageSrc !== '');
	}, [imageSrc]);

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as='div' className='relative z-10' onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
					afterLeave={() => onClose()}
				>
					<div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
				</Transition.Child>

				<div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
					<div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0' data-testid='image-modal'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'
						>
							<Dialog.Panel className='transform overflow-hidden px-1 pb-1 pt-1 shadow-xl transition-all max-w-6xl'>
								<div className='absolute right-0 top-0 block pr-3 pt-3 sm:block'>
									<button
										type='button'
										className='rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
										onClick={() => setOpen(false)}
										id='btn-close'
									>
										<span className='sr-only'>Close</span>
										<XMarkIcon className='h-6 w-6' aria-hidden='true' />
									</button>
								</div>
								<div className='sm:flex sm:items-start'>
									<div>
										<img className='rounded-lg' src={imageSrc} alt='screenshot' />
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default ImageModal;
