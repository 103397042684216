import React, { useState } from 'react';
import { PortAgreementItem, PortAgreementAction } from 'types';
import PortAgreementFormContainer from './port-agreement-form-container';
import PortAgreementView from './port-agreement-view';

interface PortAgreementViewContainerProps {
	agreement: PortAgreementItem;
	openDeleteDialogFunction: (agreement: PortAgreementItem) => void;
	onClose: () => void;
	action?: PortAgreementAction;
}

const PortAgreementViewContainer: React.FC<PortAgreementViewContainerProps> = ({
	agreement,
	openDeleteDialogFunction,
	onClose,
	action = 'EDIT',
}: PortAgreementViewContainerProps) => {
	const [editMode, setEditMode] = useState(false);
	return (
		<>
			{editMode ? (
				<PortAgreementFormContainer agreement={agreement} setEditMode={setEditMode} onClose={onClose} action={action} />
			) : (
				<PortAgreementView agreement={agreement} setEditMode={setEditMode} openDeleteDialogFunction={openDeleteDialogFunction} />
			)}
		</>
	);
};

export default PortAgreementViewContainer;
