import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Spinner } from '../spinner';
import { toast } from 'react-hot-toast';
import { GammaFeedbackToast } from '../gamma-toast';

export interface SubmitDialogProps {
	title: string;
	child: React.ReactNode;
	submitFunction: (reason: string) => Promise<Response>;
	cancelFunction: () => void;
	successMessage: string;
	defaultFailMessage: string;
	showChangeReason?: boolean;
}

const SubmitDialog = ({
	title,
	child,
	submitFunction,
	cancelFunction,
	successMessage,
	defaultFailMessage,
	showChangeReason,
}: SubmitDialogProps) => {
	const [dialogOpen, setDialogOpen] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const [changeReason, setChangeReason] = useState('');
	useEffect(() => {
		setChangeReason('');
		setDialogOpen(child !== null);
	}, [child]);

	const getFailMessage = (resError: string): string => {
		if (resError && resError !== '') {
			console.log(resError);
			return resError;
		}
		return defaultFailMessage;
	};

	const submit = async () => {
		setShowSpinner(true);
		const response = await submitFunction(changeReason);
		setShowSpinner(false);
		setDialogOpen(false);
		if (response.ok || (response.status > 200 && response.status < 299)) {
			toast.custom((t) => (
				<GammaFeedbackToast
					hotToast={t}
					message={successMessage}
					type='success'
					includeIcon={true}
					data-testid='success-feedback'
					title='Change Successful'
				/>
			));
		} else {
			const errorMessage = await response.text();
			toast.custom((t) => (
				<GammaFeedbackToast
					hotToast={t}
					message={getFailMessage(errorMessage)}
					type='error'
					includeIcon={true}
					data-testid='error-feedback'
					title='Change Failed'
				/>
			));
		}
	};

	return (
		<Transition appear show={dialogOpen} as={Fragment}>
			<Dialog as='div' className='relative z-50' onClose={() => setDialogOpen(false)}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
					afterLeave={() => cancelFunction()}
				>
					<div className='fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity' />
				</Transition.Child>
				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center' data-testid='submit-dialog'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'
						>
							<Dialog.Panel className='w-full max-w-md transform overflow-hidden relative z-0 rounded-2xl bg-gray-50 dark:bg-gray-800 p-6 align-middle shadow-xl transition-all border-solid border-4'>
								<div
									className={`absolute inset-0 flex justify-center items-center z-10 bg-gray-400 opacity-40 ${!showSpinner && 'hidden'}`}
								>
									<Spinner size={'xl'} data-testid='dialog-spinner' />
								</div>
								<Dialog.Title className='text-xl font-medium text-black dark:text-white pb-2 '>{title}</Dialog.Title>
								<Dialog.Description>
									{child}
									{showChangeReason ? (
										<div className='mt-3 text-left text-sm'>
											* Reason for Change:
											<textarea
												data-testid='change-reason'
												placeholder='Please enter a reason for changing this range.'
												className='block w-full rounded-md sm:text-sm text-gray-800 dark:text-white dark:bg-gray-800 resize-none'
												maxLength={255}
												rows={4}
												onChange={(e) => setChangeReason(e.target.value)}
											/>
										</div>
									) : null}
								</Dialog.Description>
								<div className='flex items-start justify-between'>
									<button
										type='button'
										className='mt-4 flex h-8 w-17 px-4 py-2 items-center justify-center border border-transparent rounded-md text-base font-medium shadow-sm bg-gray-700 text-white hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-gray-700'
										data-testid='cancel-button'
										onClick={() => setDialogOpen(false)}
									>
										Cancel
									</button>
									<button
										type='button'
										className='mt-4 flex h-8 w-17 px-4 py-2 items-center justify-center border border-transparent rounded-md text-base font-medium shadow-sm enabled:bg-gamma-digital text-white enabled:hover:bg-gamma-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-gamma-digital disabled:bg-gray-400'
										data-testid='submit-button'
										onClick={() => submit()}
										disabled={showChangeReason && changeReason === ''}
									>
										Submit
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};
export default SubmitDialog;
