import { RefObject, useEffect, useRef, useLayoutEffect } from 'react';

/**
 * Hook to enable the use of an [EventListener]{@link https://www.w3schools.com/js/js_htmldom_eventlistener.asp} against a window
 * @param eventName the name of the event 
 * @param handler the function to fire
 * 
 * @example
 * //window based event
 * const onScroll = (event: Event) => {
    console.log('window scrolled!', event)
  }

	useEventListener('scroll', onScroll)
 */
function useEventListener<K extends keyof WindowEventMap>(eventName: K, handler: (event: WindowEventMap[K]) => void): void;

/**
 * Hook to enable the use of an [EventListener]{@link https://www.w3schools.com/js/js_htmldom_eventlistener.asp} on a HTML element
 * @param eventName the name of the event
 * @param handler the function to fire
 * @param element the HTMLElement the listener is to be applied to
 * 
 * @example
 * //element based event
 * const buttonRef = useRef<HTMLButtonElement>(null)
 * 
 * const onClick = (event: Event) => {
    console.log('button clicked!', event)
  }

	useEventListener('click', onClick, buttonRef)
 */
function useEventListener<K extends keyof HTMLElementEventMap, T extends HTMLElement = HTMLDivElement>(
	eventName: K,
	handler: (event: HTMLElementEventMap[K]) => void,
	element: RefObject<T>,
): void;

/**
 * Hook to enable the use of an [EventListener]{@link https://www.w3schools.com/js/js_htmldom_eventlistener.asp} on a HTML element
 * @param {string} eventName the name of the event
 * @param {Event} handler the function to fire
 * @param {RefObject<T>}element the HTMLElement the listener is to be applied to
 * @template T
 * @example
 * //element based event
 * const buttonRef = useRef<HTMLButtonElement>(null)
 * 
 * const onClick = (event: Event) => {
    console.log('button clicked!', event)
  }

	useEventListener('click', onClick, buttonRef)
 */
function useEventListener<KW extends keyof WindowEventMap, KH extends keyof HTMLElementEventMap, T extends HTMLElement | void = void>(
	eventName: KW | KH,
	handler: (event: WindowEventMap[KW] | HTMLElementEventMap[KH] | Event) => void,
	element?: RefObject<T>,
) {
	// Create a ref that stores handler
	const savedHandler = useRef(handler);

	useLayoutEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(() => {
		// Define the listening target
		const targetElement: T | Window | null = element ? element.current : window;
		if (!(targetElement && targetElement.addEventListener)) {
			return;
		}

		// Create event listener that calls handler function stored in ref
		const eventListener: typeof handler = (event) => savedHandler.current(event);

		targetElement.addEventListener(eventName, eventListener);

		// Remove event listener on cleanup
		return () => {
			targetElement.removeEventListener(eventName, eventListener);
		};
	}, [eventName, element]);
}

export default useEventListener;
