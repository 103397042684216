import { SubmitDialogProps } from 'components';

/**
 * Utility function to get a set of default Submit Dialog props
 * @return {SubmitDialogProps}: The default dialog props
 */
export function getDefaultDialogProps(): SubmitDialogProps {
	return {
		title: '',
		child: null,
		submitFunction: async (s) => new Response(null, { status: 201 }),
		cancelFunction: () => {},
		showChangeReason: false,
		successMessage: '',
		defaultFailMessage: '',
	};
}
