import type {
	HistoryItem,
	NumberRangeHolderRequest,
	OfcomRangeRequest,
	OfcomRangeResponse,
	RangeAdvancedSearchInput,
	RangeNumberLength,
	RangeStatus,
} from 'types';
import { apiConfig } from 'auth-config';
import { createRequestHeader } from 'utils';
import { NumberHostingProviderRequest } from 'types';

const baseUrl = apiConfig.ofcomApi.baseUrl;

export interface RangeEditInput {
	numberBlock?: string;
	rangeHolder?: string;
	hostingProvider?: string;
	status: RangeStatus;
	allocationDate?: string;
	numberLength: RangeNumberLength;
	notes?: string;
	changeReason?: string;
}

/**
 * Simple number range search by either number range starting digits or communication provider
 * name.
 * @param {string} token: Access token for API requests
 * @param {string} searchTerm: users search term to filter number ranges against
 * @param {number} page: the page of results to return
 * @param {number} pageSize: the number of results in the page
 * @return {Promise<OfcomRangeResponse[]>}: the selected page of results
 */
export async function simpleSearchRanges(token: string, searchTerm: string, page: number, pageSize: number): Promise<OfcomRangeResponse[]> {
	const searchUrl: string = searchTerm ? `${baseUrl}/range?q=${searchTerm}&` : `${baseUrl}/range?`;
	const endpoint: string = searchUrl + `page=${page}&perPage=${pageSize}`;
	const response = await fetch(endpoint, createRequestHeader(token, 'GET'));
	const body = await response.json();
	if (response.ok || (response.status > 200 && response.status < 299)) {
		return body;
	}
	console.error(body);
	return [];
}

/**
 * Advanced number range search using a combination of number range, status and communication provider
 * @param {string} token: Access token for API requests
 * @param {RangeAdvancedSearchInput} searchRequest: An TS object that wraps up the possible query parameters for the search
 * @param {number} page: the page of results to return
 * @param {number} pageSize the number of results in the page
 */
export async function advancedSearchRanges(
	token: string,
	searchRequest: RangeAdvancedSearchInput,
	page: number,
	pageSize: number,
): Promise<OfcomRangeResponse[]> {
	// construct URL based on input
	let searchUrl: string = `${baseUrl}/range/search?`;
	let appendAnd: boolean = false;

	if (searchRequest.startDigits) {
		searchUrl = searchUrl + `startDigits=${searchRequest.startDigits}`;
		appendAnd = true;
	}

	if (searchRequest.status) {
		searchUrl = searchUrl + `${appendAnd ? '&' : ''}` + `status=${searchRequest.status}`;
		appendAnd = true;
	}

	if (searchRequest.cupid) {
		searchUrl = searchUrl + `${appendAnd ? '&' : ''}` + `cupid=${searchRequest.cupid}`;
		appendAnd = true;
	}

	if (searchRequest.ofcomRangeHolderId) {
		searchUrl = searchUrl + `${appendAnd ? '&' : ''}` + `ofcomRangeHolderId=${searchRequest.ofcomRangeHolderId}`;
		appendAnd = true;
	}

	const endpoint: string = searchUrl + `${appendAnd ? '&' : ''}` + `page=${page}&perPage=${pageSize}`;
	const response = await fetch(endpoint, createRequestHeader(token, 'GET'));
	const body = await response.json();
	if (response.ok || (response.status > 200 && response.status < 299)) {
		return body;
	}
	console.error(body);
	return [];
}

/**
 * Get the range with the given id.
 * @param {string} token: Access token for API requests
 * @param {number} rangeId: Id of the range to find
 * @return {Promise<OfcomRangeResponse>}: Range with given id.
 */
export async function getRange(token: string, rangeId: number): Promise<OfcomRangeResponse> {
	const url = `${baseUrl}/range/${rangeId}`;
	return await (await fetch(url, createRequestHeader(token, 'GET'))).json();
}

/**
 * Get the history for a range with the given id.
 * @param {string} token: Access token for API requests
 * @param {string} rangeId: Id of the range to find
 * @param {number} page: the page of results to return
 * @param {number} perPage: the number of results in the page
 * @return {Promise<HistoryItem[]>}: History of changes for the range.
 */
export async function getRangeHistory(token: string, rangeId: string, page?: number, perPage?: number): Promise<HistoryItem[]> {
	const historyUrl: string = `${baseUrl}/range/${rangeId}/history`;
	const endpoint: string = page && perPage ? historyUrl + `?page=${page}&perPage=${perPage}` : historyUrl;
	return await (await fetch(endpoint, createRequestHeader(token, 'GET'))).json();
}

/**
 * Update the information associated for the range with the given rangeId.
 * @param {string} token
 * @param {number} rangeId
 * @param {RangeEditInput} updates
 */
export async function updateRange(token: string, rangeId: number, updates: RangeEditInput): Promise<Response> {
	const url = `${baseUrl}/range/${rangeId}`;
	let hostingProvider: NumberHostingProviderRequest | undefined;
	if (updates.hostingProvider) {
		const hp = updates.hostingProvider.startsWith('C-') ? updates.hostingProvider.replace('C-', '').trim() : updates.hostingProvider;
		hostingProvider = { cupid: hp };
	}
	let rangeHolder: NumberRangeHolderRequest | undefined;
	if (!updates.status.includes('ALLOCATED')) {
		rangeHolder = undefined;
	} else if (updates.rangeHolder?.startsWith('C-')) {
		rangeHolder = { type: 'CUPID', cupid: updates.rangeHolder.replace('C-', '').trim() };
	} else if (updates.rangeHolder?.startsWith('N-')) {
		rangeHolder = { type: 'NONCUPID', rangeHolderId: updates.rangeHolder.replace('N-', '').trim() };
	}

	const requestBody: OfcomRangeRequest = {
		allocationDate: updates.status.includes('ALLOCATED') ? updates.allocationDate : undefined,
		comment: updates.changeReason,
		hostingProvider: hostingProvider,
		notes: updates.notes,
		numberLength: updates.numberLength,
		rangeHolder: rangeHolder,
		status: updates.status,
	};

	return await fetch(url, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(requestBody),
	});
}

/**
 * Add a new ofcom range with the specified information.
 * @param {string} token
 * @param {RangeEditInput} creates
 */
export async function addRange(token: string, creates: RangeEditInput): Promise<Response> {
	const url = `${baseUrl}/range`;
	let hostingProvider: NumberHostingProviderRequest | undefined;
	if (creates.hostingProvider) {
		const hp = creates.hostingProvider.startsWith('C-') ? creates.hostingProvider.replace('C-', '').trim() : creates.hostingProvider;
		hostingProvider = { cupid: hp };
	}
	let rangeHolder: NumberRangeHolderRequest | undefined;
	if (!creates.status.includes('ALLOCATED')) {
		rangeHolder = undefined;
	} else if (creates.rangeHolder?.startsWith('C-')) {
		rangeHolder = { type: 'CUPID', cupid: creates.rangeHolder.replace('C-', '').trim() };
	} else if (creates.rangeHolder?.startsWith('N-')) {
		rangeHolder = { type: 'NONCUPID', rangeHolderId: creates.rangeHolder.replace('N-', '').trim() };
	}

	const requestBody: OfcomRangeRequest = {
		numberBlock: creates.numberBlock,
		allocationDate: creates.status.includes('ALLOCATED') ? creates.allocationDate : undefined,
		comment: creates.changeReason,
		hostingProvider: hostingProvider,
		notes: creates.notes,
		numberLength: creates.numberLength,
		rangeHolder: rangeHolder,
		status: creates.status,
	};

	return await fetch(url, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(requestBody),
	});
}

/**
 * Expand an existing range
 * @param {string} token
 * @param {number} rangeId
 * @param {string} changeReason
 */
export async function expandRange(token: string, rangeId: number, changeReason: string): Promise<Response> {
	const url = `${baseUrl}/range/${rangeId}/expand`;
	return await fetch(url, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify({ comment: changeReason }),
	});
}

/**
 * Contract an existing range.
 * @param {string} token
 * @param {number} rangeId
 * @param {string} changeReason
 */
export async function contractRange(token: string, rangeId: number, changeReason: string): Promise<Response> {
	const url = `${baseUrl}/range/${rangeId}/contract`;
	return await fetch(url, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify({ comment: changeReason }),
	});
}
