import React from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';

export type TimelineItem = {
	date: string;
	person: string;
	event: string;
};

interface TimelineProps {
	items: TimelineItem[];
	dateFormat?: string;
}

const colors: string[] = ['bg-blue-500', 'bg-red-500', 'bg-green-500', 'bg-gray-500', 'bg-pink-500', 'bg-orange-500', 'bg-yellow-500'];

const Timeline = ({ items, dateFormat = 'dd/MM/yyyy' }: TimelineProps) => {
	return (
		<div className='flow-root'>
			<ul className='-mb-8'>
				{items.map((timelineItem, timelineItemIdx) => (
					<TimelineEvent
						key={timelineItemIdx}
						event={timelineItem}
						dateFormat={dateFormat}
						index={timelineItemIdx}
						totalItems={items.length}
					/>
				))}
			</ul>
		</div>
	);
};

export default Timeline;

interface EventProps {
	event: TimelineItem;
	dateFormat: string;
	index: number;
	totalItems: number;
}

/**
 *
 * @param {EventProps} param0: props for generating an event in the timeline
 * @return { JSX.Element}: JSX.Element encompassing a timeline
 */
export const TimelineEvent = ({ event, dateFormat, index, totalItems }: EventProps) => {
	const colorIndex = random(0, colors.length - 1);
	const iconColor = colors[colorIndex];

	return (
		<li data-testid={`TimelineEvent-${index}`}>
			<div className='relative pb-8'>
				{index !== totalItems - 1 ? <span className='absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200' aria-hidden='true' /> : null}
				<div className='relative flex items-start space-x-3'>
					<div className='relative'>
						<span className={classNames('h-10 w-10 rounded-full flex items-center justify-center', iconColor)} />
					</div>
					<div className='min-w-0 flex-1'>
						<div>
							<div className='text-sm'>
								<span className='font-medium text-black dark:text-white'>{event.person}</span>
							</div>
							<p className='mt-0.5 text-sm text-gray-500'>
								<time dateTime={format(new Date(event.date), dateFormat)}>{format(new Date(event.date), dateFormat)}</time>
							</p>
						</div>
						<div className='mt-2 text-sm text-gray-700 dark:text-gray-300'>
							<p>{event.event}</p>
						</div>
					</div>
				</div>
			</div>
		</li>
	);
};

/**
 * Function to get a random number from a range of values
 * @param {number} min: the start of the range
 * @param {number} max: the end of the range
 * @return {number}: a number between the min and max values inclusive
 */
function random(min: number = 0, max: number = 5): number {
	return Math.floor(Math.random() * max) + min;
}
