import React, { Dispatch, SetStateAction } from 'react';
import {
	numberLengthMap,
	NumberRangeHolderResponse,
	OfcomRangeResponse,
	Option,
	RangeNumberLength,
	RangeStatus,
	rangeStatusMap,
} from 'types';
import * as Yup from 'yup';
import { Field, FieldProps, Form, Formik, FormikProps, FormikState } from 'formik';
import { OfcomRangeService, RangeHolderService } from 'services';
import { EditLabel, EditTextField, GammaFeedbackToast, ProviderSelect, Spinner, TextArea } from 'components';
import Datepicker from 'react-tailwindcss-datepicker';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import Select from 'react-select';
import { useMsal } from '@azure/msal-react';
import { apiConfig } from 'auth-config';
import { getAccessToken } from 'utils';
import { toast } from 'react-hot-toast';
import classNames from 'classnames';

interface RangeEditProps {
	range: OfcomRangeResponse | null;
	setEditMode: Dispatch<SetStateAction<boolean>>;
	onClose: () => void;
	isRangeAddForm?: boolean;
}

const numberBlockRegExp = new RegExp('^[1-9][0-9]{3,7}$');

const RangeForm = ({ range, setEditMode, onClose, isRangeAddForm = false }: RangeEditProps) => {
	const validationSchema: Yup.SchemaOf<OfcomRangeService.RangeEditInput> = Yup.object().shape(
		{
			numberBlock: isRangeAddForm
				? Yup.string()
						.required('A number block is required')
						.matches(numberBlockRegExp, 'A number should not start with a leading 0 and should only contain digits')
						.min(4, 'A number block should contain at least 4 digits')
						.max(8, 'A number block should contain at least 8 digits')
				: Yup.string().notRequired(),
			rangeHolder: Yup.string()
				.notRequired()
				.when('status', { is: 'ALLOCATED', then: Yup.string().required('Please set a Range Holder.') })
				.when('status', { is: 'ALLOCATED_CLOSE_RANGE', then: Yup.string().required('Please set a Range Holder.') }),
			hostingProvider: Yup.string().notRequired(),
			status: Yup.mixed<RangeStatus>().required(),
			allocationDate: Yup.string()
				.when('status', { is: 'ALLOCATED', then: Yup.string().required('Please set an allocation date.') })
				.when('status', { is: 'ALLOCATED_CLOSE_RANGE', then: Yup.string().required('Please set an allocation date.') }),
			numberLength: Yup.mixed<RangeNumberLength>().required(),
			notes: Yup.string().notRequired(),
			changeReason: Yup.string()
				.notRequired()
				.when('changeReason', {
					is: (value: string) => value?.length,
					then: (rule: Yup.StringSchema) => rule.max(255, 'A change reason should be no more than 255 characters'),
				}),
		},
		[['changeReason', 'changeReason']],
	);

	const rangeStatusOptions = [] as Option[];
	const numberLengthOptions = [] as Option[];
	const msal = useMsal();
	const ofcomApiScopes = apiConfig.ofcomApi.scopes;
	rangeStatusMap.forEach((value, key) => rangeStatusOptions.push({ label: key, value: value }));
	numberLengthMap.forEach((value, key) => numberLengthOptions.push({ label: key, value: value }));

	const rangeFormReset = (resetForm: (nextState?: Partial<FormikState<OfcomRangeService.RangeEditInput>>) => void): void => {
		resetForm();
		setEditMode(false);
	};

	const getRangeHolderOptions = async (token: string, selectOptions: Option[]): Promise<Option[]> => {
		return getAllRangeHolders(token, selectOptions, false);
	};

	// Hosting providers can only be top level commprovs with cupid, hence we only want cupid only range holders.
	const getHostingProviderOptions = async (token: string, selectOptions: Option[]): Promise<Option[]> => {
		return getAllRangeHolders(token, selectOptions, true);
	};

	const getAllRangeHolders = async (token: string, selectOptions: Option[], cupidOnly: boolean): Promise<Option[]> => {
		let rangeHolders = await RangeHolderService.getRangeHolderList(token);
		rangeHolders = cupidOnly ? rangeHolders.filter((v) => v.type === 'CUPID') : rangeHolders;
		rangeHolders.forEach((item) => {
			selectOptions.push({
				label: `${item.type === 'CUPID' ? `(${item.cupid}) ${item.name}` : `${item.name}`}`,
				value: `${item.type === 'CUPID' ? `C-${item.cupid}` : `N-${item.rangeHolderId!}`}`,
			});
		});
		return selectOptions;
	};

	const getRangeHolderValue = (rangeHolder?: NumberRangeHolderResponse): string => {
		if (rangeHolder) {
			return rangeHolder.type === 'CUPID' ? `C-${rangeHolder.cupid}` : `N-${rangeHolder.rangeHolderId}`;
		}
		return '';
	};

	const getSuccessMessage = (values: OfcomRangeService.RangeEditInput): string => {
		return isRangeAddForm
			? `Successfully added the number range (${values.numberBlock})`
			: `Successfully updated the details for number range (${range!.numberBlock})`;
	};

	const getFailMessage = (resError: string): string => {
		if (resError && resError !== '') {
			console.log(resError);
			return resError;
		}
		return `Unable to ${
			isRangeAddForm ? 'add' : 'update'
		} the number range. If the problem persists feel free to get in contact your system administrator`;
	};

	const onSelectChange = (newValue: Option, formikFieldProps: FieldProps<any>) => {
		const selectedOption = newValue as Option;
		formikFieldProps.form.setFieldTouched(formikFieldProps.field.name, true);
		formikFieldProps.form.setFieldValue(formikFieldProps.field.name, selectedOption.value);
	};

	const submit = async (values: OfcomRangeService.RangeEditInput) => {
		const token = await getAccessToken(ofcomApiScopes, msal);
		const response = isRangeAddForm
			? await OfcomRangeService.addRange(token, values)
			: await OfcomRangeService.updateRange(token, range!.id, values);
		if (response.ok || (response.status > 200 && response.status < 299)) {
			setEditMode(false);
			onClose();
			toast.custom((t) => (
				<GammaFeedbackToast
					hotToast={t}
					message={getSuccessMessage(values)}
					type='success'
					includeIcon={true}
					data-testid='success-feedback'
					title='Number Range Update Successful'
				/>
			));
		} else {
			const errorMessage = await response.text();
			toast.custom((t) => (
				<GammaFeedbackToast
					hotToast={t}
					message={getFailMessage(errorMessage)}
					type='error'
					includeIcon={true}
					data-testid='error-feedback'
					title='Number Range Update Failed'
				/>
			));
		}
	};

	return (
		<Formik
			initialValues={{
				numberBlock: '',
				rangeHolder: range ? getRangeHolderValue(range.rangeHolder) : '',
				hostingProvider: range && range.hostingProvider ? `C-${range.hostingProvider.cupid}` : '',
				status: rangeStatusMap.get(range ? range.status : 'Allocated')!,
				allocationDate: range ? range.allocationDate : '',
				numberLength: numberLengthMap.get(range ? range.numberLength.format : '4+6')!,
				notes: range ? range.notes : '',
				changeReason: '',
			}}
			onSubmit={(values) => submit(values)}
			validationSchema={validationSchema}
		>
			{(props: FormikProps<any>) => (
				<Form className='flex h-full flex-col overflow-y-scroll shadow-xl'>
					{/* Divider container */}
					<div className='space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0' data-testid='range-form'>
						{/* Number block */}
						{isRangeAddForm ? (
							<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
								<EditLabel label='Number Block' id='number-block' mandatory={true} />
								<div className='mt-1 w-full flex rounded-md shadow-sm'>
									<span className='inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-300 px-3 text-gray-600 sm:text-sm dark:bg-gray-700 dark:text-white'>
										+44
									</span>
									<Field name='numberBlock'>
										{(props: FieldProps<any>) => (
											<EditTextField
												formikProps={props}
												id='number-block'
												placeholder='Starting digits of range'
												errorKey='number-block-error'
											/>
										)}
									</Field>
								</div>
							</div>
						) : null}

						{/* Range holder list */}
						<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
							<Field name='rangeHolder'>
								{(props: FieldProps<any>) => (
									<>
										<EditLabel
											label='Range Holder'
											id='rangeHolderSelect'
											mandatory={props.form.values.status.toLowerCase().includes('allocated')}
										/>
										<ProviderSelect
											formikProps={props}
											noSelectionLabel={'No Range Holder'}
											optionFunction={getRangeHolderOptions}
											onChangeFunction={onSelectChange}
											initialValue={props.field.value.type === 'CUPID' ? props.field.value.cupid : props.field.value.rangeHolderId}
											id='rangeHolder'
											disabled={!props.form.values.status.toLowerCase().includes('allocated')}
											placeHolder='Please select a range holder'
											errorKey='rangeHolder-error'
										/>
									</>
								)}
							</Field>
						</div>
						{/* Hosting Provider list */}
						<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
							<EditLabel label='Hosting Provider' id='hostingProviderSelect' />
							<Field name='hostingProvider'>
								{(props: FieldProps<any>) => (
									<ProviderSelect
										formikProps={props}
										noSelectionLabel={'No Hosting Provider'}
										optionFunction={getHostingProviderOptions}
										onChangeFunction={onSelectChange}
										initialValue={props.field.value}
										id='hostingProvider'
										disabled={!props.form.values.status.toLowerCase().includes('allocated')}
										placeHolder='Please select a hosting provider'
										errorKey='hostingProvider-error'
									/>
								)}
							</Field>
						</div>
						{/* Range status */}
						<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
							<EditLabel label='Status' id='rangeStatus' mandatory={true} />
							<Field name='status'>
								{(props: FieldProps<any>) => (
									<div className='sm:col-span-2 relative rounded-md shadow-sm'>
										<Select
											aria-labelledby='rangeStatusSelect'
											aria-invalid={props.meta.touched && props.meta.error ? 'true' : 'false'}
											aria-describedby={props.meta.touched && props.meta.error ? 'rangeStatusSelect-error' : ''}
											options={rangeStatusOptions}
											id='rangeStatusSelect'
											inputId='rangeStatusSelect'
											isMulti={false}
											placeholder='Select a status for the range'
											name='Range Status'
											value={rangeStatusOptions.find((option) => option.value === props.field.value)}
											onChange={(newValue) => {
												const selectedOption = newValue as Option;
												props.form.setFieldTouched(props.field.name, true);
												props.form.setFieldValue(props.field.name, selectedOption.value);
												if (selectedOption.value !== 'ALLOCATED' && selectedOption.value !== 'ALLOCATED_CLOSE_RANGE') {
													props.form.setFieldValue('rangeHolder', '');
													props.form.setFieldValue('hostingProvider', '');
												}
											}}
											onBlur={() => {
												props.form.handleBlur({ target: { name: props.field.name } });
											}}
											className={
												props.meta.touched && props.meta.error ? 'searchable-select-error-container' : 'searchable-select-container'
											}
											classNamePrefix={props.meta.touched && props.meta.error ? 'searchable-select-error' : 'searchable-select'}
										/>
										{props.meta.touched && props.meta.error && (
											<div className='absolute inset-y-0 -top-7 right-0 pr-3 flex items-center pointer-events-none'>
												<ExclamationCircleIcon
													className='h-5 w-5 text-red-500'
													aria-hidden='true'
													data-testid='rangeStatusSelect-error-icon'
												/>
											</div>
										)}
										{props.meta.touched && props.meta.error && (
											<p className='mt-2 text-sm text-red-600' id='rangeStatusSelect-error' data-testid='rangeStatusSelect-error'>
												{props.meta.error}
											</p>
										)}
									</div>
								)}
							</Field>
						</div>
						{/* Allocation Date */}
						<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
							<Field name='allocationDate'>
								{(props: FieldProps<any>) => (
									<>
										<EditLabel
											label='Allocation Date'
											id='allocationDate'
											mandatory={props.form.values.status.toLowerCase().includes('allocated')}
										/>
										<div className='sm:col-span-2 relative rounded-md shadow-sm'>
											<Datepicker
												disabled={!props.form.values.status.toLowerCase().includes('allocated')}
												useRange={false}
												containerClassName={props.meta.touched && props.meta.error ? 'border-red-300' : 'border-gray-300'}
												inputClassName={classNames(
													'w-full rounded-md shadow-sm sm:text-sm text-gray-800 dark:text-white dark:bg-gray-800',
													props.meta.touched && props.meta.error
														? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:outline-red-500 focus:border-red-500'
														: 'border-gray-300 focus:border-gamma-digital focus:ring-gamma-digital',
												)}
												toggleClassName='rounded-r-lg bg-opacity-40 bg-gray-300 hover:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 hover:bg-opacity-60 transition-all duration-150 ease-in-out'
												primaryColor='purple'
												asSingle={true}
												displayFormat={'DD/MM/YYYY'}
												value={{ startDate: new Date(props.field.value), endDate: new Date(props.field.value) }}
												separator={'/'}
												onChange={(v) => {
													props.form.setFieldTouched(props.field.name, true);
													if (v) {
														let dateString = v.startDate ? new Date(v.startDate).toISOString() : '';
														dateString = dateString !== '' ? dateString.substring(0, dateString.indexOf('T')) : '';
														props.form.setFieldValue(props.field.name, dateString);
														props.form.setFieldError(props.field.name, undefined);
													} else if (props.form.values.status.toLowerCase().includes('allocated')) {
														props.form.setFieldError(props.field.name, 'Please set an allocation date.');
													} else {
														props.form.setFieldValue(props.field.name, undefined);
														props.form.setFieldError(props.field.name, undefined);
													}
												}}
											/>
											{props.meta.touched && props.meta.error && (
												<div className='absolute inset-y-0 -top-7 right-0 pr-3 flex items-center pointer-events-none'>
													<ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' data-testid='date-picker-error-icon' />
												</div>
											)}
											{props.meta.touched && props.meta.error && (
												<p className='mt-2 text-sm text-red-600' id='date-picker-error' data-testid='date-picker-error'>
													{props.meta.error}
												</p>
											)}
										</div>
									</>
								)}
							</Field>
						</div>
						<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
							<EditLabel label='Number Length' id='numberLengthLabel' mandatory={true} />
							<Field name='numberLength'>
								{(props: FieldProps<any>) => (
									<div className='sm:col-span-2 relative rounded-md shadow-sm'>
										<Select
											aria-labelledby='numberLengthSelect'
											aria-invalid={props.meta.touched && props.meta.error ? 'true' : 'false'}
											aria-describedby={props.meta.touched && props.meta.error ? 'numberLengthSelect-error' : ''}
											options={numberLengthOptions}
											id='numberLengthSelect'
											inputId='numberLengthSelect'
											isMulti={false}
											placeholder='Select a number length for the range'
											name='Number Length'
											value={numberLengthOptions.find((option) => option.value === props.field.value)}
											onChange={(newValue) => {
												const selectedOption = newValue as Option;
												props.form.setFieldTouched(props.field.name, true);
												props.form.setFieldValue(props.field.name, selectedOption.value);
											}}
											onBlur={() => {
												props.form.handleBlur({ target: { name: props.field.name } });
											}}
											className={
												props.meta.touched && props.meta.error ? 'searchable-select-error-container' : 'searchable-select-container'
											}
											classNamePrefix={props.meta.touched && props.meta.error ? 'searchable-select-error' : 'searchable-select'}
										/>
										{props.meta.touched && props.meta.error && (
											<div className='absolute inset-y-0 -top-7 right-0 pr-3 flex items-center pointer-events-none'>
												<ExclamationCircleIcon
													className='h-5 w-5 text-red-500'
													aria-hidden='true'
													data-testid='rangeStatusSelect-error-icon'
												/>
											</div>
										)}
										{props.meta.touched && props.meta.error && (
											<p className='mt-2 text-sm text-red-600' id='rangeStatusSelect-error' data-testid='rangeStatusSelect-error'>
												{props.meta.error}
											</p>
										)}
									</div>
								)}
							</Field>
						</div>
						<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
							<EditLabel label='Notes' id='notes' />
							<Field name='notes'>
								{(props: FieldProps<any>) => (
									<EditTextField formikProps={props} id='range-notes' placeholder='Add a note to the range' errorKey='range-note-error' />
								)}
							</Field>
						</div>
						{/* Reason for Change */}
						<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
							<EditLabel label='Reason For Change' id='range-change-reason' />
							<Field name='changeReason'>
								{(props: FieldProps<any>) => (
									<TextArea
										formikProps={props}
										id='range-change-reason'
										errorKey='change-reason-error'
										placeholder='Enter a reason for changing this range.'
									/>
								)}
							</Field>
						</div>
					</div>

					{/* Action buttons */}
					<div className='flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6'>
						<div className='flex justify-end space-x-3'>
							<button
								data-testid='cancel-button'
								type='button'
								onClick={() => (isRangeAddForm ? onClose() : rangeFormReset(props.resetForm))}
								className='rounded-md border border-gray-300 bg-gray-50 dark:bg-gray-800 py-2 px-4 text-sm font-medium text-gray-800 dark:text-white shadow-sm hover:bg-gamma-digital hover:text-white dark:hover:bg-gamma-digital focus:outline-none focus:ring-2 focus:ring-gamma-digital focus:ring-offset-2 dark:focus:text-white'
							>
								Cancel
							</button>
							<button
								data-testid='submit-button'
								type='submit'
								disabled={props.isSubmitting}
								onClick={() => props.submitForm()}
								className='ml-4 inline-flex justify-center rounded-md border border-transparent bg-gamma-digital py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gamma-hover focus:outline-none focus:ring-2 focus:ring-gamma-hover focus:ring-offset-2'
							>
								{props.isSubmitting ? <Spinner color='button' /> : null}
								&nbsp;Save
							</button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default RangeForm;
