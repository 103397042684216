import React, { useState } from 'react';
import { OfcomRangeResponse } from 'types';
import RangeForm from './range-form';
import RangeView from './range-view';

interface RangeViewContainerProps {
	range: OfcomRangeResponse;
	openExpandDialogFunction: (range: OfcomRangeResponse) => void;
	openContractDialogFunction: (range: OfcomRangeResponse) => void;
	onClose: () => void;
}

const RangeViewContainer: React.FC<RangeViewContainerProps> = ({
	range,
	openExpandDialogFunction,
	openContractDialogFunction,
	onClose,
}: RangeViewContainerProps) => {
	const [editMode, setEditMode] = useState(false);
	return (
		<>
			{editMode ? (
				<RangeForm range={range} setEditMode={setEditMode} onClose={onClose} />
			) : (
				<RangeView
					range={range}
					openExpandDialogFunction={openExpandDialogFunction}
					openContractDialogFunction={openContractDialogFunction}
					setEditMode={setEditMode}
				/>
			)}
		</>
	);
};
export default RangeViewContainer;
