import React from 'react';
import { Deskphone } from 'assets';
import { ScrollTop } from 'components';

const About: React.FC = () => (
	<main className='relative'>
		<div className='lg:absolute lg:inset-0'>
			<div className='lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2'>
				<img className='h-56 w-full object-cover lg:absolute lg:h-full' src={Deskphone} alt='user interacting with deskphone' />
			</div>
		</div>
		<div className='relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2'>
			<div className='lg:col-start-2 lg:pl-8'>
				<div className='text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0'>
					<h1 className='leading-6 text-gamma-digital font-semibold tracking-wide uppercase'>Numbering and Porting Value Stream</h1>
					<h2 className='mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-4xl'>Our Goals</h2>
					<p className='mt-8 text-lg text-gray-500'>
						Provide a state-of-art Number Management, Number Porting and Routing core service in the whole BSS/OSS estate working seamlessly
						end to end, inline with the Group strategy.
					</p>
					<div className='mt-5 prose prose-indigo text-gray-500'>
						<p>
							Quickly and easily integrate with Gamma&apos; suite of products providing innovative solutions that work across the board with
							the ability to adapt, scale and modernise as and when the need arises.
						</p>
						<p>
							A service tightly aligned to business goals, reflects customer needs and also conforms to the latest industry standards and
							regulations.
						</p>
						<h3 className='text-black dark:text-white'>How NPAC achieves these goals</h3>
						<p>
							The Numbering and Porting Agent Console (or NPAC) aims to provide a slick and mordern interface to allow the operational teams
							within Gamma to better manage user requests and manage core Numbering and Porting data used by other software applications
							with Gamma
						</p>
						<p>Current features of NPAC: </p>
						<ul id='features'>
							<li>Management of important industry reference data such as Communication Providers and Number Ranges issued by Ofcom</li>
							<li>Management of Number Ports including Geographic, Non Geographic, IPEX and Mixed Operator variants</li>
							<li>Management of Porting and Hosting Agreements with other Communication Providers</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<ScrollTop />
	</main>
);

export default About;
