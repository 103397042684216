import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import { ProvideAppContext } from 'contexts';
import { About, CommunicationProviders, Home, NotFound, OfcomRanges, PortAgreements, Profile, Support, Team } from 'pages';
import { Layout } from 'sections';

type AppProps = {
	pca: IPublicClientApplication;
};

/**
 * The main body of the app
 * @return {JSX.Element}: The main N&P Toolkit Application known as NPAC
 */
function App({ pca }: AppProps) {
	return (
		<MsalProvider instance={pca}>
			<ProvideAppContext>
				<Router>
					<Layout>
						<Routes>
							<Route path='/' element={<Home />} />
							<Route path='/about' element={<About />} />
							<Route path='/cupids' element={<CommunicationProviders />} />
							<Route path='/ranges' element={<OfcomRanges />} />
							<Route path='/portagreements' element={<PortAgreements />} />
							<Route path='/profile' element={<Profile />} />
							<Route path='/support' element={<Support />} />
							<Route path='/team' element={<Team />} />
							<Route path='*' element={<NotFound />} />
						</Routes>
					</Layout>
				</Router>
				<Toaster position='top-right' />
			</ProvideAppContext>
		</MsalProvider>
	);
}

export default App;
