import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Tab } from '@headlessui/react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { useMsal } from '@azure/msal-react';
import { format } from 'date-fns';

import { useHasRole } from 'hooks';
import { CommunicationProviderService } from 'services';

import type { CommunicationProvider } from 'types';
import { getAccessToken, OFCOM_API_SCOPES, OFCOM_DATA_ADMIN_ROLE } from 'utils';
import { HistoryTab } from 'components';
import { EditButton } from 'components/edit-button';

interface ProviderViewProps {
	provider: CommunicationProvider;
	setEditMode: Dispatch<SetStateAction<boolean>>;
}

const ProviderView = ({ provider, setEditMode }: ProviderViewProps) => {
	const hasAdminRole = useHasRole(OFCOM_DATA_ADMIN_ROLE);

	return (
		<div className='h-full overflow-y-auto p-4' data-testid='provider-view'>
			<div className='space-y-1 pb-4'>
				<div>
					<div className='flex items-start justify-between'>
						<div>
							<p className='text-sm font-medium text-gray-500'>
								Last Update{' '}
								<time dateTime={format(new Date(provider.lastUpdated), 'dd/MM/yyyy')} data-testid='last-updated'>
									{format(new Date(provider.lastUpdated), 'dd/MM/yyyy')}
								</time>
							</p>
						</div>
						{hasAdminRole ? <EditButton setEditMode={setEditMode}></EditButton> : null}
					</div>
				</div>
				<div>
					<h3 className='font-medium text-gray-800 dark:text-gray-200'>Information</h3>
					<dl className='mt-2 divide-y divide-gray-200 border-t border-b border-gray-200'>
						<div className='flex justify-between py-3 text-sm font-medium'>
							<dt className='text-gray-500'>Franchise Area</dt>
							<dd data-testid='franchise-area'>{provider.franchiseArea ? provider.franchiseArea : ''}</dd>
						</div>
						<div className='flex justify-between py-3 text-sm font-medium'>
							<dt className='text-gray-500'>Gamma</dt>
							<dd data-testid='gamma-flag'>
								<FlagIcon flag={provider.flags.gamma} />
							</dd>
						</div>
						<div className='flex justify-between py-3 text-sm font-medium'>
							<dt className='text-gray-500'>Hosting Partner</dt>
							<dd data-testid='hosting-partner-flag'>
								<FlagIcon flag={provider.flags.hostingPartner} />
							</dd>
						</div>
						<div className='flex justify-between py-3 text-sm font-medium'>
							<dt className='text-gray-500'>Porting Partner</dt>
							<dd data-testid='porting-partner-flag'>
								<FlagIcon flag={provider.flags.portingPartner} />
							</dd>
						</div>
						{provider.flags.portingPartner ? (
							<>
								<div className='flex justify-between py-3 text-sm font-medium'>
									<dt className='text-gray-500'>Single Line Email(s)</dt>
									<dd data-testid='single-line-email'>
										<ul>
											{provider.singleLineEmail?.split(';').map((e) => {
												e = e.trim();
												return <li key={e}>{e}</li>;
											})}
										</ul>
									</dd>
								</div>
								<div className='flex justify-between py-3 text-sm font-medium'>
									<dt className='text-gray-500'>Multi Line Email(s)</dt>
									<dd data-testid='multi-line-email'>
										<div>
											<ul>
												{provider.multiLineEmail?.split(';').map((e) => {
													e = e.trim();
													return <li key={e}>{e}</li>;
												})}
											</ul>
										</div>
									</dd>
								</div>
							</>
						) : null}
						<div className='flex justify-between py-3 text-sm font-medium'>
							<dt className='text-gray-500'>Short Name</dt>
							<dd data-testid='provider-short-name'>{provider.shortName}</dd>
						</div>
						<div className='flex justify-between py-3 text-sm font-medium'>
							<dt className='text-gray-500'>Parent Provider</dt>
							<dd data-testid='provider-parent'>{provider.parent ? `(${provider.parent.cupid}) ${provider.parent.name}` : ''}</dd>
						</div>
					</dl>
				</div>
				<div>
					<Tab.Group defaultIndex={provider.parent ? 1 : 0}>
						<Tab.List className='space-x-2 lg:space-x-8'>
							<Tab
								disabled={provider.parent ? true : false}
								className={({ selected }) =>
									classNames(
										selected
											? 'border-gamma-digital text-gamma-digital'
											: 'border-transparent text-gray-500 dark:text-gray-200 hover:text-gamma-hover hover:border-gamma-hover',
										'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
									)
								}
							>
								Children
							</Tab>
							<Tab
								className={({ selected }) =>
									classNames(
										selected
											? 'border-gamma-digital text-gamma-digital'
											: 'border-transparent text-gray-500 dark:text-gray-200 hover:text-gamma-hover hover:border-gamma-hover',
										'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
									)
								}
								data-testid='history-tab'
							>
								History
							</Tab>
						</Tab.List>
						<Tab.Panels className='my-4 lg:my-8 space-x-2 lg:space-x-8'>
							<Tab.Panel data-testid='children-tab-panel'>
								<ChildrenTab provider={provider} />
							</Tab.Panel>
							<Tab.Panel data-testid='history-tab-panel'>
								<HistoryTab
									historyFunction={(token, page?, perPage?) =>
										CommunicationProviderService.getProviderHistory(token, provider.cupid, page, perPage)
									}
								/>
							</Tab.Panel>
						</Tab.Panels>
					</Tab.Group>
				</div>
			</div>
		</div>
	);
};

export default ProviderView;

interface FlagIconProps {
	flag: boolean;
}

/**
 *
 * @param {FlagIconProps} props: input flag to check condition of
 * @return {JSX.Element}: A tick or cross icon depending on flag condition
 */
function FlagIcon({ flag }: FlagIconProps): JSX.Element {
	return flag ? (
		<CheckCircleIcon aria-hidden='true' className='h-6 w-6 text-green-500' />
	) : (
		<XCircleIcon aria-hidden='true' className='h-6 w-6 text-red-500' />
	);
}

interface ChildrenTabProps {
	provider: CommunicationProvider;
}

/**
 * Tab containing a list of children providers
 * @return {JSX.Element}
 */
function ChildrenTab({ provider }: ChildrenTabProps) {
	const [children, setChildren] = useState<CommunicationProvider[]>([]);
	const msal = useMsal();

	useEffect(() => {
		const getChildren = async () => {
			const token = await getAccessToken(OFCOM_API_SCOPES, msal);
			setChildren(await CommunicationProviderService.getChildProviders(token, provider.cupid));
		};

		getChildren();
	}, []);

	return (
		<ul className='divide-y divide-gray-200' data-testid='children-list'>
			{children.map((child) => (
				<li key={child.cupid} className='py-4 flex'>
					<p className='text-sm font-medium'>
						{`(${child.cupid})`} {child.name} {child.franchiseArea ? `${child.franchiseArea}` : ''}
					</p>
				</li>
			))}
		</ul>
	);
}
