import React from 'react';

interface LabelProps {
	label: string;
	id: string;
	mandatory?: boolean;
}

/**
 *
 * @param {LabelProps} param0
 * @return {JSX.Element}
 */
const EditLabel = ({ label, id, mandatory = false }: LabelProps): JSX.Element => {
	return (
		<div>
			<label htmlFor={id} className='block text-sm font-medium text-gray-800 dark:text-gray-200 sm:mt-px sm:pt-2'>
				{' '}
				{label}{' '}
				{mandatory ? (
					<span data-testid='mandatory-star' className='text-red-500'>
						*
					</span>
				) : null}
			</label>
		</div>
	);
};

export default EditLabel;
