import React, { Dispatch, SetStateAction } from 'react';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';

interface SearchTypeToggleProps {
	advancedSearch: boolean;
	setAdvancedSearch: Dispatch<SetStateAction<boolean>>;
}

const SearchTypeToggle: React.FC<SearchTypeToggleProps> = ({ advancedSearch, setAdvancedSearch }: SearchTypeToggleProps) => {
	return (
		<Switch.Group>
			<div className='flex items-center'>
				<Switch
					id='search-toggle'
					data-testid='search-toggle'
					checked={advancedSearch}
					onChange={setAdvancedSearch}
					className={classNames(
						advancedSearch ? 'bg-gamma-digital' : 'bg-gray-400',
						'relative inline-flex flex-shrink-0 h-10 w-16 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-150 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gamma-hover',
					)}
				>
					<span className='sr-only' data-testid={'search-toggle-screen-reader'}>
						{advancedSearch ? 'Toggle Simple Search Functions' : 'Toggle Advanced Search Functions'}
					</span>
					<span
						aria-hidden='true'
						className={classNames(
							advancedSearch ? 'translate-x-5' : 'translate-x-0',
							'pointer-events-none inline-block h-9 w-9 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-100',
						)}
					/>
				</Switch>
				<Switch.Label className='pl-2 text-sm font-extrabold sm:text-lg md:text-lg lg:text-xl xl:text-xl text-gamma-digital dark:text-white'>
					Advanced Search
				</Switch.Label>
			</div>
		</Switch.Group>
	);
};

export default SearchTypeToggle;
