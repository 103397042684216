import React from 'react';
import { FieldProps } from 'formik';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

interface TextAreaProps {
	formikProps: FieldProps<any>;
	id: string;
	placeholder: string;
	errorKey: string;
}

const TextArea = ({ formikProps, id, placeholder, errorKey }: TextAreaProps): JSX.Element => {
	return (
		<>
			<div className='sm:col-span-2 relative rounded-md shadow-sm'>
				<textarea
					id={id}
					data-testid={id}
					placeholder={placeholder}
					aria-invalid={formikProps.meta.touched && formikProps.meta.error ? 'true' : 'false'}
					aria-describedby={formikProps.meta.touched && formikProps.meta.error ? `${errorKey}` : ''}
					className={classNames(
						'block w-full rounded-md sm:text-sm text-gray-800 dark:text-white dark:bg-gray-800',
						formikProps.meta.touched && formikProps.meta.error
							? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:outline-red-500 focus:border-red-500'
							: 'border-gray-300 focus:border-gamma-digital focus:ring-gamma-digital',
					)}
					{...formikProps.field}
				/>
				{formikProps.meta.touched && formikProps.meta.error && (
					<div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
						<ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' data-testid={`${id}-error-icon`} />
					</div>
				)}
			</div>
			{formikProps.meta.touched && formikProps.meta.error && (
				<p className='mt-2 text-sm text-red-600' id={errorKey} data-testid={`${id}-error`}>
					{formikProps.meta.error}
				</p>
			)}
		</>
	);
};

export default TextArea;
