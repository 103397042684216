import React, { Dispatch, SetStateAction } from 'react';
import { PortAgreementItem } from 'types';
import { format } from 'date-fns';
import { Tab } from '@headlessui/react';
import { PortAgreementService } from 'services';
import { EditButton, HistoryTab } from 'components';
import { useHasRole } from 'hooks';
import { PORT_AGREEMENT_SINGLE_ADMIN_ROLE } from 'utils';

interface PortAgreementViewProps {
	agreement: PortAgreementItem;
	openDeleteDialogFunction: (agreement: PortAgreementItem) => void;
	setEditMode: Dispatch<SetStateAction<boolean>>;
}

const PortAgreementView = ({ agreement, openDeleteDialogFunction, setEditMode }: PortAgreementViewProps) => {
	const hasSingleAdminRole = useHasRole(PORT_AGREEMENT_SINGLE_ADMIN_ROLE);

	return (
		<div data-testid='agreement-view' className='h-full overflow-y-auto p-4'>
			<div className='space-y-1 pb-4'>
				<div>
					<div className='flex items-start justify-between'>
						<div>
							<p className='text-sm font-medium text-gray-500'>
								Last Update{' '}
								<time dateTime={format(new Date(agreement.lastUpdated), 'dd/MM/yyyy')} data-testid='last-updated'>
									{format(new Date(agreement.lastUpdated), 'dd/MM/yyyy')}
								</time>
							</p>
						</div>
						<div className='flex items-start justify-between'>
							{hasSingleAdminRole ? <EditButton setEditMode={setEditMode}></EditButton> : null}

							{hasSingleAdminRole ? (
								<button
									type='button'
									className='ml-4 flex h-8 w-16 px-4 py-2 items-center justify-center border border-transparent rounded-md text-sm font-medium shadow-sm bg-gamma-digital text-white hover:bg-gamma-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-gamma-digital'
									data-testid='expand-button'
									onClick={() => openDeleteDialogFunction(agreement)}
								>
									Delete
								</button>
							) : null}
						</div>
					</div>
				</div>
			</div>
			<div>
				<h3 className='font-medium text-gray-800 dark:text-gray-200'>Information</h3>
				<dl className='mt-2 divide-y divide-gray-200 border-t border-b border-gray-200'>
					<div className='flex justify-between py-3 text-sm font-medium'>
						<dt className='text-gray-500'>Number Range</dt>
						<dd data-testid='number-range'>{`+44${agreement.numberBlock}`}</dd>
					</div>
					<div className='flex justify-between py-3 text-sm font-medium'>
						<dt className='text-gray-500'>Communications Provider</dt>
						<dd data-testid='communications-provider'>{`${agreement.agreementHolder.name}${
							agreement.agreementHolder.franchiseArea ? ` ${agreement.agreementHolder.franchiseArea}` : ''
						} (${agreement.agreementHolder.cupid})`}</dd>
					</div>
					<div className='flex justify-between py-3 text-sm font-medium'>
						<dt className='text-gray-500'>Prefix</dt>
						<dd data-testid='port-prefix'>{agreement.portPrefix}</dd>
					</div>
					{agreement.pdi ? (
						<div className='flex justify-between py-3 text-sm font-medium'>
							<dt className='text-gray-500'>PDI</dt>
							<dd data-testid='pdi-link'>
								<a
									data-testid={agreement.pdi.key}
									className='ml-2 relative inline-flex items-center px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gamma-digital hover:bg-gamma-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gamma-hover focus:ring-gamma-hover'
									href={agreement.pdi.url}
								>
									View
								</a>
							</dd>
						</div>
					) : (
						<div className='flex justify-between py-3 text-sm font-medium'>
							<dt className='text-gray-500'>PDI</dt>
							<dd data-testid='no-pdi'>No Associated PDI</dd>
						</div>
					)}
				</dl>
			</div>
			<div>
				<Tab.Group defaultIndex={0}>
					<Tab.List className='space-x-2 lg:space-x-8'>
						<Tab
							className='border-gamma-digital text-gamma-digital whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
							data-testid='history-tab'
						>
							History
						</Tab>
					</Tab.List>
					<Tab.Panels className='my-4 lg:my-8 space-x-2 lg:space-x-8'>
						<Tab.Panel data-testid='history-tab-panel'>
							<HistoryTab
								historyFunction={(token, page?, perPage?) =>
									PortAgreementService.getPortAgreementItemHistory(token, agreement.id, page, perPage)
								}
							/>
						</Tab.Panel>
					</Tab.Panels>
				</Tab.Group>
			</div>
		</div>
	);
};

export default PortAgreementView;
