import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';

import { StatService } from 'services';
import { AgreementProviderCountStat } from 'types';
import { getAccessToken } from 'utils';
import { apiConfig } from 'auth-config';

export const top = 5;

const PortAgreementCountTable: React.FC = () => {
	const [agreementCounts, setAgreementCounts] = useState<AgreementProviderCountStat[]>([]);
	const msal = useMsal();
	const ofcomApiScopes = apiConfig.ofcomApi.scopes;

	useEffect(() => {
		/**
		 * Load the top porting partners and sublist so only the top 5 are displayed.
		 */
		async function loadTopPortingPartners() {
			const token: string = await getAccessToken(ofcomApiScopes, msal);
			const countsPerProvider = await StatService.getAgreementProviderTypeCounts(token);

			const responseLength = countsPerProvider.length;
			setAgreementCounts(countsPerProvider.slice(0, responseLength < top ? responseLength : top));
		}

		loadTopPortingPartners();
	}, []);

	return (
		<div className='px-4 sm:px-6 lg:px-8'>
			<div className='sm:flex sm:items-center'>
				<div className='sm:flex-auto'>
					<h1 className='text-base font-semibold leading-6 text-gray-900 dark:text-white'>Port Agreements</h1>
					<p className='mt-2 text-sm text-gray-700 dark:text-gray-300'>{`Counts of port agreements in place per provider (top ${top})`}</p>
				</div>
			</div>
			<div className='mt-8 flow-root'>
				<div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
					<div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
						<table className='min-w-full divide-y divide-gray-300'>
							<thead>
								<tr className='divide-x divide-gray-200'>
									<th scope='col' className='py-3.5 pl-4 text-left text-sm font-semibold text-gray-900 dark:text-white'>
										Communication Provider
									</th>
									<th scope='col' className='py-3.5 px-4 text-left text-sm font-semibold text-gray-900 dark:text-white'>
										Total Active Agreements
									</th>
								</tr>
							</thead>
							<tbody className='divide-y divide-gray-200 bg-white dark:bg-gray-800'>
								{agreementCounts.map((ac, acIdx) => (
									<tr key={acIdx} className='divide-x divide-gray-200'>
										<td className='py-4 pl-4 pr-4 text-sm font-medium text-gray-900 dark:text-white sm:pl-0 text-center'>
											{`(${ac.agreementHolder.cupid}) ${ac.agreementHolder.name} ${
												ac.agreementHolder.franchiseArea ? ac.agreementHolder.franchiseArea : ''
											}`}
										</td>
										<td className='py-4 pl-4 pr-4 text-sm font-medium text-gray-900 dark:text-white sm:pl-0 text-center'>{ac.count}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PortAgreementCountTable;
