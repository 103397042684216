import type { NumberRangeHolderResponse } from 'types';
import { apiConfig } from 'auth-config';
import { createRequestHeader } from 'utils';

const baseUrl = apiConfig.ofcomApi.baseUrl;

/**
 * Get the list of range holders for advanced search and range management functions.
 * @param {string} token: Access token for API requests
 * @return {Promise<RangeHolder[]>}
 */
export async function getRangeHolderList(token: string): Promise<NumberRangeHolderResponse[]> {
	const getUrl: string = `${baseUrl}/range/holder?order=ID`;
	return await (await fetch(getUrl, createRequestHeader(token, 'GET'))).json();
}
