export type TopBarLink = {
	name: string;
	href: string;
	current: boolean;
};

const NavigationContent: TopBarLink[] = [
	{ name: 'Communication Providers', href: '/cupids', current: false },
	{ name: 'Number Ranges', href: '/ranges', current: false },
	{ name: 'Port Agreements', href: '/portagreements', current: false },
];

export default NavigationContent;
