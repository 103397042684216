import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import type { FC } from 'react';
import { apiConfig } from 'auth-config';
import { OfcomRangeService } from 'services';
import type { OfcomRangeResponse } from 'types';
import { getAccessToken } from 'utils';
import RangeTable from './range-table';
import { RangeActionsDropdown, SearchTypeToggle } from './range-components';

interface SimpleSearchProps {
	setRanges: Dispatch<SetStateAction<OfcomRangeResponse[]>>;
	ranges: OfcomRangeResponse[];
	onRangeSelection: (rangeId: number) => Promise<void>;
	setAdvancedSearch: Dispatch<SetStateAction<boolean>>;
	pageSize?: number;
	onAddRange: () => Promise<void>;
	searchTerm?: string;
}

const SimpleSearch: FC<SimpleSearchProps> = ({
	setRanges,
	ranges,
	onRangeSelection,
	setAdvancedSearch,
	pageSize = 5,
	onAddRange,
	searchTerm = '',
}): JSX.Element => {
	const [searchInput, setSearchInput] = useState<string>('');
	const msal = useMsal();
	const [page, setPage] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const ofcomApiScopes = apiConfig.ofcomApi.scopes;

	useEffect(() => {
		setSearchInput(searchTerm);
	}, [searchTerm]);

	const handleTermChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		e.preventDefault();
		setSearchInput(e.target.value);
	};

	const handleSimpleSearch = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLInputElement>): void => {
		e.preventDefault();
		// reset page and more values then set state
		setPage(0);
		fetchRanges(searchInput, 0);
	};

	const fetchRanges = async (term: string, page: number) => {
		const token: string = await getAccessToken(ofcomApiScopes, msal);
		OfcomRangeService.simpleSearchRanges(token, term, page, pageSize).then((r) => {
			setRanges(r);
			setHasMore(!(r.length < pageSize));
		});
	};

	const nextPage = () => {
		if (hasMore) {
			const newPage = page + 1;
			setPage(newPage);
			fetchRanges(searchInput, newPage);
		}
	};

	const previousPage = () => {
		if (page > 0) {
			const newPage = page - 1;
			setPage(newPage);
			fetchRanges(searchInput, newPage);
		}
	};

	return (
		<>
			{/* Search Input Box */}
			<div className='flex p-2'>
				<div className='w-8/12 md:w-4/12 mt-2 relative rounded-full shadow-sm'>
					<div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
						<MagnifyingGlassIcon aria-hidden='true' className='h-5 w-5 text-gray-400' />
					</div>
					<input
						type='text'
						name='searchInput'
						id='searchInput'
						value={searchInput}
						className='focus:ring-gamma-digital focus:border-gamma-digital block w-full pl-10 sm:text-sm border-gray-300 px-4 rounded-full shadow-sm text-black dark:text-white dark:bg-gray-800'
						placeholder='Search Number Ranges'
						onChange={(e) => handleTermChange(e)}
						onKeyDown={(e) => e.key === 'Enter' && handleSimpleSearch(e)} // Search by pressing enter
					/>
				</div>
				<div className='p-2'>
					<button
						type='button'
						className='ml-4 rounded-md border border-transparent bg-gamma-digital p-2 text-sm font-medium text-white shadow-sm hover:bg-gamma-hover focus:outline-none focus:ring-2 focus:ring-gamma-hover focus:ring-offset-2'
						data-testid='search-button'
						onClick={(e) => handleSimpleSearch(e)}
					>
						Search
					</button>
				</div>
				<div className='p-2'>
					<SearchTypeToggle advancedSearch={false} setAdvancedSearch={setAdvancedSearch} />
				</div>
			</div>

			{/* actions menu */}
			<RangeActionsDropdown onAddRange={onAddRange} />

			{/* Results selection */}
			<RangeTable
				ranges={ranges}
				hasPrevious={page > 0}
				hasNext={hasMore}
				page={page}
				pageSize={pageSize}
				onPrevious={() => previousPage()}
				onNext={() => nextPage()}
				onSelection={onRangeSelection}
			/>
		</>
	);
};

export default SimpleSearch;
