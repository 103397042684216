import { useEffect, useState } from 'react';

/**
 * This React hook helps to limit that the component is re-rendered too many times. Imagine that you want * to execute a function on an event that executes several hundred times per second such as moving the
 * mouse or scrolling. This may cause the application to lag. To prevent this, the debounce uses an
 * internal timer to execute the callback function every xx milliseconds (2nd parameter).
 *
 * For example, calling an API too find the elements that match the search term, can do so by monitoring
 * the unpacked variable, which will be more economical.
 *
 * @param {T} value: The value to detect changes for and unfold based on a delay
 * @param {number | undefined} delay: The delay in milliseconds defaulting to 500 if not specified
 * @return {T} The unfolded value post debounce
 */
function useDebounce<T>(value: T, delay?: number): T {
	const [debouncedValue, setDebouncedValue] = useState<T>(value);

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

		return () => {
			clearTimeout(timer);
		};
	}, [value, delay]);

	return debouncedValue;
}

export default useDebounce;
