import React, { Fragment } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { GammaWhiteIcon, GammaWhiteLogo } from 'assets';
import { Avatar, DarkModeToggle } from 'components';
import { useAppContext } from 'contexts';
import NavigationContent from './navigation-content';

const Header: React.FC = () => {
	const isAuthenticated = useIsAuthenticated();
	const app = useAppContext();
	const user = app.user || { avatar: undefined, displayName: '', email: '', jobTitle: '', location: '', phone: '' };

	const location = useLocation();

	const currentPath = location.pathname;
	for (let i = 0; i < NavigationContent.length; i++) {
		NavigationContent[i].current = NavigationContent[i].href == currentPath ? true : false;
	}

	return (
		<Disclosure
			as='nav'
			className='bg-purple-dark dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700'
			data-testid='layout-header'
		>
			{({ open }) => (
				<>
					<div className='max-w-7xl mx-auto px-2 sm:px-6 lg:px-8'>
						<div className='relative flex items-center justify-between h-16'>
							<div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
								{/* Mobile menu button*/}
								{isAuthenticated ? (
									<Disclosure.Button className='inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-gamma-digital focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gamma-digital'>
										<span className='sr-only' data-testid='main-menu'>
											Open main menu
										</span>
										{open ? (
											<XMarkIcon className='block h-6 w-6' aria-hidden={true} />
										) : (
											<Bars3Icon className='block h-6 w-6' aria-hidden={true} />
										)}
									</Disclosure.Button>
								) : null}
							</div>
							<div className='flex-1 flex items-center justify-center sm:items-stretch sm:justify-start'>
								<div className='flex-shrink-0 flex items-center'>
									<Link to='/' data-cy='header-gamma-icon'>
										<img className='block lg:hidden h-8 w-auto' src={GammaWhiteIcon} alt='White Gamma Corporate Logo' />
										<img className='hidden lg:block h-8 w-auto' src={GammaWhiteLogo} alt='White Gamma Corporate Logo' />
									</Link>
								</div>
								{isAuthenticated ? (
									<div className='hidden sm:block sm:ml-6'>
										<div className='flex space-x-4'>
											{NavigationContent.map((item) => (
												<Link
													key={item.name}
													to={item.href}
													className={classNames(
														item.current ? 'bg-gamma-digital text-white' : 'text-white hover:bg-gamma-hover hover:text-white',
														'px-3 py-2 lg:py-5 rounded-md text-sm font-medium',
													)}
													aria-current={item.current ? 'page' : undefined}
												>
													{item.name}
												</Link>
											))}
										</div>
									</div>
								) : null}
							</div>
							<div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
								<DarkModeToggle />

								{isAuthenticated ? (
									<Menu as='div' className='ml-3 relative z-30'>
										<div>
											<Menu.Button className='bg-purple-dark dark:bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gamma-digital focus:ring-gamma-digital dark:focus:ring-gamma-digital'>
												<span className='sr-only' data-testid='user-menu'>
													Open user menu
												</span>
												{user.avatar ? (
													<>
														<img className='h-8 w-8 rounded-full' src={user.avatar} alt={`${user.displayName} avatar`} />
													</>
												) : (
													<>
														<Avatar fullname={user.displayName ? user.displayName : ''} />
													</>
												)}
											</Menu.Button>
										</div>
										<Transition
											as={Fragment}
											enter='transition ease-out duration-100'
											enterFrom='transform opacity-0 scale-95'
											enterTo='transform opacity-100 scale-100'
											leave='transition ease-in duration-75'
											leaveFrom='transform opacity-100 scale-100'
											leaveTo='transform opacity-0 scale-95'
										>
											<Menu.Items className='origin-top-right absolute right-0 mt-3 w-48 rounded-md shadow-lg py-1 bg-purple-dark dark:bg-gray-800 ring-1 ring-gamma-digital ring-opacity-5 focus:outline-none'>
												<Menu.Item>
													<span className='block px-4 py-2 text-sm text-white'>
														{user.displayName} {user.email} {user.jobTitle} {user.location}
													</span>
												</Menu.Item>
												<Menu.Item>
													{({ active }) => (
														<Link
															to='/profile'
															className={classNames(active ? 'bg-gamma-digital' : '', 'block px-4 py-2 text-sm text-white')}
															data-testid='profile-link'
														>
															Your Profile
														</Link>
													)}
												</Menu.Item>
												<Menu.Item>
													{({ active }) => (
														// eslint-disable-next-line jsx-a11y/anchor-is-valid
														<a
															href='#'
															className={classNames(active ? 'bg-gamma-digital' : '', 'block px-4 py-2 text-sm text-white')}
															onClick={app.signOut}
															data-testid='sign-out-link'
														>
															Sign Out
														</a>
													)}
												</Menu.Item>
											</Menu.Items>
										</Transition>
									</Menu>
								) : (
									<button
										type='button'
										className='ml-2 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gamma-digital hover:bg-gamma-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gamma-hover focus:ring-gamma-hover'
										onClick={app.signIn}
										data-testid='sign-in-button'
									>
										Sign In
									</button>
								)}
							</div>
						</div>
					</div>

					{isAuthenticated ? (
						<Disclosure.Panel className='sm:hidden'>
							<div className='px-2 pt-2 pb-3 space-y-1'>
								{NavigationContent.map((item) => (
									<Disclosure.Button
										key={item.name}
										as='a'
										href={item.href}
										className={classNames(
											item.current ? 'bg-gamma-digital text-white' : 'text-white hover:bg-gamma-hover hover:text-white',
											'block px-3 py-2 rounded-md text-base font-medium',
										)}
										aria-current={item.current ? 'page' : undefined}
									>
										{item.name}
									</Disclosure.Button>
								))}
							</div>
						</Disclosure.Panel>
					) : null}
				</>
			)}
		</Disclosure>
	);
};

export default Header;
