import React, { useEffect, useState } from 'react';
import { User } from 'microsoft-graph';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { NoSymbolIcon, UserIcon } from '@heroicons/react/24/solid';

import { AppUser } from 'contexts';
import { GraphService } from 'services';

interface DetailsProps {
	user: AppUser;
	authProvider: AuthCodeMSALBrowserAuthenticationProvider | undefined;
}

const UserDetails = ({ user, authProvider }: DetailsProps) => {
	const [reportees, setReportees] = useState([] as User[]);

	useEffect(() => {
		/**
		 * Load the direct reports that a user has
		 */
		async function loadReportees() {
			setReportees(await GraphService.getDirectReports(authProvider));
		}

		loadReportees();
	}, []);

	return (
		<div className='bg-gray-200 dark:bg-gray-700 shadow overflow-hidden sm:rounded-lg'>
			<div className='px-4 py-5 sm:px-6'>
				<h3 className='text-lg leading-6 font-medium'>Your Details</h3>
			</div>
			<div className='border-t border-gray-400 dark:border-gray-200'>
				<dl>
					<div className='bg-100 dark:bg-gray-700 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
						<dt className='text-sm font-medium text-gamma-digital'>Email address</dt>
						<dd className='mt-1 text-sm sm:mt-0 sm:col-span-2' data-testid='details-email'>
							{user.email}
						</dd>
					</div>
					<div className='bg-white dark:bg-gray-400 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
						<dt className='text-sm font-medium text-gamma-digital'>Job Title</dt>
						<dd className='mt-1 text-sm sm:mt-0 sm:col-span-2' data-testid='details-job-title'>
							{user.jobTitle}
						</dd>
					</div>
					<div className='bg-100 dark:bg-gray-700 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
						<dt className='text-sm font-medium text-gamma-digital'>Office Location</dt>
						<dd className='mt-1 text-sm sm:mt-0 sm:col-span-2' data-testid='details-location'>
							{user.location}
						</dd>
					</div>
					<div className='bg-white dark:bg-gray-400 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
						<dt className='text-sm font-medium text-gamma-digital'>Contact Number</dt>
						<dd className='mt-1 text-sm sm:mt-0 sm:col-span-2' data-testid='details-contact'>
							{user.phone ? user.phone.replaceAll(' ', '') : ''}
						</dd>
					</div>
					<div className='bg-100 dark:bg-gray-700 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
						<dt className='text-sm font-medium text-gamma-digital'>My Manager</dt>
						<dd className='mt-1 text-sm sm:mt-0 sm:col-span-2' data-testid='details-manager'>
							{user.manager ? user.manager : 'N/A'}
						</dd>
					</div>
					<div className='bg-white dark:bg-gray-400 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
						<dt className='text-sm font-medium text-gamma-digital'>My Direct Reports</dt>
						<dd className='mt-1 text-sm sm:mt-0 sm:col-span-2'>
							<ul className='border border-gray-200 rounded-md divide-y divide-gray-200'>
								{reportees.length > 0 ? (
									<>
										{reportees.map((reportee) => (
											<li key={reportee.id} className='pl-3 pr-4 py-3 flex items-center justify-between text-sm'>
												<div className='w-0 flex-1 flex items-center'>
													<UserIcon className='flex-shrink-0 h-5 w-5' aria-hidden='true' />
													<span
														className='ml-2 flex-1 w-0 truncate'
														data-testid={`details-reportee-${reportee.id}`}
													>{`${reportee.displayName} (${reportee.jobTitle})`}</span>
												</div>
											</li>
										))}
									</>
								) : (
									<>
										<li className='pl-3 pr-4 py-3 flex items-center justify-between text-sm'>
											<div className='w-0 flex-1 flex items-center'>
												<NoSymbolIcon className='flex-shrink-0 h-5 w-5' aria-hidden='true' />
												<span className='ml-2 flex-1 w-0 truncate' data-testid='details-no-reports'>
													No Direct Reports
												</span>
											</div>
										</li>
									</>
								)}
							</ul>
						</dd>
					</div>
				</dl>
			</div>
		</div>
	);
};

export default UserDetails;
