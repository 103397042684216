import React, { createContext, MouseEventHandler, useContext, useState, useEffect } from 'react';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { AccountInfo, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { User } from 'microsoft-graph';

import authConfig, { apiConfig } from 'auth-config';
import { GraphService } from 'services';

export interface AppUser {
	id?: string;
	displayName?: string;
	email?: string;
	jobTitle?: string;
	location?: string;
	avatar?: string;
	phone?: string;
	manager?: string;
	roles: string[];
}

type AppContext = {
	user?: AppUser;
	signIn?: MouseEventHandler<HTMLElement>;
	signOut?: MouseEventHandler<HTMLElement>;
	authProvider?: AuthCodeMSALBrowserAuthenticationProvider;
};

const appContext = createContext<AppContext>({
	user: undefined,
	signIn: undefined,
	signOut: undefined,
	authProvider: undefined,
});

/**
 * React hook for obtaining the Application Context
 * @return {AppContext}
 */
export function useAppContext(): AppContext {
	return useContext(appContext);
}

interface ProvideAppContextProps {
	children: React.ReactNode;
}

/**
 * Function to get the application context
 * @param {ProvideAppContextProps} param0: Child nodes
 * @return {JSX.Element}: Application Context provider
 */
export default function ProvideAppContext({ children }: ProvideAppContextProps) {
	const auth = useProvideAppContext();
	return <appContext.Provider value={auth}>{children}</appContext.Provider>;
}

/**
 * Function to setup the appContext
 * @return {AppContext}
 */
function useProvideAppContext() {
	const [user, setUser] = useState<AppUser | undefined>(undefined);
	const msal = useMsal();

	const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msal.instance as PublicClientApplication, {
		account: msal.instance.getActiveAccount()!,
		scopes: authConfig.scopes,
		interactionType: InteractionType.Redirect,
	});

	/**
	 * Funtion to extract the roles from the msalAccount
	 * @param {AccountInfo} msalAccount
	 * @return {string[]} Array of role or an empty array
	 */
	function getRoles(msalAccount: AccountInfo) {
		if (msalAccount.idTokenClaims) {
			if (msalAccount.idTokenClaims.roles) {
				return msalAccount.idTokenClaims.roles;
			}
		}
		return [];
	}

	useEffect(() => {
		const checkUser = async () => {
			if (!user) {
				try {
					// Check if user is already signed in
					const account = msal.instance.getActiveAccount();
					if (account) {
						// Get the user and profile from Microsoft Graph
						const user = await GraphService.getUser(authProvider);
						const picture = await GraphService.getProfilePicture(authProvider);

						const managerRes = await GraphService.getManagerChain(authProvider);
						const manager = managerRes?.manager as User;

						setUser({
							id: user.id || '',
							displayName: user.displayName || '',
							email: user.mail || user.userPrincipalName || '',
							jobTitle: user.jobTitle || '',
							location: user.officeLocation || '',
							avatar: picture || undefined,
							phone: user.businessPhones ? user.businessPhones[0] : '',
							manager: manager.displayName || '',
							roles: getRoles(account),
						});
					}
				} catch (err: any) {
					console.error(err.message);
				}
			}
		};
		checkUser();
	});

	const signIn = async () => {
		await msal.instance.loginRedirect({
			scopes: [...authConfig.scopes, ...apiConfig.ofcomApi.scopes],
		});

		// Get the user and picture from Microsoft Graph
		const user = await GraphService.getUser(authProvider);
		const picture = await GraphService.getProfilePicture(authProvider);

		const managerRes = await GraphService.getManagerChain(authProvider);
		const manager = managerRes?.manager as User;

		const account = msal.instance.getActiveAccount();

		setUser({
			id: user.id || '',
			displayName: user.displayName || '',
			email: user.mail || user.userPrincipalName || '',
			jobTitle: user.jobTitle || '',
			location: user.officeLocation || '',
			avatar: picture || undefined,
			phone: user.businessPhones ? user.businessPhones[0] : '',
			manager: manager.displayName || '',
			roles: account ? getRoles(account) : [],
		});
	};

	const signOut = async () => {
		const currentAccount = msal.instance.getActiveAccount();
		await msal.instance.logoutRedirect({
			account: currentAccount,
			postLogoutRedirectUri: '/',
		});
		setUser(undefined);
	};

	return {
		user,
		signIn,
		signOut,
		authProvider,
	};
}
