import React, { useEffect, useState } from 'react';
import { LightAssets, DarkAssets, DisableOfficeFiles } from 'assets';

import { ImageModal, ScrollTop } from 'components';
import { useDarkMode } from 'hooks';
import { HashLink } from 'react-router-hash-link';

const Support: React.FC = () => {
	const [assets, setAssets] = useState<typeof DarkAssets | typeof LightAssets>(LightAssets);
	const { isDarkMode } = useDarkMode();
	const [selectedImage, setSelectedImage] = useState<string>('');

	useEffect(() => {
		const assetsToUse = isDarkMode ? DarkAssets : LightAssets;
		setAssets(assetsToUse);
	}, [isDarkMode]);

	const setImage = (src: string) => {
		setSelectedImage(src);
	};

	const closeModal = () => {
		setSelectedImage('');
	};

	const getFigure = (imgSrc: string, alt: string, caption?: string) => {
		return (
			<figure>
				<img
					onClick={() => setImage(imgSrc)}
					aria-hidden='true'
					className='w-full rounded-lg drop-shadow-lg hover:opacity-50'
					src={imgSrc}
					alt={alt}
				/>
				{caption && <figcaption>{caption}</figcaption>}
			</figure>
		);
	};

	const serviceDeskLink = 'https://itservicedesk.gamma.co.uk/support/catalog/items/68';
	const noirBoardLink = 'https://gammatelecom.atlassian.net/jira/software/projects/NOIR/boards/803';
	return (
		<>
			<main className='relative py-16 overflow-hidden'>
				<ImageModal imageSrc={selectedImage} onClose={closeModal} />
				<div className='relative px-4 sm:px-6 lg:px-8'>
					<div className='text-lg max-w-prose mx-auto'>
						<h1>
							<span className='block text-base text-center text-gamma-digital font-semibold tracking-wide uppercase'>
								Help &amp; Support
							</span>
							<span className='mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight sm:text-4xl'>
								Getting Started and Using the <span className='text-gamma-digital'>Numbering and Porting Agent Console</span>
							</span>
						</h1>
					</div>
					<div className='mt-6 prose dark:prose-invert prose-indigo prose-lg mx-auto'>
						<div>
							<h2>Jump to Section</h2>
							<ul className='list-none'>
								<li>
									<HashLink smooth to={'#acquiring-access'} className='text-2xl font-bold no-underline'>
										Acquiring Access
									</HashLink>
								</li>
								<li>
									<HashLink smooth to={'#using-console'} className='text-2xl font-bold no-underline'>
										Using the Console
									</HashLink>
									<ul className='list-none'>
										<li>
											<HashLink smooth to={'#home-page'} className='text-2xl no-underline'>
												Home Page
											</HashLink>
										</li>
										<li>
											<HashLink smooth to={'#commprov-page'} className='text-2xl no-underline'>
												Communication Providers Page
											</HashLink>
											<ul>
												<li>
													<HashLink smooth to={'#commprov-search'} className='text-xl no-underline'>
														Searching for a Communication Provider
													</HashLink>
												</li>
												<li>
													<HashLink smooth to={'#commprov-details'} className='text-xl no-underline'>
														Viewing Communication Provider Information
													</HashLink>
												</li>
												<li>
													<HashLink smooth to={'#commprov-edit'} className='text-xl no-underline'>
														Editing a Communication Provider
													</HashLink>
												</li>
												<li>
													<HashLink smooth to={'#commprov-add'} className='text-xl no-underline'>
														Adding a new Communication Provider
													</HashLink>
												</li>
											</ul>
										</li>
										<li>
											<HashLink smooth to={'#ranges-page'} className='text-2xl no-underline'>
												Number Ranges Page
											</HashLink>
											<ul>
												<li>
													<HashLink smooth to={'#range-search'} className='text-xl no-underline'>
														Searching for a Range
													</HashLink>
												</li>
												<li>
													<HashLink smooth to={'#range-details'} className='text-xl no-underline'>
														Viewing Number Range Information
													</HashLink>
												</li>
												<li>
													<HashLink smooth to={'#range-expand-contract'} className='text-xl no-underline'>
														Expanding and Contracting a Number Range
													</HashLink>
												</li>
												<li>
													<HashLink smooth to={'#range-edit'} className='text-xl no-underline'>
														Editing a Number Range
													</HashLink>
												</li>
												<li>
													<HashLink smooth to={'#range-add'} className='text-xl no-underline'>
														Adding a Number Range
													</HashLink>
												</li>
											</ul>
										</li>
										<li>
											<HashLink smooth to={'#pa-page'} className='text-2xl no-underline' data-testid='pa-page-link'>
												Port Agreements Page
											</HashLink>
											<ul>
												<li>
													<HashLink smooth to={'#pa-search'} className='text-xl no-underline'>
														Searching for Port Agreements
													</HashLink>
												</li>
												<li>
													<HashLink smooth to={'#pa-details'} className='text-xl no-underline'>
														Viewing Port Agreement Information
													</HashLink>
												</li>
												<li>
													<HashLink smooth to={'#pa-edit'} className='text-xl no-underline'>
														Editing a Port Agreement
													</HashLink>
												</li>
												<li>
													<HashLink smooth to={'#pa-add'} className='text-xl no-underline'>
														Adding a Port Agreement
													</HashLink>
												</li>
											</ul>
										</li>
									</ul>
								</li>
								<li>
									<HashLink smooth to={'#contact-us'} className='text-2xl font-bold no-underline'>
										Contact Us
									</HashLink>
								</li>
								<li>
									<HashLink smooth to={'#faqs'} className='text-2xl font-bold no-underline'>
										FAQs
									</HashLink>
								</li>
							</ul>
						</div>
						<div>
							<h2 id='acquiring-access'>Acquiring Access</h2>
							<p>In order to get access to the Numbering and Porting Agent Console, please follow these steps:</p>
							<ol>
								<li>
									Navigate to the IT Services{' '}
									<a className='text-gamma-digital' href={serviceDeskLink} target='_blank' rel='noopener noreferrer'>
										SG Changes
									</a>{' '}
									page.
								</li>
								<li>
									In the <strong>&apos;Name of SG&apos;</strong> field, specify of the <strong>Security Group</strong> names for the roles
									you would like:
									<table>
										<tbody>
											<tr>
												<th>Security Group</th>
												<th>Application Role</th>
											</tr>
											<tr>
												<td>SG-Numbering-Porting-Console-Agent-Prod</td>
												<td>General read access</td>
											</tr>
											<tr>
												<td>SG-Numbering-Porting-Ofcom-Management-Admin-Prod</td>
												<td>Add and update records for communication provider and ofcom ranges</td>
											</tr>
											<tr>
												<td>SG-Numbering-Porting-Console-PortPrefix-SingleAdmin-Prod</td>
												<td>Add and update single records for port prefix</td>
											</tr>
											<tr>
												<td>SG-Numbering-Porting-Ofcom-Management-Admin-Prod</td>
												<td>(Not in use yet) For future add and update bulk port prefix records</td>
											</tr>
										</tbody>
									</table>
								</li>
								<li>
									In the <strong>&apos;Changes required?&apos;</strong> field, specify your <strong>Gamma email address</strong>
								</li>
							</ol>
						</div>
					</div>
					<div className='mt-6 prose dark:prose-invert prose-indigo prose-lg mx-auto'>
						<h2 id='using-console'>Using the Console</h2>
						<div>
							<h3 id='home-page'>Home Page</h3>
							<p>The Home Page of the console provides a summarised view of Communication Providers and Port Agreements.</p>
							{getFigure(assets.HomePage, 'Home Page')}
						</div>

						<div>
							<h3 id='commprov-page'>Communication Providers Page</h3>
							<p>
								The Communication Providers page allows for <strong>adding</strong>, <strong>searching</strong>, <strong>viewing</strong>{' '}
								and <strong>editing</strong> Communication Providers to and from Gamma&apos;s database.
							</p>
							{getFigure(assets.CommProv, 'Communication Providers Page')}

							<h4 id='commprov-search'>Searching for a Communication Provider</h4>
							<p>A communication provider can be searched for by its name or CUPID value.</p>
							{getFigure(assets.CommProvSearch, 'Communication Providers Search Screenshot', 'Searching for Vodafone by its CUPID value.')}

							<h4 id='commprov-details'>Viewing Communication Provider Information</h4>
							<p>
								Selecting a communication provider from the list will display its information in a neatly formatted slideout. Within this
								you can view key details about it as well as any children it may have and the edit history of the communication provider.
							</p>
							{getFigure(
								assets.CommProvDetails,
								'Communication Providers Details Screenshot',
								'Information about BT displayed in a slideout.',
							)}

							<h4 id='commprov-edit'>Editing a Communication Provider</h4>
							<p>
								Given that you have been added to the
								<strong> SG-Numbering-Porting-Ofcom-Management-Admin-Prod</strong> security group, you will see an <strong>Edit</strong>{' '}
								button within the Communication Provider information slideout. Clicking this will let you update any information about the
								Communication Provider.
							</p>
							<p>
								Any changes saved here will be propagated to the Gamma database and be shown as part of the edit history for this
								Communication Provider.
							</p>
							{getFigure(
								assets.CommProvEdit,
								'Communication Provider Edit Screenshot',
								'Edit form shown for BT after selecting the Edit button.',
							)}

							<h4 id='commprov-add'>Adding a new Communication Provider</h4>
							<p>
								Given that you have been added to the
								<strong> SG-Numbering-Porting-Ofcom-Management-Admin-Prod</strong>security group, selecting the <strong>...</strong> button
								under the search bar will give you the option to <strong>Add Provider</strong>.
							</p>
							{getFigure(
								assets.CommProvAddButton,
								'Communication Provider Add Button Screenshot',
								'Add Provider option on Communication Provider page',
							)}

							<p>
								Selecting the option will open up a slideout form allowing you to fill in the details of a new Communication Provider.
								Saving it will persist it to the Gamma database and the created Communication Provider will appear in search.
							</p>
							{getFigure(assets.CommProvAdd, 'Communication Provider Add Form Screenshot', 'Add form shown for Communication Provider.')}
						</div>

						<div>
							<h3 id='ranges-page'>Number Ranges Page</h3>
							<p>
								The Number Ranges page allows for <strong>adding</strong>, <strong>searching</strong>, <strong>viewing</strong> and{' '}
								<strong>editing</strong> Number Ranges to and from Gamma&apos;s database.
							</p>
							{getFigure(assets.Ranges, 'Number Ranges Page Screenshot')}

							<h4 id='range-search'>Searching for a Range</h4>
							<p>
								A Range can be searched for by its <strong>Number Range</strong> value (without the +44) or <strong>Range Holder</strong>{' '}
								value
							</p>
							{getFigure(assets.RangesSearch, 'Number Ranges Search Screenshot', "Searching for a Range by it's Number Range value.")}
							<h5 className='text-gamma-digital'>Advanced Search</h5>
							<p>
								Enabling Advanced Search next to the Search button allows you to specify the <strong>Number Range</strong>,{' '}
								<strong>Status </strong>
								and <strong>Range Holder</strong>
								to search for Ranges.
							</p>
							{getFigure(
								assets.RangesAdvancedSearch,
								'Number Ranges Advanced Search Screenshot',
								'Searching for a Range beginning with 11311, with a status of Allocated belonging to Vodafone.',
							)}

							<h4 id='range-details'>Viewing Number Range Information</h4>
							<p>
								Selecting a Number Range from the table will display its information in a neatly formatted slideout. Within this you can
								view key details about it as well as the edit history of the Number Range.
							</p>
							{getFigure(assets.RangesDetails, 'Number Range Details Screenshot', 'Information about BT displayed in a slideout.')}

							<h4 id='range-expand-contract'>Expanding and Contracting a Number Range</h4>
							<p>
								Given that you have been added to the
								<strong> SG-Numbering-Porting-Ofcom-Management-Admin-Prod</strong> security group, you will see buttons to{' '}
								<strong> Expand</strong> and <strong>Contract</strong> the Number Range.
							</p>

							<h5 className='text-gamma-digital'>Expanding a Number Range</h5>
							<p>
								Expanding a Number Range will split it into 10 different sub ranges with the suffixes from 0 to 9. A reason must be provided
								for expanding the range.
							</p>
							<p>
								The new expanded ranges will be saved to the Gamma database and will appear in searches within the agent console, the
								original range will not.
							</p>
							<p>The screenshot below shows an example of how a range would be expanded.</p>
							{getFigure(assets.RangesExpand, 'Number Range Expand Screenshot', 'Expand modal shown for a Number Range.')}

							<h5 className='text-gamma-digital'>Contracting a Number Range</h5>
							<p>
								Contracting a Number Range will combine it and the neighbouring 9 ranges ending in 0-9 into their parent range. A reason
								must be provided for contracting the range.
							</p>
							<p>
								The new contracted range will be saved to the Gamma database and will appear in searches within the agent console, the
								original ranges will not.
							</p>
							<p>The screenshot below shows an example of how a range would be contracted.</p>
							{getFigure(assets.RangesContract, 'Number Range Contract Screenshot', 'Contract modal shown for a Number Range.')}

							<h4 id='range-edit'>Editing a Number Range</h4>
							<p>
								Given that you have been added to the
								<strong> SG-Numbering-Porting-Ofcom-Management-Admin-Prod</strong> security group, you will see an <strong>Edit</strong>{' '}
								button within the Number Range information slideout. Clicking this will let you update any information about the Number
								Range.
							</p>
							<p>
								Any changes saved here will be propagated to the Gamma database and be shown as part of the edit history for this Number
								Range.
							</p>
							{getFigure(assets.RangesEdit, 'Number Range Edit Screenshot', 'Edit form shown for a Number Range.')}

							<h4 id='range-add'>Adding a Number Range</h4>
							<p>
								Given that you have been added to the
								<strong> SG-Numbering-Porting-Ofcom-Management-Admin-Prod</strong>security group, selecting the <strong>...</strong> button
								under the search bar will give you the option to <strong>Add Range</strong>.
							</p>
							{getFigure(assets.RangesAddButton, 'Ranges Add Button Screenshot', 'Add Range option on Number Ranges page.')}
							<p>
								Selecting the option will open up a slideout form allowing you to fill in the details of a new Number Range. Saving it will
								persist it to the Gamma database and the created Number Range will appear in search.
							</p>
							{getFigure(assets.RangesAdd, 'Number Ranges Add Form Screenshot', 'Form to add a new Number Range.')}
						</div>
						<div>
							<h3 id='pa-page' data-testid={'pa-page'}>
								Port Agreements Page
							</h3>
							<p>
								The Port Agreements page allows for <strong>adding</strong>, <strong>deleting</strong>, <strong>searching</strong>,{' '}
								<strong>viewing</strong> and <strong>editing</strong> Port Agreements to and from Gamma&apos;s database.
							</p>
							{getFigure(assets.PortAgreements, 'Port Agreements Page Screenshot')}

							<h4 id='pa-search'>Searching for Port Agreements</h4>
							<p>
								A Port Agreement can be searched for by its <strong>Number Range</strong> value (without the +44) or the{' '}
								<strong>Communication Provider</strong> it belongs to.
							</p>
							{getFigure(
								assets.PortAgreementsSearch,
								'Port Agreements Search Screenshot',
								"Searching for a Port Agreement by it's Number Range value.",
							)}

							<h4 id='pa-details'>Viewing Port Agreement Information</h4>
							<p>
								Selecting a Port Agreement from the table will display its information in a neatly formatted slideout. Within this you can
								view key details about it as well as the edit history of the Port Agreement.
							</p>
							{getFigure(
								assets.PortAgreementsDetails,
								'Port Agreement Details Screenshot',
								'Details of a Port Agreement displayed in a slideout.',
							)}

							<h4 id='pa-edit'>Editing a Port Agreement</h4>
							<p>
								Given that you have been added to the
								<strong> SG-Numbering-Porting-Console-PortPrefix-SingleAdmin-Prod</strong> security group, you will see an{' '}
								<strong>Edit</strong> button within the Port Agreement information slideout. Clicking this will let you update any
								information about the Port Agreement.
							</p>
							<p>
								Any changes saved here will be propagated to the Gamma database and be shown as part of the edit history for this Port
								Agreement.
							</p>
							{getFigure(assets.PortAgreementsEdit, 'Port Agreements Edit Screenshot', 'Edit form shown for a Port Agreement.')}
							<h5 className='text-gamma-digital'>Attaching PDIs</h5>
							<p>
								PDI files contain information about which numbers ranges are able to ported from different communication providers, and can
								be attached to a Porting Agreement within the edit form for it.
							</p>
							<p>
								PDI files must be of the format <strong>.xls</strong>, <strong>.xlsx</strong> or <strong>.pdf</strong>.
							</p>

							<h4 id='pa-add'>Adding a Port Agreement</h4>
							<p>
								Given that you have been added to the
								<strong> SG-Numbering-Porting-Console-PortPrefix-SingleAdmin-Prod</strong> security group, selecting the{' '}
								<strong>...</strong> button under the search bar will give you the option to <strong>Add Agreement</strong>.
							</p>
							{getFigure(
								assets.PortAgreementsAddButton,
								'Port Agreements Add Button Screenshot',
								'Add Agreement option on Port Agreements page.',
							)}
							<p>
								Selecting the option will open up a slideout form allowing you to fill in the details of a new Port Agreement. Saving it
								will persist it to the Gamma database and the created Port Agreement will appear in search.
							</p>
							{getFigure(assets.PortAgreementsAdd, 'Form to add a new Port Agreement.', 'Form to add a new Port Agreement.')}
						</div>
					</div>
					<div className='mt-6 prose dark:prose-invert prose-indigo prose-lg mx-auto'>
						<h2 id='contact-us'>Contact Us</h2>
						<p>
							If you are facing any problems, please raise a ticket describing the issue on the{' '}
							<a className='text-gamma-digital' href={noirBoardLink} target='_blank' rel='noopener noreferrer'>
								Dragon NOIR board
							</a>
							.
						</p>
						<p>We will aim to resolve it as soon as possible.</p>
					</div>
					<div className='mt-6 prose dark:prose-invert prose-indigo prose-lg mx-auto'>
						<h2 id='faqs'>FAQs</h2>
						<ul>
							<li>
								<blockquote>
									<p>Why do the PDI files always open in my internet browser?</p>
								</blockquote>
								<p>
									This problem occurs if you are using Microsoft Edge as your internet browser. You can disable this behaviour by navigating
									to <strong>Settings &#10140; Downloads </strong>
									and disable the option shown below:
								</p>
								{getFigure(DisableOfficeFiles, 'MS Edge Disabling Office Files In Browser Screenshot')}
							</li>
						</ul>
					</div>
				</div>
			</main>
			<ScrollTop />
		</>
	);
};

export default Support;
