import React from 'react';
import { PortAgreementCountTable, ProviderStatCards } from './home-components';

const AuthenticatedLanding: React.FC = () => (
	<main className='mt-24 pb-8'>
		<div className='mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8'>
			<h1
				className='text-2xl tracking-tight font-extrabold sm:text-3xl md:text-3xl lg:text-4xl xl:text-4xl -mt-16 mb-6'
				data-testid='authenticated-welcome'
			>
				Welcome to <span className='text-gamma-digital'>Numbering and Porting Agent Console</span>
			</h1>
			{/* Main 3 column grid */}
			<div className='grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8'>
				{/* Left column */}
				<div className='grid grid-cols-1 gap-4 lg:col-span-2 border-gamma-digital rounded-lg border-2'>
					<section aria-labelledby='section-1-title'>
						<h2 className='sr-only' id='section-1-title'>
							Communication Providers
						</h2>
						<div className='overflow-hidden rounded-lg bg-white dark:bg-gray-800 shadow'>
							<div className='p-6'>
								<ProviderStatCards />
							</div>
						</div>
					</section>
				</div>

				{/* Right column */}
				<div className='grid grid-cols-1 gap-4 border-gamma-digital rounded-lg border-2'>
					<section aria-labelledby='section-2-title'>
						<h2 className='sr-only' id='section-2-title'>
							Port Agreements
						</h2>
						<div className='overflow-hidden rounded-lg bg-white dark:bg-gray-800 shadow'>
							<div className='p-6'>
								<PortAgreementCountTable />
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	</main>
);

export default AuthenticatedLanding;
