import classNames from 'classnames';
import React, { Fragment } from 'react';
import toast, { Toast } from 'react-hot-toast';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon, XCircleIcon, InformationCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';

import type { FC } from 'react';

export type GammaToastType = 'default' | 'gamma' | 'success' | 'info' | 'warning' | 'error';

interface GammaToastProps {
	hotToast: Toast;
	title?: string;
	message: string;
	type?: GammaToastType;
	includeIcon?: boolean;
}

const backgroundColorClasses: Record<GammaToastType, string> = {
	default: 'bg-white dark:bg-gray-700',
	gamma: 'bg-gamma-digital',
	success: 'bg-green-300',
	info: 'bg-blue-300',
	warning: 'bg-yellow-300',
	error: 'bg-red-300',
};

const titleColorClasses: Record<GammaToastType, string> = {
	default: 'text-black dark:text-white',
	gamma: 'text-white',
	success: 'text-green-900',
	info: 'text-blue-900',
	warning: 'text-yellow-900',
	error: 'text-red-900',
};

const messageColorClasses: Record<GammaToastType, string> = {
	default: 'text-black dark:text-white',
	gamma: 'text-white',
	success: 'text-green-700',
	info: 'text-blue-700',
	warning: 'text-yellow-700',
	error: 'text-red-700',
};

const icons: Record<GammaToastType, JSX.Element> = {
	default: <InformationCircleIcon aria-hidden='true' className='h-6 w-6 text-black dark:text-white' />,
	gamma: <InformationCircleIcon aria-hidden='true' className='h-6 w-6 text-white' />,
	success: <CheckCircleIcon aria-hidden='true' className='h-6 w-6 text-green-900' />,
	info: <InformationCircleIcon aria-hidden='true' className='h-6 w-6 text-blue-900' />,
	warning: <ExclamationCircleIcon aria-hidden='true' className='h-6 w-6 text-yellow-900' />,
	error: <XCircleIcon aria-hidden='true' className='h-6 w-6 text-red-900' />,
};

const buttonClasses: Record<GammaToastType, string> = {
	default: 'text-indigo-600 hover:text-indigo-500 focus:ring-indigo-500',
	gamma: 'text-white hover:text-gray-100 focus:ring-gray-100',
	success: 'text-green-600 hover:text-green-500 focus:ring-green-500',
	info: 'text-blue-600 hover:text-blue-500 focus:ring-blue-500',
	warning: 'text-yellow-600 hover:text-yellow-500 focus:ring-yellow-500',
	error: 'text-red-600 hover:text-red-500 focus:ring-red-500',
};

const GammaFeedbackToast: FC<GammaToastProps> = ({
	hotToast,
	message,
	title = undefined,
	type = 'default',
	includeIcon = true,
}): JSX.Element => {
	const icon = includeIcon ? icons[type] : undefined;

	return (
		<Transition
			as={Fragment}
			show={hotToast.visible}
			enter='transform ease-out duration-300 transition'
			enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
			enterTo='translate-y-0 opacity-100 sm:translate-x-0'
			leave='transition ease-in duration-100'
			leaveFrom='opacity-100'
			leaveTo='opacity-0'
		>
			<div
				className={classNames(
					'max-w-md w-full shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black dark:ring-white ring-opacity-5',
					backgroundColorClasses[type],
				)}
			>
				<div className='w-0 flex-1 p-4'>
					<div className='flex items-start'>
						{icon ? (
							<div className='flex-shrink-0 pt-0.5' data-testid='gamma-toast-icon'>
								{icon}
							</div>
						) : null}
						<div className='ml-3 w-0 flex-1'>
							{title ? (
								<p data-testid='gamma-toast-title' className={classNames('text-sm font-medium', titleColorClasses[type])}>
									{title}
								</p>
							) : null}
							<p className={classNames('mt-1 text-sm', messageColorClasses[type])} data-testid='gamma-toast-message'>
								{message}
							</p>
						</div>
					</div>
				</div>
				<div className='flex border-l border-gray-400'>
					<button
						data-testid='gamma-toast-dismiss'
						type='button'
						className={classNames(
							'w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium focus:outline-none focus:ring-2',
							buttonClasses[type],
						)}
						onClick={() => toast.dismiss(hotToast.id)}
					>
						Dismiss
					</button>
				</div>
			</div>
		</Transition>
	);
};

export default GammaFeedbackToast;
