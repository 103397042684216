import React from 'react';
import { EnvelopeIcon, MapPinIcon } from '@heroicons/react/24/outline';

interface TeamMemberProps {
	name: string;
	role: string;
	email: string;
	location: string;
	image?: string;
}

/**
 * Function to render a team member component including name, role, image and additional links
 * @param {TeamMemberProps} props: Required properties to render the component
 * @return {JSX.Element}: A Team Member component for display on the Meet the Team page
 */
const TeamMember = ({ name, role, email, location, image }: TeamMemberProps) => {
	return (
		<div className='space-y-6 xl:space-y-10'>
			<img data-testid='team-member-image' className='mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56' src={image} alt={`${name} ${role}`} />
			<div className='space-y-2 xl:flex xl:items-center xl:justify-between'>
				<div className='font-medium text-lg leading-6 space-y-1'>
					<h2 data-testid='team-member-name'>{name}</h2>
					<p data-testid='team-member-role' className='text-black dark:text-white'>
						{role}
					</p>
				</div>
				<ul className='flex justify-center space-x-5'>
					<li>
						<a data-testid='team-member-email' href={`mailto:${email}`} className='text-black dark:text-white hover:text-gamma-digital'>
							<span className='sr-only'>Email {name}</span>
							<EnvelopeIcon className='w-5 h-5' aria-hidden={true} />
						</a>
					</li>
					<li>
						<span className='text-black dark:text-white flex'>
							<MapPinIcon className='w-5 h-5' aria-hidden={true} />
							<span data-testid='team-member-location'>{location}</span>
						</span>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default TeamMember;
