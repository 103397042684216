import React from 'react';

interface EditButtonProps {
	setEditMode: (m: boolean) => void;
}

const EditButton = ({ setEditMode }: EditButtonProps) => {
	return (
		<button
			type='button'
			className='ml-4 flex h-8 w-14 px-4 py-2 items-center justify-center border border-transparent rounded-md text-sm font-medium shadow-sm bg-gamma-digital text-white hover:bg-gamma-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-gamma-digital'
			onClick={() => setEditMode(true)}
			data-testid='edit-button'
		>
			Edit
		</button>
	);
};

export default EditButton;
