import React from 'react';
import { MoonIcon, SunIcon } from '@heroicons/react/24/solid';
import { useDarkMode } from 'hooks';

const DarkModeToggle: React.FC = () => {
	const { isDarkMode, enable, disable } = useDarkMode();

	return isDarkMode ? (
		<button
			type='button'
			className='bg-purple-dark dark:bg-gray-800 p-1 rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gamma-digital focus:ring-gamma-digital hover:bg-gamma-digital dark:hover:bg-gamma-digital dark:focus:ring-gamma-digital'
			onClick={disable}
		>
			<span data-testid='light-mode' className='sr-only'>
				Toggle Light Mode
			</span>
			<SunIcon className='h-6 w-6' aria-hidden={true} data-testid='toggle-light' />
		</button>
	) : (
		<button
			type='button'
			className='bg-purple-dark dark:bg-gray-800 p-1 rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gamma-digital focus:ring-gamma-digital hover:bg-gamma-digital dark:hover:bg-gamma-digital dark:focus:ring-gamma-digital'
			onClick={enable}
		>
			<span data-testid='dark-mode' className='sr-only'>
				Toggle Dark Mode
			</span>
			<MoonIcon className='h-6 w-6' aria-hidden={true} data-testid='toggle-dark' />
		</button>
	);
};

export default DarkModeToggle;
