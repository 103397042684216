import React from 'react';
import { format } from 'date-fns';
import type { FC } from 'react';
import type { OfcomRangeResponse } from 'types';
import { PagingCards } from 'components';

interface RangeTableProps {
	ranges: OfcomRangeResponse[];
	hasPrevious: boolean;
	hasNext: boolean;
	page: number;
	pageSize: number;
	onPrevious: Function;
	onNext: Function;
	onSelection: (id: number) => Promise<void>;
}

const RangeTable: FC<RangeTableProps> = ({
	ranges,
	hasPrevious,
	hasNext,
	page,
	pageSize,
	onPrevious: onPrevious,
	onNext: onNext,
	onSelection: onSelection,
}): JSX.Element => {
	return (
		<div className='my-4' data-testid='range-result-table'>
			<div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
				<div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
					<div className='overflow-hidden shadow ring-1 ring-black dark:ring-slate-300 ring-opacity-5 md:rounded-lg'>
						<table className='min-w-full divide-y divide-gray-300'>
							<thead className='bg-gray-50 dark:bg-gray-800'>
								<tr>
									<th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-200 sm:pl-6'>
										Number Range
									</th>
									<th
										scope='col'
										className='hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200 sm:table-cell'
									>
										Status
									</th>
									<th
										scope='col'
										className='hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200 lg:table-cell'
									>
										Range Holder
									</th>
									<th
										scope='col'
										className='hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200 lg:table-cell'
									>
										Allocation Date
									</th>
									<th
										scope='col'
										className='hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200 lg:table-cell'
									>
										Last Updated
									</th>
								</tr>
							</thead>
							<tbody className='bg-white dark:bg-gray-600'>
								{ranges.map((range, rangeIdx) => (
									<tr
										key={range.id}
										data-testid='range-result-row'
										className={
											rangeIdx % 2 === 0
												? 'hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600'
												: 'bg-gray-100 hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-600'
										}
										onClick={(e) => {
											e.stopPropagation();
											e.preventDefault();
											onSelection(range.id);
										}}
									>
										<td
											data-testid='range-result-column-mobile'
											className='w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-200 sm:w-auto sm:max-w-none sm:pl-6'
										>
											{`+44${range.numberBlock}`}
											<dl className='font-normal lg:hidden'>
												<dt className='sr-only sm:hidden'>Status</dt>
												<dd className='mt-1 truncate text-gray-700 dark:text-gray-100 sm:hidden'>{range.status}</dd>
												<dt className='sr-only lg:hidden'>Range Holder</dt>
												<dd className='mt-1 truncate text-gray-500 dark:text-gray-50 lg:hidden'>{range.rangeHolder?.name}</dd>
											</dl>
										</td>

										<td className='hidden py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-200 sm:pl-6'>
											{`+44${range.numberBlock}`}
										</td>
										<td className='hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 sm:table-cell'>{range.status}</td>
										<td className='hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 lg:table-cell'>{range.rangeHolder?.name}</td>
										<td className='hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 lg:table-cell'>
											{range.allocationDate ? (
												<time
													dateTime={format(new Date(range.allocationDate), 'dd/MM/yyyy')}
													data-testid={`allocation-${range.numberBlock}`}
												>
													{format(new Date(range.allocationDate), 'dd/MM/yyyy')}
												</time>
											) : null}
										</td>
										<td className='hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 lg:table-cell'>
											<time dateTime={format(new Date(range.lastUpdated), 'dd/MM/yyyy')} data-testid={`lastupdated-${range.numberBlock}`}>
												{format(new Date(range.lastUpdated), 'dd/MM/yyyy')}
											</time>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div className='mt-4'>
				<PagingCards
					hasPrevious={hasPrevious}
					hasNext={hasNext}
					page={page}
					pageSize={pageSize}
					results={ranges.length}
					onPrevious={() => onPrevious()}
					onNext={() => onNext()}
				/>
			</div>
		</div>
	);
};

export default RangeTable;
