import type { PortAgreementItem, PortAgreementHistory, PortAgreementSearch } from 'types';
import { apiConfig } from 'auth-config';
import { createRequestHeader } from 'utils';

const baseUrl = apiConfig.ofcomApi.baseUrl;

export interface PortAgreementEditInput {
	numberBlock?: string;
	agreementHolder?: string;
	portPrefix: string;
	pdi?: File;
	changeReason?: string;
}

/**
 * Port Agreement search using a combination of number range and communication provider
 * @param {string} token: Access token for API requests
 * @param {PortAgreementSearch} searchRequest: An TS object that wraps up the possible query parameters for the search
 * @param {number} page: the page of results to return
 * @param {number} pageSize: the number of results in the page
 * @return {Promise<PortAgreementItem[]>}: List of Port Agreement Items matching the supplied criteria
 */
export async function searchPortAgreements(
	token: string,
	searchRequest: PortAgreementSearch,
	page: number,
	pageSize: number,
): Promise<PortAgreementItem[]> {
	// construct URL based on input
	let searchUrl: string = `${baseUrl}/portagreement?`;
	let appendAnd: boolean = false;

	if (searchRequest.startDigits) {
		searchUrl = searchUrl + `startDigits=${searchRequest.startDigits}`;
		appendAnd = true;
	}
	if (searchRequest.cupid) {
		searchUrl = searchUrl + `${appendAnd ? '&' : ''}` + `cupid=${searchRequest.cupid}`;
		appendAnd = true;
	}

	const endpoint: string = searchUrl + `${appendAnd ? '&' : ''}` + `page=${page}&perPage=${pageSize}`;
	return await (await fetch(endpoint, createRequestHeader(token, 'GET'))).json();
}

/**
 * Get the port agreement with the given id
 * @param {string} token: Access token for API requests
 * @param {number} agreementId: ID of the Port Agreement item to find
 * @return {Promise<PortAgreementItem>} Agreement Item with the given Id
 */
export async function getPortAgreementItem(token: string, agreementId: number): Promise<PortAgreementItem> {
	const url = `${baseUrl}/portagreement/${agreementId}`;
	return await (await fetch(url, createRequestHeader(token, 'GET'))).json();
}

/**
 * Get the history of changes for a given port agreement item
 * @param {string} token: Access token for API requests
 * @param {number} agreementId: Id of the port agreement to find
 * @param {number} page: the page of results to return
 * @param {number} perPage: the number of results in the page
 * @return {Promise<PortAgreementHistory[]>}: History of changes for the port agreement item
 */
export async function getPortAgreementItemHistory(
	token: string,
	agreementId: number,
	page?: number,
	perPage?: number,
): Promise<PortAgreementHistory[]> {
	const historyUrl = `${baseUrl}/portagreement/${agreementId}/history`;
	const endpoint: string = page && perPage ? historyUrl + `?page=${page}&perPage=${perPage}` : historyUrl;
	return await (await fetch(endpoint, createRequestHeader(token, 'GET'))).json();
}

/**
 * Update the information associated for the port agreement with the given agreementId.
 * @param {string} token: Access token for API requests
 * @param {number} agreementId: Id of the port agreement to update
 * @param {PortAgreementEditInput} updates:
 * @return {Promise<boolean>}: true if request is successful, false if unsuccessful
 */
export async function updatePortAgreementItem(token: string, agreementId: number, updates: PortAgreementEditInput): Promise<Response> {
	const url = `${baseUrl}/portagreement/${agreementId}`;
	const requestBody = new FormData();
	updates.pdi && requestBody.append('pdi', updates.pdi);
	const inputs = {
		numberBlocks: [updates.numberBlock],
		agreementHolderCupid: updates.agreementHolder,
		portPrefix: updates.portPrefix,
		changeReason: updates.changeReason,
	};
	const jsonBlob = new Blob([JSON.stringify(inputs)], {
		type: 'application/json',
	});
	requestBody.append('values', jsonBlob);
	return await fetch(url, {
		method: 'PATCH',
		body: requestBody,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Add the following port agreements
 * @param {string} token: Access token for API requests
 * @param {PortAgreementEditInput} creates:
 * @return {Promise<boolean>}: true if request is successful, false if unsuccessful
 */
export async function addPortAgreements(token: string, creates: PortAgreementEditInput): Promise<Response> {
	const url = `${baseUrl}/portagreement`;
	const requestBody = new FormData();
	const blocks = creates.numberBlock?.split(/;|,|\n/).map((n) => n.trim());
	creates.pdi && requestBody.append('pdi', creates.pdi);
	const inputs = {
		numberBlocks: blocks,
		agreementHolderCupid: creates.agreementHolder,
		portPrefix: creates.portPrefix,
		changeReason: creates.changeReason,
	};
	const jsonBlob = new Blob([JSON.stringify(inputs)], {
		type: 'application/json',
	});
	requestBody.append('values', jsonBlob);
	return await fetch(url, {
		method: 'POST',
		body: requestBody,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

/**
 * Delete a port agreement item
 * @param {string} token: Access token for API requests
 * @param {number} agreementId: Id of the port agreement to remove
 * @return {Promise<boolean>}: true if request is successful, false if unsuccessful
 */
export async function deletePortAgreement(token: string, agreementId: number): Promise<Response> {
	const url = `${baseUrl}/portagreement/${agreementId}`;
	return await fetch(url, {
		method: 'DELETE',
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}
