import { IMsalContext } from '@azure/msal-react';
import { AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import { apiConfig } from '../../auth-config';

export const OFCOM_AGENT_ROLE = 'Agent';
export const OFCOM_DATA_ADMIN_ROLE = 'Ofcom.Data.Admin';
export const PORT_AGREEMENT_SINGLE_ADMIN_ROLE = 'PortAgreement.Single.Admin';
export const PORT_AGREEMENT_BULK_ADMIN_ROLE = 'PortAgreement.Bulk.Admin';
export const OFCOM_API_SCOPES = apiConfig.ofcomApi.scopes;

/**
 * This method retrieves access tokens which are required for API calls.
 *
 * @param {string[]} scopes: scopes required for the api
 * @param {IMsalContext} msal: Msal auth provider
 * @return {Promise<string>}: Access token
 */
export async function getAccessToken(scopes: string[], msal: IMsalContext): Promise<string> {
	let token = '';
	try {
		const response = await msal.instance.acquireTokenSilent({
			scopes: scopes,
			account: msal.instance.getActiveAccount()!,
		});
		token = response.accessToken;
	} catch (error) {
		if (error instanceof InteractionRequiredAuthError) {
			const response = await getAccessTokenFromPopup(scopes, msal);
			token = response.accessToken;
		}
	}
	return token;
}

/**
 * This method is a fallback to an interactive way of retrieving an access token if silent acquire fails.
 *
 * @param {string[]} scopes: scopes required for the api
 * @param {IMsalContext} msal: Msal auth provider
 * @return {Promise<AuthenticationResult>}: Authentication result from MSAL
 */
async function getAccessTokenFromPopup(scopes: string[], msal: IMsalContext): Promise<AuthenticationResult> {
	return msal.instance.acquireTokenPopup({
		scopes: scopes,
	});
}

/**
 * Create the headers with the authorization token and http method required for calls to the API.
 * @param {string} token: Access token for API requests
 * @param {string} httpMethod: Http Method for the request
 * @return {Promise<CommunicationProvider>}: the identified communication provider
 */
export function createRequestHeader(token: string, httpMethod: string): RequestInit {
	const headers = new Headers();
	const bearer = `Bearer ${token}`;

	headers.append('Authorization', bearer);

	return {
		method: httpMethod,
		headers: headers,
	};
}
