import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';

import { StatService } from 'services';
import { ProviderTypeCountStat } from 'types';
import { getAccessToken } from 'utils';
import { apiConfig } from 'auth-config';

const ProviderStatCards: React.FC = () => {
	const [stats, setStats] = useState<ProviderTypeCountStat[]>([]);
	const msal = useMsal();
	const ofcomApiScopes = apiConfig.ofcomApi.scopes;

	useEffect(() => {
		/**
		 * Load the provider stats from Ofcom Reference data API.
		 */
		async function loadProviderStats() {
			const token: string = await getAccessToken(ofcomApiScopes, msal);
			setStats(await StatService.getProviderTypeCounts(token));
		}

		loadProviderStats();
	}, []);

	return (
		<div className='py-2 sm:py-1'>
			<h2 className='text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl'>Communication Providers</h2>
			<p className='mt-4 text-lg leading-8 text-gray-600 dark:text-gray-300'>Supporting porting and hosting partnerships</p>
			<dl className='mt-4 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-3'>
				{stats.map((stat, statIdx) => (
					<div key={statIdx} className='flex flex-col bg-gray-400/5 dark:bg-white/5 p-8'>
						<dt className='text-sm font-semibold leading-6 text-gray-600 dark:text-gray-300'>{stat.type}</dt>
						<dd className='order-first text-3xl font-semibold tracking-tight text-gray-900 dark:text-white'>{stat.count}</dd>
					</div>
				))}
			</dl>
		</div>
	);
};

export default ProviderStatCards;
