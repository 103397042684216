import { useEffect, useState } from 'react';

/**
 * A hook to retrieve media dimensions and responds to onResize events
 * @param {string} query: media query to check
 * @return {boolean}: true if query matches otherwise false
 */
function useMediaQuery(query: string): boolean {
	const getMatches = (query: string): boolean => {
		if (typeof window !== 'undefined') {
			return window.matchMedia(query).matches;
		}

		return false;
	};

	const [matches, setMatches] = useState<boolean>(getMatches(query));

	/**
	 * Event listener function which handles changes due to onReSize
	 */
	function handleChange() {
		setMatches(getMatches(query));
	}

	useEffect(() => {
		const matchMedia = window.matchMedia(query);

		// Triggered at the first client-slide load and if query changes
		handleChange();

		// Listen matchMedia
		matchMedia.addEventListener('change', handleChange);

		return () => {
			matchMedia.removeEventListener('change', handleChange);
		};
	}, [query]);

	return matches;
}

export default useMediaQuery;
