import React from 'react';
import type { FC } from 'react';

interface PagingCardsProps {
	hasPrevious: boolean;
	hasNext: boolean;
	page: number;
	pageSize: number;
	results: number;
	onPrevious: Function;
	onNext: Function;
}

const PagingCards: FC<PagingCardsProps> = ({
	hasPrevious,
	hasNext,
	page,
	pageSize,
	results,
	onPrevious: fetchPreviousPage,
	onNext: fetchNextPage,
}): JSX.Element => {
	return (
		<nav
			className='flex items-center justify-between border-t border-gray-200 bg-white dark:bg-gray-800 px-4 py-3 sm:px-6'
			aria-label='Pagination'
		>
			<div className='hidden sm:block'>
				<p className='text-sm text-gray-700 dark:text-gray-200'>
					{results > 0 ? (
						<span>
							Showing {page * pageSize + 1} to {page * pageSize + results} of results
						</span>
					) : hasNext ? (
						<span /> // shows nothing, no results yet but has more
					) : (
						<span>No more results</span>
					)}
				</p>
			</div>
			<div className='flex flex-1 justify-between sm:justify-end'>
				<button
					disabled={!hasPrevious}
					onClick={() => fetchPreviousPage()}
					className='relative inline-flex items-center rounded-md border border-gray-300 dark:border-slate-300 bg-white dark:bg-gray-600
					px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 disabled:opacity-25'
				>
					Previous
				</button>
				<button
					disabled={!hasNext}
					onClick={() => fetchNextPage()}
					className='relative ml-3 inline-flex items-center rounded-md border border-gray-300 dark:border-slate-300 bg-white dark:bg-gray-600
					px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 disabled:opacity-25'
				>
					Next
				</button>
			</div>
		</nav>
	);
};

export default PagingCards;
