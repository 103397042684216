import React, { ComponentProps } from 'react';

/**
 * Function to render a Gamma icon
 * @param {ComponentProps<'svg'>} props: SVG Attributes
 * @return {JSX.Element}: Gamma Icon JSX Element
 */
export default function GammaIcon(props: ComponentProps<'svg'>): JSX.Element {
	return (
		<svg
			data-testid='gamma-icon'
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			viewBox='0 0 2834.6 2551.2'
			fill='currentColor'
			xmlSpace='preserve'
			{...props}
		>
			<path
				fill='currentColor'
				d='M1621.8,185.5c-104.4,176.7-44.2,401.5,132.5,505.9s401.5,44.2,505.9-132.5s44.2-401.5-132.5-505.9
	C1951-51.4,1726.2,8.8,1621.8,185.5z M369.1,904.2c204.8,0,369.4,164.6,369.4,369.4S573.8,1643,369.1,1643S-0.3,1478.4-0.3,1273.6
	C-0.3,1072.8,164.3,904.2,369.1,904.2z M565.8,546.9c96.4,180.7,321.2,244.9,501.9,148.6s244.9-321.2,148.6-501.9
	C1119.9,12.8,895-51.4,714.4,45S469.4,366.2,565.8,546.9z M2264.2,2004.3c-96.4-180.7-321.2-244.9-501.9-148.6
	c-180.7,96.4-244.9,321.2-148.6,501.9c96.4,180.7,321.2,244.9,501.9,148.6C2296.3,2405.8,2364.6,2185,2264.2,2004.3z M1208.2,2365.7
	c104.4-176.7,44.2-401.5-132.5-505.9c-176.7-104.4-401.5-44.2-505.9,132.5s-44.2,401.5,132.5,505.9S1107.8,2542.3,1208.2,2365.7z
	 M1678,1012.6c-68.3-68.3-160.6-108.4-261-108.4c-204.8,0-369.4,164.6-369.4,369.4S1212.2,1643,1417,1643
	c100.4,0,196.7-40.2,261-108.4l0,0c184.7-184.7,341.3-184.7,526,0l0,0c68.3,68.3,160.6,108.4,261,108.4
	c204.8,0,369.4-164.6,369.4-369.4s-164.6-369.4-369.4-369.4c-100.4,0-196.7,40.2-261,108.4l0,0
	C2019.3,1197.3,1862.7,1197.3,1678,1012.6L1678,1012.6z'
			/>
		</svg>
	);
}
