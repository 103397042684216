import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import type { FC } from 'react';
import Select from 'react-select';
import { apiConfig } from 'auth-config';
import { OfcomRangeService, RangeHolderService } from 'services';
import type { OfcomRangeResponse, Option, RangeAdvancedSearchInput, RangeStatus } from 'types';
import { getAccessToken } from 'utils';
import RangeTable from './range-table';
import { RangeActionsDropdown, SearchTypeToggle } from './range-components';

interface AdvancedSearchProps {
	setRanges: Dispatch<SetStateAction<OfcomRangeResponse[]>>;
	getRanges: () => OfcomRangeResponse[];
	onRangeSelection: (rangeId: number) => Promise<void>;
	setAdvancedSearch: Dispatch<SetStateAction<boolean>>;
	pageSize?: number;
	onAddRange: () => Promise<void>;
}

const AdvancedSearch: FC<AdvancedSearchProps> = ({
	setRanges,
	getRanges,
	onRangeSelection,
	setAdvancedSearch,
	pageSize = 5,
	onAddRange,
}): JSX.Element => {
	const [numberRange, setNumberRange] = useState('');
	const [status, setStatus] = useState('');
	const [rangeHolder, setRangeHolder] = useState('');

	const msal = useMsal();
	const [page, setPage] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const ofcomApiScopes = apiConfig.ofcomApi.scopes;

	const handleNumberRangeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		e.preventDefault();
		setNumberRange(e.target.value);
	};

	const handleAdvancedSearch = (e: React.MouseEvent<HTMLButtonElement>): void => {
		e.preventDefault();

		setPage(0);
		fetchRanges(numberRange, status, rangeHolder, 0);
	};

	const fetchRanges = async (numberRange: string, rangeStatus: string, rangeHolder: string, page: number) => {
		const token = await getAccessToken(ofcomApiScopes, msal);

		// build search input
		const searchRequest: RangeAdvancedSearchInput = {
			startDigits: numberRange && numberRange !== '' ? numberRange.replace(/^0+/, '') : undefined,
			status: rangeStatus && rangeStatus !== '' ? (rangeStatus as RangeStatus) : undefined,
			cupid: rangeHolder && rangeHolder !== '' && rangeHolder.startsWith('C-') ? rangeHolder.replace('C-', '').trim() : undefined,
			ofcomRangeHolderId:
				rangeHolder && rangeHolder !== '' && rangeHolder.startsWith('N-') ? Number(rangeHolder.replace('N-', '').trim()) : undefined,
		};

		OfcomRangeService.advancedSearchRanges(token, searchRequest, page, pageSize).then((r) => {
			setRanges(r);
			setHasMore(!(r.length < pageSize));
		});
	};

	const nextPage = () => {
		if (hasMore) {
			const newPage = page + 1;
			setPage(newPage);
			fetchRanges(numberRange, status, rangeHolder, newPage);
		}
	};

	const previousPage = () => {
		if (page > 0) {
			const newPage = page - 1;
			setPage(newPage);
			fetchRanges(numberRange, status, rangeHolder, newPage);
		}
	};

	return (
		<>
			<form className='space-y-8'>
				<div className='space-y-8'>
					<div>
						<div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 lg:w-1/2'>
							{/* Number Range Input */}
							<div className='sm:col-span-4'>
								<label htmlFor='number-range' className='block text-sm font-medium text-gray-800 dark:text-white'>
									Number Range
								</label>
								<div className='mt-1 flex rounded-md shadow-sm'>
									<span className='inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-300 px-3 text-gray-600 sm:text-sm dark:bg-gray-700 dark:text-white'>
										+44
									</span>
									<input
										type='text'
										name='numberRange'
										id='number-range'
										value={numberRange}
										onChange={(e) => handleNumberRangeChange(e)}
										className='block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-gamma-digital focus:ring-gamma-digital sm:text-sm dark:text-white dark:bg-gray-800'
									/>
								</div>
							</div>
							{/* Range Status Input */}
							<div className='sm:col-span-4'>
								<label htmlFor='status' className='block text-sm font-medium text-gray-800 dark:text-white'>
									Status
								</label>
								<div className='mt-1'>
									<StatusDropDown id='status' setValue={setStatus} />
								</div>
							</div>
							{/* Communication Provider Input */}
							<div className='sm:col-span-4'>
								<label htmlFor='rangeHolder' className='block text-sm font-medium text-gray-800 dark:text-white'>
									Communication Provider
								</label>
								<div className='mt-1'>
									<RangeHolderDropDown id='rangeHolder' setValue={setRangeHolder} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='pt-5 pb-5'>
					<div className='flex justify-end lg:justify-start'>
						<SearchTypeToggle advancedSearch={true} setAdvancedSearch={setAdvancedSearch} />
						<button
							type='submit'
							data-testid='advanced-search-button'
							className='ml-3 inline-flex justify-center rounded-md border border-transparent bg-gamma-digital py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gamma-hover focus:outline-none focus:ring-2 focus:ring-gamma-digital focus:ring-offset-2'
							onClick={(e) => handleAdvancedSearch(e)}
						>
							Search
						</button>
					</div>
				</div>
			</form>

			<RangeActionsDropdown onAddRange={onAddRange} />

			{/* Results selection */}
			<RangeTable
				ranges={getRanges()}
				hasPrevious={page > 0}
				hasNext={hasMore}
				page={page}
				pageSize={pageSize}
				onPrevious={() => previousPage()}
				onNext={() => nextPage()}
				onSelection={onRangeSelection}
			/>
		</>
	);
};

export default AdvancedSearch;

interface DropDownProps {
	id: string;
	setValue: Dispatch<SetStateAction<string>>;
}

/**
 * Simple function that returns a searchable status drop down
 * @param {DropDownProps}: drop down props
 * @return {JSX.Element}: Range Status drop down for searching
 */
function StatusDropDown({ id, setValue }: DropDownProps): JSX.Element {
	const statuses = [] as Option[];
	// add statues to array
	statuses.push({
		label: 'Any',
		value: '',
	});
	statuses.push({
		label: 'Allocated',
		value: 'ALLOCATED',
	});
	statuses.push({
		label: 'Allocated (Closed Range)',
		value: 'ALLOCATED_CLOSE_RANGE',
	});
	statuses.push({
		label: 'Quarantined',
		value: 'QUARANTINED',
	});
	statuses.push({
		label: 'Free',
		value: 'FREE',
	});
	statuses.push({
		label: 'Designated',
		value: 'DESIGNATED',
	});
	statuses.push({
		label: 'Protected',
		value: 'PROTECTED',
	});
	statuses.push({
		label: 'Reserved',
		value: 'RESERVED',
	});
	statuses.push({
		label: 'Free for National Dialing Only',
		value: 'FREE_NATIONAL_DIALING',
	});

	// build select
	return (
		<div className='block w-full rounded-md border-gray-300 shadow-sm focus:border-gamma-digital focus:ring-gamma-digital sm:text-sm'>
			<Select
				aria-labelledby={id}
				id={id}
				options={statuses}
				inputId={id}
				isMulti={false}
				isDisabled={false}
				name={id}
				onChange={(newValue) => {
					const selectedOption = newValue as Option;
					setValue(selectedOption.value);
				}}
				className='searchable-select-container'
				classNamePrefix='searchable-select'
			/>
		</div>
	);
}

/**
 * Simple function that returns a searchable range holder drop down
 * @param {DropDownProps}: drop down props
 * @return {JSX.Element}: Range Holder drop down for searching
 */
function RangeHolderDropDown({ id, setValue }: DropDownProps): JSX.Element {
	const [rangeHolders, setRangeHolders] = useState<Option[]>([] as Option[]);
	const msal = useMsal();
	const ofcomApiScopes = apiConfig.ofcomApi.scopes;

	useEffect(() => {
		const initialise = async () => {
			const token = await getAccessToken(ofcomApiScopes, msal);
			const holders = await RangeHolderService.getRangeHolderList(token);

			const options = [] as Option[];
			options.push({
				label: 'Any',
				value: '',
			});

			holders.forEach((item) => {
				if (item.type == 'NONCUPID') {
					// Range Holder without CUPID
					options.push({
						label: item.name,
						value: `N-${item.rangeHolderId}`,
					});
				} else {
					// Range Holder with CUPID
					options.push({
						label: `(${item.cupid}) ${item.name}`,
						value: `C-${item.cupid}`,
					});
				}
			});

			setRangeHolders(options);
		};

		initialise();
	}, []);

	return (
		<div className='block w-full rounded-md border-gray-300 shadow-sm focus:border-gamma-digital focus:ring-gamma-digital sm:text-sm'>
			<Select
				aria-labelledby={id}
				id={id}
				options={rangeHolders}
				inputId={id}
				isMulti={false}
				isDisabled={false}
				name={id}
				onChange={(newValue) => {
					const selectedOption = newValue as Option;
					setValue(selectedOption.value);
				}}
				className='searchable-select-container'
				classNamePrefix='searchable-select'
			/>
		</div>
	);
}
