import React, { Dispatch, SetStateAction } from 'react';
import { OfcomRangeResponse } from 'types';
import { format } from 'date-fns';
import { Tab } from '@headlessui/react';
import { OfcomRangeService } from 'services';
import { HistoryTab } from 'components';
import { EditButton } from 'components';
import classNames from 'classnames';
import { useHasRole } from 'hooks';
import { OFCOM_DATA_ADMIN_ROLE } from 'utils';

interface RangeViewProps {
	range: OfcomRangeResponse;
	openExpandDialogFunction: (range: OfcomRangeResponse) => void;
	openContractDialogFunction: (range: OfcomRangeResponse) => void;
	setEditMode: Dispatch<SetStateAction<boolean>>;
}

const RangeView = ({ range, openExpandDialogFunction, openContractDialogFunction, setEditMode }: RangeViewProps) => {
	const hasAdminRole = useHasRole(OFCOM_DATA_ADMIN_ROLE);
	const getClassfication = (numberBlock: string): string => {
		let classification = '';
		numberBlock = `0${numberBlock}`;
		switch (true) {
			case numberBlock.startsWith('01'):
			case numberBlock.startsWith('02'):
				classification = 'Geographic';
				break;
			case numberBlock.startsWith('03'):
			case numberBlock.startsWith('08'):
			case numberBlock.startsWith('090'):
			case numberBlock.startsWith('091'):
			case numberBlock.startsWith('098'):
				classification = 'Non-geographic';
				break;
			case numberBlock.startsWith('055'):
			case numberBlock.startsWith('056'):
				classification = 'Corporate';
				break;
			case numberBlock.startsWith('070'):
				classification = 'Personal';
				break;
			case numberBlock.startsWith('076'):
				classification = 'Radiopaging Service';
				break;
			case numberBlock.startsWith('07'):
				classification = 'Mobile';
				break;
			default:
				classification = 'N/A';
				break;
		}
		return classification;
	};

	const getGridCols = (): string => {
		return `grid-cols-${Math.ceil((range.numberBlock.length - 2) / 2)}`;
	};

	const isRangeExpandable = (block: string) => {
		return block.length < 8;
	};

	const isRangeContractable = (block: string) => {
		return block.length > 4;
	};

	return (
		<div data-testid='range-view' className='h-full overflow-y-auto p-4'>
			<div className='space-y-1 pb-4'>
				<div>
					<div className='flex items-start justify-between'>
						<div>
							<p className='text-sm font-medium text-gray-500'>
								Last Update{' '}
								<time dateTime={format(new Date(range.lastUpdated), 'dd/MM/yyyy')} data-testid='last-updated'>
									{format(new Date(range.lastUpdated), 'dd/MM/yyyy')}
								</time>
							</p>
						</div>
						<div className='flex items-start justify-between'>
							{hasAdminRole ? <EditButton setEditMode={setEditMode} /> : null}

							{hasAdminRole && isRangeExpandable(range.numberBlock) ? (
								<button
									type='button'
									className='ml-4 flex h-8 w-16 px-4 py-2 items-center justify-center border border-transparent rounded-md text-sm font-medium shadow-sm bg-gamma-digital text-white hover:bg-gamma-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-gamma-digital'
									data-testid='expand-button'
									onClick={() => openExpandDialogFunction(range)}
								>
									Expand
								</button>
							) : null}
							{hasAdminRole && isRangeContractable(range.numberBlock) ? (
								<button
									type='button'
									className='ml-4 flex h-8 w-16 px-4 py-2 items-center justify-center border border-transparent rounded-md text-sm font-medium shadow-sm bg-gamma-digital text-white hover:bg-gamma-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-gamma-digital'
									data-testid='contract-button'
									onClick={() => openContractDialogFunction(range)}
								>
									Contract
								</button>
							) : null}
						</div>
					</div>
				</div>
			</div>
			<div>
				<h3 className='font-medium text-gray-800 dark:text-gray-200'>Information</h3>
				<dl className='mt-2 divide-y divide-gray-200 border-t border-b border-gray-200'>
					<div className='flex justify-between py-3 text-sm font-medium'>
						<dt className='text-gray-500'>Classification</dt>
						<dd data-testid='classification'>{getClassfication(range.numberBlock)}</dd>
					</div>
					<div className='flex justify-between py-3 text-sm font-medium'>
						<dt className='text-gray-500'>Range Size </dt>
						<dd data-testid='range-size'>{range.blockSize}</dd>
					</div>
					<div className={classNames('divide-x divide-gray-200 border-t border-b border-gray-200 grid', getGridCols())}>
						<div className='flex justify-self-stretch justify-between py-3 text-sm font-medium col-start-1 col-span-1'>
							<dt className='text-gray-500'>SABC</dt>
							<dd className={`${range.numberBlock.length > 4 && 'pr-4'}`} data-testid='sabc'>
								{range.numberBlock.substring(0, 4)}
							</dd>
						</div>
						{range.numberBlock.length > 4 && (
							<div className='flex justify-self-stretch justify-between py-3 text-sm font-medium col-start-2 col-span-1'>
								<dt className='pl-4 text-gray-500'>DE</dt>
								<dd className={`${range.numberBlock.length > 6 && 'pr-4'}`} data-testid='de'>
									{range.numberBlock.substring(4, range.numberBlock.length > 6 ? 6 : range.numberBlock.length)}
								</dd>
							</div>
						)}
						{range.numberBlock.length > 6 && (
							<div className='flex justify-self-stretch justify-between py-3 text-sm font-medium col-start-3 col-span-1'>
								<dt className='pl-4 text-gray-500'>FG</dt>
								<dd data-testid='fg'>{range.numberBlock.length > 6 ? range.numberBlock.substring(6, range.numberBlock.length) : ''}</dd>
							</div>
						)}
					</div>
					<div className='flex justify-between py-3 text-sm font-medium'>
						<dt className='text-gray-500'>Range Holder</dt>
						<dd data-testid='communication-provider'>{range.rangeHolder?.name || ''}</dd>
					</div>
					<div className='flex justify-between py-3 text-sm font-medium'>
						<dt className='text-gray-500'>Hosting Provider</dt>
						<dd data-testid='hosting-provider'>{range.hostingProvider?.name || ''}</dd>
					</div>
					<div
						className={`border-t border-b border-gray-200 ${
							range.status.includes('Allocated') && 'grid grid-cols-2 divide-x divide-gray-200'
						}`}
					>
						<div className='flex justify-self-stretch justify-between py-3 text-sm font-medium'>
							<dt className='text-gray-500'>Status</dt>
							<dd className={`${range.status.includes('Allocated') && 'pr-4'}`} data-testid='status'>
								{range.status}
							</dd>
						</div>
						{range.status.includes('Allocated') && range.allocationDate && (
							<div className='flex justify-self-stretch justify-between py-3 text-sm font-medium'>
								<dt className='pl-4 text-gray-500'>Allocation Date</dt>
								<dd>
									<time dateTime={format(new Date(range.allocationDate), 'dd/MM/yyyy')} data-testid='allocation-date'>
										{format(new Date(range.allocationDate), 'dd/MM/yyyy')}
									</time>
								</dd>
							</div>
						)}
					</div>
					<div className='flex justify-between py-3 text-sm font-medium'>
						<dt className='text-gray-500'>Number Length</dt>
						<dd data-testid='number-length'>{range.numberLength.format}</dd>
					</div>
					<div className='flex justify-between py-3 text-sm font-medium'>
						<dt className='text-gray-500'>Area Code</dt>
						<dd data-testid='area-code'>+44{range.numberBlock.substring(0, range.numberLength.areaCodeLength)}</dd>
					</div>
					<div className='flex justify-between py-3 text-sm font-medium'>
						<dt className='text-gray-500'>Notes</dt>
						<dd data-testid='notes'>{range.notes}</dd>
					</div>
				</dl>
			</div>
			<div>
				<Tab.Group defaultIndex={0}>
					<Tab.List className='space-x-2 lg:space-x-8'>
						<Tab
							className='border-gamma-digital text-gamma-digital whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
							data-testid='history-tab'
						>
							History
						</Tab>
					</Tab.List>
					<Tab.Panels className='my-4 lg:my-8 space-x-2 lg:space-x-8'>
						<Tab.Panel data-testid='history-tab-panel'>
							<HistoryTab
								historyFunction={(token, page?, perPage?) => OfcomRangeService.getRangeHistory(token, range.id.toString(), page, perPage)}
							/>
						</Tab.Panel>
					</Tab.Panels>
				</Tab.Group>
			</div>
		</div>
	);
};

export default RangeView;
