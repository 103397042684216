import React, { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Slideout, SubmitDialog, SubmitDialogProps, UnauthorisedCard } from 'components';
import { useHasRole } from 'hooks';
import { OfcomRangeResponse } from 'types';
import { OfcomRangeService } from 'services';
import { getAccessToken, getDefaultDialogProps, OFCOM_AGENT_ROLE } from 'utils';
import { apiConfig } from 'auth-config';
import AdvancedSearch from './ofcom-ranges-advanced-search';
import SimpleSearch from './ofcom-ranges-simple-search';
import RangeViewContainer from './range-view-container';
import RangeForm from './range-form';

export const pageSize = 5;

const OfcomRanges: React.FC = () => {
	const isAuthenticated = useIsAuthenticated();
	if (!isAuthenticated)
		return (
			<main className='p-3 pb-6'>
				<UnauthorisedCard />
			</main>
		);

	const isAuthorised = useHasRole(OFCOM_AGENT_ROLE);
	if (!isAuthorised) {
		return (
			<main className='p-3 pb-6'>
				<UnauthorisedCard
					message='You do not have the required access to view this content. Please consult the Help & Support page on Acquiring Access'
					showSignIn={false}
				/>
			</main>
		);
	}

	// Search State
	const [advancedSearch, setAdvancedSearch] = useState(false);
	const [ranges, setRanges] = useState<OfcomRangeResponse[]>([]);
	const [slideoutChild, setSlideoutChild] = useState<React.ReactNode>(() => <></>);
	const [dialogProps, setDialogProps] = useState<SubmitDialogProps>(getDefaultDialogProps());
	const [searchTerm, setSearchTerm] = useState('');
	const [slideoutTitle, setSlideoutTitle] = useState('');
	const msal = useMsal();
	const ofcomApiScopes = apiConfig.ofcomApi.scopes;
	const [slideoutOpen, setSlideoutOpen] = useState(false);
	// Functions and effects

	const initialFetch = async (term: string, page: number) => {
		const token: string = await getAccessToken(ofcomApiScopes, msal);
		OfcomRangeService.simpleSearchRanges(token, term, page, pageSize).then((r) => {
			setRanges(r);
		});
	};

	const onRangeSelection = async (rangeId: number) => {
		const token: string = await getAccessToken(ofcomApiScopes, msal);
		setSlideoutOpen(true);
		const range = await OfcomRangeService.getRange(token, rangeId);
		setSlideoutTitle('Number Block: +44' + range.numberBlock);
		setSlideoutChild(
			<RangeViewContainer
				range={range}
				openExpandDialogFunction={openExpandDialog}
				openContractDialogFunction={openContractDialog}
				onClose={() => setSlideoutOpen(false)}
			/>,
		);
	};

	const onAddRange = async () => {
		setSlideoutTitle('Add New Number Range');
		setSlideoutChild(<RangeForm range={null} setEditMode={(b) => null} onClose={() => setSlideoutOpen(false)} isRangeAddForm={true} />);
		setSlideoutOpen(true);
	};

	const openExpandDialog = (range: OfcomRangeResponse) => {
		const dProps: SubmitDialogProps = {
			title: `Expand Range: +44${range.numberBlock}`,
			child: getExpandDialogChild(range),
			submitFunction: (reason) => expandRange(reason, range),
			cancelFunction: () => setDialogProps(getDefaultDialogProps()),
			showChangeReason: true,
			successMessage: `Successfully expanded range: +44${range.numberBlock}.`,
			defaultFailMessage: `Failed to expand range: +44${range.numberBlock}.`,
		};
		setDialogProps(dProps);
	};

	const getExpandDialogChild = (range: OfcomRangeResponse): React.ReactNode => {
		return (
			<div className='text-left text-sm'>
				The number range will be expanded into the following ranges:
				<ul className='max-w-md space-y-1 list-disc list-inside py-2'>
					{[...Array(10).keys()].map((i) => {
						return <li key={i}>+44{range.numberBlock + i}</li>;
					})}
				</ul>
				After +44{range.numberBlock} has been expanded, it will no longer be returned by the system. Please ensure the data is correct prior
				to making the changes.
			</div>
		);
	};

	const expandRange = async (changeReason: string, range: OfcomRangeResponse): Promise<Response> => {
		const token = await getAccessToken(ofcomApiScopes, msal);
		const response = await OfcomRangeService.expandRange(token, range.id, changeReason);
		if (response.ok || (response.status > 200 && response.status < 299)) {
			setSlideoutOpen(false);
			setAdvancedSearch(false);
			setSearchTerm(range.numberBlock);
			initialFetch(range.numberBlock, 0);
		}
		return response;
	};

	const openContractDialog = (range: OfcomRangeResponse) => {
		const contractedRange = range.numberBlock.slice(0, -1);
		const dProps: SubmitDialogProps = {
			title: `Contract Ranges: +44${contractedRange + 0} to +44${contractedRange + 9}`,
			child: getContractDialogChild(range, contractedRange),
			submitFunction: (reason) => contractRange(reason, range),
			cancelFunction: () => setDialogProps(getDefaultDialogProps()),
			showChangeReason: true,
			successMessage: `Successfully contracted ranges: +44${contractedRange + 0} to +44${contractedRange + 9}.`,
			defaultFailMessage: `Failed to contract ranges: +44${contractedRange + 0} to +44${contractedRange + 9}.`,
		};
		setDialogProps(dProps);
	};

	const getContractDialogChild = (range: OfcomRangeResponse, contractedRange: string): React.ReactNode => {
		return (
			<div className='text-left text-sm'>
				You have selected to contract the number range +44{range.numberBlock} with its adjacent ranges. The following number ranges will be
				contracted into a single number range:
				<ul className='max-w-md space-y-1 list-disc list-inside py-2'>
					{[...Array(10).keys()].map((i) => {
						return <li key={i}>+44{contractedRange + i}</li>;
					})}
				</ul>
				After the contraction, a new range +44{contractedRange} will be created. The number ranges listed above will no longer be returned
				by the system. Please ensure the data is correct prior to making the changes.
			</div>
		);
	};

	const contractRange = async (changeReason: string, range: OfcomRangeResponse): Promise<Response> => {
		const token = await getAccessToken(ofcomApiScopes, msal);
		const response = await OfcomRangeService.contractRange(token, range.id, changeReason);
		if (response.ok || (response.status > 200 && response.status < 299)) {
			const contractedRange = range.numberBlock.slice(0, -1);
			setSlideoutOpen(false);
			setAdvancedSearch(false);
			setSearchTerm(contractedRange);
			initialFetch(contractedRange, 0);
		}
		return response;
	};

	useEffect(() => {
		initialFetch('', 0);
	}, []);

	return (
		<main className='p-3 pb-6'>
			{/* eslint-disable-next-line react/no-children-prop */}
			<Slideout open={slideoutOpen} setOpen={setSlideoutOpen} title={slideoutTitle} child={slideoutChild} />
			<SubmitDialog
				title={dialogProps.title}
				child={dialogProps.child}
				submitFunction={dialogProps.submitFunction}
				cancelFunction={dialogProps.cancelFunction}
				showChangeReason={dialogProps.showChangeReason}
				successMessage={dialogProps.successMessage}
				defaultFailMessage={dialogProps.defaultFailMessage}
			/>
			<h1 className='text-3xl tracking-tight font-extrabold sm:text-4xl md:text-4xl lg:text-5xl xl:text-5xl'>Ofcom Ranges</h1>
			<hr className='border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8' />
			{advancedSearch ? (
				<AdvancedSearch
					setRanges={setRanges}
					getRanges={() => ranges}
					onRangeSelection={onRangeSelection}
					setAdvancedSearch={setAdvancedSearch}
					pageSize={pageSize}
					onAddRange={onAddRange}
				/>
			) : (
				<SimpleSearch
					setRanges={setRanges}
					ranges={ranges}
					onRangeSelection={onRangeSelection}
					setAdvancedSearch={setAdvancedSearch}
					pageSize={pageSize}
					onAddRange={onAddRange}
					searchTerm={searchTerm}
				/>
			)}
		</main>
	);
};

export default OfcomRanges;
