import { useMsal } from '@azure/msal-react';
import Select from 'react-select';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { apiConfig } from 'auth-config';
import { EditLabel, EditTextField, TextArea } from 'components';
import { Field, FieldProps, FormikProps, FormikState } from 'formik';
import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CommunicationProviderService, PortAgreementService } from 'services';
import { PortAgreementItem, Option, PortAgreementAction, StepItemStatus } from 'types';
import { getAccessToken } from 'utils';

interface PortAgreementFormInputProps {
	agreement: PortAgreementItem | null;
	setEditMode: Dispatch<SetStateAction<boolean>>;
	onClose: () => void;
	action: PortAgreementAction;
	setShowSummary: Dispatch<SetStateAction<boolean>>;
	formPops: FormikProps<any>;
	updateStep: (stepIdx: number, stepStatus: StepItemStatus) => void;
}

const PortAgreementFormInput = ({
	agreement,
	setEditMode,
	onClose,
	action,
	setShowSummary,
	formPops,
	updateStep,
}: PortAgreementFormInputProps) => {
	const agreementFormReset = (resetForm: (nextState?: Partial<FormikState<PortAgreementService.PortAgreementEditInput>>) => void): void => {
		resetForm();
		setEditMode(false);
	};

	return (
		<>
			{/* Divider container */}
			<div className='space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0' data-testid='agreement-form'>
				{/* Number Block Display */}
				{action == 'EDIT' ? (
					<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
						<EditLabel label='Number Range' id='number-range' />
						<div className='block text-sm font-medium text-gray-800 dark:text-gray-200 sm:mt-px sm:pt-2'>+44{agreement?.numberBlock}</div>
					</div>
				) : null}
				{/* Communication Provider Input/Display */}
				{action == 'EDIT' ? (
					<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
						<EditLabel label='Communication Provider' id='agreement-holder' />
						<div className='block text-sm font-medium text-gray-800 dark:text-gray-200 sm:mt-px sm:pt-2'>
							{`(${agreement?.agreementHolder.cupid}) ${agreement?.agreementHolder.name}`}
						</div>
					</div>
				) : (
					<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
						<EditLabel label='Communication Provider' id='agreement-holder' mandatory={true} />
						<Field name='agreementHolder'>
							{(props: FieldProps<any>) => (
								<PortingPartnerFormSelect formikProps={props} id='agreement-holder-select' errorKey='agreement-holder-select-error' />
							)}
						</Field>
					</div>
				)}
				{/* Port Prefix */}
				<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
					<EditLabel label='Prefix' id='port-prefix' mandatory={true} />
					<Field name='portPrefix'>
						{(props: FieldProps<any>) => (
							<EditTextField formikProps={props} id='port-prefix' placeholder='Specify a port prefix' errorKey='port-prefix-error' />
						)}
					</Field>
				</div>
				{/* PDI */}
				<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
					<EditLabel label='PDI' id='pdi' />
					<Field name='pdi'>
						{(props: FieldProps<any>) => (
							<div className='sm:col-span-2 relative rounded-md shadow-sm'>
								<input
									type='file'
									name={props.field.name}
									id='pdi'
									accept='.xls,.xlsx,.pdf'
									onChange={(e) => {
										props.form.setFieldTouched(props.field.name, true);
										props.form.setFieldValue(props.field.name, e.currentTarget.files ? e.currentTarget.files[0] : undefined);
										props.form.validateField(props.field.name);

										if (props.meta.touched && props.meta.error) {
											// clear the field
											props.form.setFieldValue(props.field.name, undefined);
										}
									}}
									className={classNames(
										'block w-full text-sm file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-gamma-digital file:text-white hover:file:bg-gamma-hover',
										props.meta.touched && props.meta.error ? 'text-red-500' : 'text-gray-800 dark:text-white',
									)}
								/>

								{props.meta.touched && props.meta.error && (
									<div className='absolute inset-y-0 -top-7 right-0 pr-3 flex items-center pointer-events-none'>
										<ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' data-testid={`pdi-error-icon`} />
									</div>
								)}
								{props.meta.touched && props.meta.error && (
									<p className='mt-2 text-sm text-red-600' id='pdi-error' data-testid='pdi-error'>
										{props.meta.error}
									</p>
								)}
							</div>
						)}
					</Field>
				</div>
				{/* Number Ranges Input */}
				{action != 'EDIT' ? (
					<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
						<EditLabel label='Number Ranges' id='number-ranges' mandatory={true} />
						<Field name='numberBlock'>
							{(props: FieldProps<any>) => (
								<TextArea
									formikProps={props}
									id='number-ranges'
									errorKey='number-ranges-error'
									placeholder='Enter the number range(s) to be included as part of the port agreement'
								/>
							)}
						</Field>
					</div>
				) : null}
				{/* Reason for Change */}
				<div className='space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5'>
					<EditLabel label='Reason For Change' id='agreement-change-reason' />
					<Field name='changeReason'>
						{(props: FieldProps<any>) => (
							<TextArea
								formikProps={props}
								id='agreement-change-reason'
								errorKey='change-reason-error'
								placeholder='Enter a reason for changing this agreement.'
							/>
						)}
					</Field>
				</div>
			</div>
			{/* Action buttons */}
			<div className='flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6'>
				<div className='flex justify-end space-x-3'>
					<button
						data-testid='cancel-button'
						type='button'
						onClick={() => (action != 'EDIT' ? onClose() : agreementFormReset(formPops.resetForm))}
						className='rounded-md border border-gray-300 bg-gray-50 dark:bg-gray-800 py-2 px-4 text-sm font-medium text-gray-800 dark:text-white shadow-sm hover:bg-gamma-digital hover:text-white dark:hover:bg-gamma-digital focus:outline-none focus:ring-2 focus:ring-gamma-digital focus:ring-offset-2 dark:focus:text-white'
					>
						Cancel
					</button>
					<button
						data-testid='next-button'
						type='button'
						onClick={() =>
							formPops.validateForm().then(() => {
								updateStep(0, 'COMPLETE');
								updateStep(1, 'CURRENT');
								setShowSummary(true);
							})
						}
						className='ml-4 inline-flex justify-center rounded-md border border-transparent bg-gamma-digital py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gamma-hover focus:outline-none focus:ring-2 focus:ring-gamma-hover focus:ring-offset-2'
					>
						Next
					</button>
				</div>
			</div>
		</>
	);
};

export default PortAgreementFormInput;

interface PortingPartnerFormSelectProps {
	formikProps: FieldProps<any>;
	id: string;
	errorKey: string;
}

/**
 * Simple function that returns a formik select containing a list of porting partners
 * @param {PortingPartnerFormSelectProps}: porting partner select props
 * @return {JSX.Element}: Porting Partner Form Input
 */
function PortingPartnerFormSelect({ formikProps, id, errorKey }: PortingPartnerFormSelectProps): JSX.Element {
	const [portingPartners, setPortingPartners] = useState<Option[]>([] as Option[]);
	const msal = useMsal();
	const ofcomApiScopes = apiConfig.ofcomApi.scopes;

	useEffect(() => {
		const initialise = async () => {
			const token = await getAccessToken(ofcomApiScopes, msal);
			const providers = await CommunicationProviderService.getParentProviders(token);

			const options = [] as Option[];
			options.push({
				label: 'Any',
				value: '',
			});

			providers.forEach((item) => {
				if (item.flags.portingPartner) {
					// only add porting partners as options
					options.push({
						label: `(${item.cupid}) ${item.name}${item.franchiseArea ? ` ${item.franchiseArea}` : ''}`,
						value: item.cupid,
					});
				}
			});

			setPortingPartners(options);
		};

		initialise();
	}, []);

	const defaultValue = (options: Option[], value: string) => {
		return options ? options.find((option) => option.value === value) : '';
	};

	return (
		<div className='sm:col-span-2 relative rounded-md shadow-sm'>
			<Select
				aria-labelledby={id}
				aria-invalid={formikProps.meta.touched && formikProps.meta.error ? 'true' : 'false'}
				aria-describedby={formikProps.meta.touched && formikProps.meta.error ? `${errorKey}` : ''}
				options={portingPartners}
				id={id}
				inputId={id}
				isMulti={false}
				placeholder='Select a porting partner'
				name={formikProps.field.name}
				value={defaultValue(portingPartners, formikProps.field.value)}
				onChange={(newValue) => {
					const selectedOption = newValue as Option;
					formikProps.form.setFieldTouched(formikProps.field.name, true);
					formikProps.form.setFieldValue(formikProps.field.name, selectedOption.value);
				}}
				onBlur={() => {
					formikProps.form.handleBlur({ target: { name: formikProps.field.name } });
				}}
				className={formikProps.meta.touched && formikProps.meta.error ? 'searchable-select-error-container' : 'searchable-select-container'}
				classNamePrefix={formikProps.meta.touched && formikProps.meta.error ? 'searchable-select-error' : 'searchable-select'}
			/>
			{formikProps.meta.touched && formikProps.meta.error && (
				<div className='absolute inset-y-0 -top-7 right-0 pr-3 flex items-center pointer-events-none'>
					<ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' data-testid={`${id}-error-icon`} />
				</div>
			)}
			{formikProps.meta.touched && formikProps.meta.error && (
				<p className='mt-2 text-sm text-red-600' id={errorKey} data-testid={`${id}-error`}>
					{formikProps.meta.error}
				</p>
			)}
		</div>
	);
}
