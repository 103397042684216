import { useEffect } from 'react';
import { useLocalStorage } from '../use-local-storage';
import { useMediaQuery } from '../use-media-query';
import { useUpdateEffect } from '../use-update-effect';

const COLOR_SCHEME_QUERY = '(prefers-color-scheme: dark)';
const DARK_MODE_STORAGE_KEY = 'np-dark-mode';

interface UseDarkModeOutput {
	isDarkMode: boolean;
	toggle: () => void;
	enable: () => void;
	disable: () => void;
}

/**
 * This React Hook provides an interface to enable, disable, toggle and read the dark theme mode. The returned value (isDarkMode) is a boolean that can be used with application logic.
 * @param {boolean} defaultValue:  optional starting state for dark mode true = dark false = light
 * @return {UseDarkModeOutput}: Dark Mode toggles and actions
 */
function useDarkMode(defaultValue?: boolean): UseDarkModeOutput {
	const isDarkOS = useMediaQuery(COLOR_SCHEME_QUERY);
	const [isDarkMode, setDarkMode] = useLocalStorage<boolean>(DARK_MODE_STORAGE_KEY, defaultValue ?? isDarkOS ?? false);
	const html = document.querySelector('html');

	// Update darkMode if os prefers change
	useUpdateEffect(() => {
		setDarkMode(isDarkOS);
	}, [isDarkOS]);

	useEffect(() => {
		isDarkMode ? html?.classList.add('dark') : html?.classList.remove('dark');
	}, [isDarkMode]);

	return {
		isDarkMode,
		toggle: () => setDarkMode((prev) => !prev),
		enable: () => setDarkMode(true),
		disable: () => setDarkMode(false),
	};
}

export default useDarkMode;
