import { DependencyList, EffectCallback, useEffect } from 'react';
import { useIsFirstRender } from '../use-is-first-render';

/**
 * A modified version of {@link UseEffect} that is skipped on the render
 * @param {EffectCallback} effect: UseEffect function
 * @param {DependencyList} deps : List of objects that when changed trigger the useUpdateEffect hook
 */
function useUpdateEffect(effect: EffectCallback, deps?: DependencyList) {
	const isFirst = useIsFirstRender();

	useEffect(() => {
		if (!isFirst) {
			return effect();
		}
	}, deps);
}

export default useUpdateEffect;
