import React from 'react';
import ReactDOM from 'react-dom/client';
import { PublicClientApplication, EventMessage, EventType, AuthenticationResult } from '@azure/msal-browser';

import './index.css';
import App from './App';
import authConfig from './auth-config';

const msalInstance = new PublicClientApplication({
	auth: {
		clientId: authConfig.clientId,
		authority: authConfig.authority,
		redirectUri: authConfig.redirectUri,
		postLogoutRedirectUri: authConfig.postLogoutRedirectUri,
		navigateToLoginRequestUrl: authConfig.navigateToLoginRequestUrl,
	},
	cache: {
		cacheLocation: authConfig.cacheLocation,
		storeAuthStateInCookie: authConfig.storeAuthStateInCookie,
	},
});

// Check if there are already accounts in the browser session
// If so, set the first account as the active account
const accounts = msalInstance.getAllAccounts();
if (accounts && accounts.length > 0) {
	msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
		// Set the active account - this simplifies token acquisition
		const authResult = event.payload as AuthenticationResult;
		msalInstance.setActiveAccount(authResult.account);
	}
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App pca={msalInstance} />);
